import {
  BLACK,
  BRAND_SWASHLY_DARK_BLUE,
  BRAND_SWASHLY_TRANSPARENT_30_BLACK,
  BRAND_SWASHLY_TRANSPARENT_80_BLACK,
  BRAND_SWASHLY_LIGHT_SKY_BLUE,
  BRAND_SWASHLY_RED,
  BRAND_SWASHLY_GRAY,
  BRAND_WHATSAPP,
  CHARCOAL_BLUE,
  PRIMARY_BLUE,
  PRIMARY_BLUE_10,
  PRIMARY_BLUE_20,
  PRIMARY_BLUE_30,
  PRIMARY_BLUE_40,
  PRIMARY_BLUE_50,
  PRIMARY_BLUE_60,
  PRIMARY_BLUE_70,
  PRIMARY_BLUE_80,
  PRIMARY_BLUE_90,
  PRIMARY_GREEN,
  PRIMARY_NAVY,
  PRIMARY_NAVY_10,
  PRIMARY_NAVY_20,
  PRIMARY_NAVY_30,
  PRIMARY_NAVY_40,
  PRIMARY_NAVY_50,
  PRIMARY_NAVY_60,
  PRIMARY_NAVY_70,
  PRIMARY_NAVY_80,
  PRIMARY_NAVY_90,
  PRIMARY_RED,
  PRIMARY_YELLOW,
  SECONDARY_DARKER_BLUE,
  SECONDARY_DARKER_GREEN,
  SECONDARY_DARKER_RED,
  SECONDARY_DARK_BLUE,
  SECONDARY_DARK_GREEN,
  SECONDARY_DARK_RED,
  SECONDARY_GRAY,
  SECONDARY_LIGHT_GRAY,
  SECONDARY_PURPLE,
  theme,
  WHITE,
} from '@/utils/constants'
import { cssRaw } from 'typestyle'

const colorVarWithPrefix = (name: string) => `--color-${name}`
const RawColors = {
  [BLACK]: theme.black,
  [WHITE]: theme.white,
  [PRIMARY_BLUE]: theme.primaryBlue,
  [PRIMARY_BLUE_10]: theme.primaryBlue10,
  [PRIMARY_BLUE_20]: theme.primaryBlue20,
  [PRIMARY_BLUE_30]: theme.primaryBlue30,
  [PRIMARY_BLUE_40]: theme.primaryBlue40,
  [PRIMARY_BLUE_50]: theme.primaryBlue50,
  [PRIMARY_BLUE_60]: theme.primaryBlue60,
  [PRIMARY_BLUE_70]: theme.primaryBlue70,
  [PRIMARY_BLUE_80]: theme.primaryBlue80,
  [PRIMARY_BLUE_90]: theme.primaryBlue90,
  [PRIMARY_NAVY]: theme.primaryNavy,
  [PRIMARY_NAVY_10]: theme.primaryNavy10,
  [PRIMARY_NAVY_20]: theme.primaryNavy20,
  [PRIMARY_NAVY_30]: theme.primaryNavy30,
  [PRIMARY_NAVY_40]: theme.primaryNavy40,
  [PRIMARY_NAVY_50]: theme.primaryNavy50,
  [PRIMARY_NAVY_60]: theme.primaryNavy60,
  [PRIMARY_NAVY_70]: theme.primaryNavy70,
  [PRIMARY_NAVY_80]: theme.primaryNavy80,
  [PRIMARY_NAVY_90]: theme.primaryNavy90,
  [PRIMARY_RED]: theme.primaryRed,
  [PRIMARY_GREEN]: theme.primaryGreen,
  [PRIMARY_YELLOW]: theme.primaryYellow,
  [CHARCOAL_BLUE]: theme.charcoalBlue,
  [SECONDARY_LIGHT_GRAY]: theme.secondaryLightGray,
  [SECONDARY_GRAY]: theme.secondaryGray,
  [SECONDARY_DARK_BLUE]: theme.secondaryDarkBlue,
  [SECONDARY_DARKER_BLUE]: theme.secondaryDarkerBlue,
  [SECONDARY_DARK_RED]: theme.secondaryDarkRed,
  [SECONDARY_DARKER_RED]: theme.secondaryDarkerRed,
  [SECONDARY_DARK_GREEN]: theme.secondaryDarkGreen,
  [SECONDARY_DARKER_GREEN]: theme.secondaryDarkerGreen,
  [SECONDARY_PURPLE]: theme.secondaryPurple,
  [BRAND_WHATSAPP]: theme.brandWhatsapp,
  [BRAND_SWASHLY_DARK_BLUE]: theme.brandSwashlyDarkBlue,
  [BRAND_SWASHLY_TRANSPARENT_30_BLACK]: theme.brandSwashlyTransparent30Black,
  [BRAND_SWASHLY_TRANSPARENT_80_BLACK]: theme.brandSwashlyTransparent80Black,
  [BRAND_SWASHLY_LIGHT_SKY_BLUE]: theme.brandSwashlyLightSkyBlue,
  [BRAND_SWASHLY_RED]: theme.brandSwashlyRed,
  [BRAND_SWASHLY_GRAY]: theme.brandSwashlyGray,
}
export type Color = keyof typeof RawColors

cssRaw(`
  :root {
    ${Object.entries(RawColors)
      .map(([key, value]) => `${colorVarWithPrefix(key)}: ${value};`)
      .join('\n')}
  }
`)

export const getColor = (color: Color = PRIMARY_NAVY): string =>
  `var(${colorVarWithPrefix(color)})`

export const roleColorMap = {
  ['SUPER_ADMIN']: SECONDARY_PURPLE,
  ['ADMIN']: PRIMARY_BLUE,
  ['FF_USER']: PRIMARY_YELLOW,
  ['CLIENT']: SECONDARY_PURPLE,
  ['CLIENT_READ']: PRIMARY_YELLOW,
  ['CLIENT_WRITE']: PRIMARY_BLUE,
}
