import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      _ctx.regular({
        size: _ctx.size,
        margin: _ctx.margin,
        padding: _ctx.padding,
        color: _ctx.color,
        weight: _ctx.weight,
        clickable: _ctx.clickable,
        align: _ctx.textAlign,
        display: _ctx.display,
      })
    )
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}