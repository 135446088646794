import { sessionUser } from '@/router/guard'
import { User } from '@/types/models'

export interface AuthModuleState {
  isLoggedIn: boolean
  user: User | null
}

export const state: AuthModuleState = {
  isLoggedIn: !!sessionUser,
  user: sessionUser || null,
}
