
import { useProjectRestrictedRoutes } from '@/composables/useProjectRestrictedRoutes'
import { computed, defineComponent } from '@vue/runtime-core'
import { useRouter } from 'vue-router'

import { configurationBannerStyle as style } from '../styles'

export default defineComponent({
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  setup: (props) => {
    const { push } = useRouter()

    const { projectSettingsProgress: projectProgress } =
      useProjectRestrictedRoutes(props.projectId)

    const projectProgressStatusText = computed(() => {
      if (projectProgress.value?.style === false) return 'Style'
      else if (projectProgress.value?.metaDescription === false)
        return 'Meta Information'
      else if (projectProgress.value?.configuration === false)
        return 'Configuration'
      else return ''
    })

    const projectComplete = computed(
      () =>
        projectProgress.value?.style &&
        projectProgress.value?.metaDescription &&
        projectProgress.value?.configuration
    )

    const navigate = () => {
      if (projectProgress.value?.style === false) push({ name: 'Style' })
      else if (projectProgress.value?.metaDescription === false)
        push({ name: 'Meta Information' })
      else if (projectProgress.value?.configuration === false)
        push({ name: 'Configuration' })
    }

    return {
      projectProgressStatusText,
      projectComplete,
      navigate,
      style,
    }
  },
})
