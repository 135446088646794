import { style } from 'typestyle'
import { getColor } from '@/utils'

export const divider = style({
  width: '2px',
  height: '20px',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  margin: '10px 12px 0px 8px',
  display: 'inline-flex',
})

export const editorMultiselect = (width: string): string =>
  style({
    width: width,
    display: 'inline-flex',
    background: 'none',
    border: 'none',
    borderBottom: `1px solid ${getColor('primary-navy-20')}`,
    marginTop: '10px',
    minHeight: '25px',
    $nest: {
      '.multiselect-caret': {
        background: `url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right -2px center no-repeat`,
        height: '16px',
        position: 'absolute',
        margin: '0px',
      },
      '.is-open': {
        background: `url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right -2px center no-repeat`,
        height: '16px',
        position: 'absolute',
        margin: '0px',
      },
      '.multiselect-tag': {
        background: getColor('primary-navy-20'),
        color: getColor('black'),
        marginRight: '7px',
      },
      '.multiselect-options': {
        color: getColor('secondary-gray'),
        maxHeight: '180px',
      },
      '.multiselect-option': {
        padding: '0px',
        fontSize: '14px',
      },
      '.multiselect-option.is-pointed': {
        background: getColor('primary-navy-20'),
        color: getColor('black'),
      },
      '.multiselect-option.is-selected': {
        background: 'none',
        color: getColor('black'),
        fontWeight: 'bold',
      },
      '.multiselect-search': {
        background: 'none',
      },
      '.multiselect-tags-search': {
        background: 'none',
      },
      '.multiselect-dropdown': {
        background: getColor('white'),
        border: `1px solid ${getColor('primary-navy-20')}`,
        borderRadius: '4px',
        boxShadow: `0 0 5px 0 ${getColor('secondary-gray')}`,
      },
      '.normal-flag': {
        margin: '0px -5px 0px -5px',
      },
      '::-webkit-scrollbar': {
        width: '16px',
      },
      '::-webkit-scrollbar-thumb': {
        height: '50px',
        border: '6px solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: `${getColor('primary-blue-90')}`,
        borderRadius: '8px',
      },
    },
  })

export default { divider, editorMultiselect }
