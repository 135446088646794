import { style } from 'typestyle'
import { getColor, sizeMapper, Sizes } from '@/utils'

const multiselectWrapper = style({
  position: 'relative',
  display: 'initial',
  $nest: {
    '.is-disabled': {
      background: 'none',
    },
  },
})

export const isActive = style({
  boxShadow: 'none !important',
})

export const multiselect = (width: string, margin: Sizes, optionPadding: Sizes): string =>
  style({
    width: width,
    display: 'inline-flex',
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    borderBottom: `1px solid ${getColor('secondary-light-gray')}`,
    margin: sizeMapper(margin),
    $nest: {
      '.multiselect-placeholder': {
        color: getColor('secondary-gray'),
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '70%',
      },
      '.multiselect-caret': {
        background: `url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right -2px center no-repeat`,
        height: '16px',
        position: 'absolute',
        margin: '0px',
      },
      '.is-open': {
        background: `url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right -2px center no-repeat`,
        height: '16px',
        position: 'absolute',
        margin: '0px',
      },
      '.multiselect-tag': {
        background: getColor('primary-navy-20'),
        color: getColor('black'),
        marginRight: '7px',
        fontSize: '12px',
        fontWeight: '500',
        borderRadius: '10px',
        padding: '4px 10px',
        minWidth: '100px',
        display: 'flex',
        justifyContent: 'space-around',
      },
      '.multiselect-options': {
        color: getColor('secondary-gray'),
        maxHeight: '180px',
      },
      '.multiselect-option': {
        padding: sizeMapper(optionPadding),
        fontSize: '14px',
      },
      '.multiselect-option.is-pointed': {
        background: getColor('primary-navy-20'),
        color: getColor('black'),
      },
      '.multiselect-option.is-selected': {
        background: 'none',
        color: getColor('black'),
        fontWeight: 'bold',
      },
      '.multiselect-search': {
        background: 'none',
      },
      '.multiselect-tags-search': {
        background: 'none',
      },
      '.multiselect-dropdown': {
        background: 'white',
        border: `1px solid ${getColor('primary-navy-20')}`,
        borderRadius: '4px',
        boxShadow: `0 0 5px 0 ${getColor('secondary-gray')}`,
      },
      '.normal-flag': {
        margin: '0px -5px 0px -5px',
      },
      '::-webkit-scrollbar': {
        width: '16px',
      },
      '::-webkit-scrollbar-thumb': {
        height: '50px',
        border: '6px solid transparent',
        backgroundClip: 'content-box',
        backgroundColor: `${getColor('primary-blue-90')}`,
        borderRadius: '8px',
      },
    },
  })

export default { multiselect, isActive, multiselectWrapper }
