import { style } from 'typestyle'
import { getColor } from '@/utils'

const offersContainer = style({
  overflow: 'scroll',
  overflowX: 'hidden',
  maxHeight: '510px',
  $nest: {
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: getColor('primary-blue'),
      borderRadius: '62px',
    },
  },
})

const infoMessage = style({
  fontSize: '10px',
  marginBottom: '10px',
})

const selectedContainer = style({
  display: 'flex',
  overflowX: 'scroll',
  paddingBottom: '20px',
  width: '100%',
  background: getColor('white'),
  $nest: {
    '&::-webkit-scrollbar': {
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: getColor('primary-blue'),
      borderRadius: '62px',
    },
  },
})

const offerName = style({
  fontSize: '10px',
  textAlign: 'center',
  color: getColor('primary-blue'),
})

export default {
  infoMessage,
  offersContainer,
  offerName,
  selectedContainer,
}
