import { Color, getColor, sizeMapper } from '@/utils'
import { style } from 'typestyle'

export const headerButton = (
  color: Color,
  iconColor: Color,
  marginBottom?: string
): string => {
  return style({
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    color: getColor(color),
    marginRight: sizeMapper('small'),
    marginBottom,
    $nest: {
      svg: {
        width: '14px',
        height: '14px',
        color: getColor(iconColor),
      },
      span: {
        fontSize: '12px',
        padding: '0 8px',
      },
      '&:hover': {
        color: '#2596be',
      },
    },
  })
}
