import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { ProjectWizardModuleState } from '@/store/modules/projectWizard/state'

interface Getters<S = ProjectWizardModuleState> {
  projectName(state: S): string
  termsAndConditions(state: S): boolean
}

export const getters: GetterTree<ProjectWizardModuleState, RootState> &
  Getters = {
  projectName: (state) => {
    return state.projectName
  },
  termsAndConditions: (state) => {
    return state.termsAndConditions
  },
}
