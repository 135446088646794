import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    class: _normalizeClass(_ctx.className),
    disabled: _ctx.disabled || _ctx.isLoading
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.loading('LIGHT'), 'loading'])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}