
import { defineComponent } from 'vue'
import { modalStyle as css } from './styles'

export default defineComponent({
  props: {
    portrait: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  setup() {
    return { css }
  },
})
