
import { Property } from 'csstype'
import { defineComponent, PropType, computed } from 'vue'
import { cardStyle as css, padding20 } from './styles'
import { Sizes } from '@/utils'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    subtitleAlign: {
      type: String as PropType<Property.TextAlign>,
      default: 'center',
    },
    buttonText: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    defaultSelection: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'tiny no',
    },
  },
  emits: ['card-selected'],

  setup(props) {
    const cardTitleClass = computed(() =>
      css.cardTitle({
        margin: !props.image && !props.icon ? 'small no' : 'no',
      })
    )

    const cardClasses = computed(() => {
      return [
        css.card({
          selected: props.selected,
          disabled: props.disabled,
          margin: props.margin,
          defaultSelection: props.defaultSelection,
        }),
        !props.image ? `${padding20}` : '',
      ]
    })

    return {
      css,
      cardClasses,
      cardTitleClass,
      iconName: props.icon,
      padding20,
    }
  },
})
