import { style } from 'typestyle'
import { Property } from 'csstype'
import { getColor, sizeMapper, mapper, Sizes } from '@/utils'
import { TOP, BOTTOM } from '@/utils/constants'

interface HorizontalPositionType {
  [key: string]: number
}

interface PopoverType {
  position: string
  distance?: string
  horizontalPosition?: HorizontalPositionType
  align?: Property.TextAlign
  size?: string
  padding?: Sizes
}

export default ({
  position,
  distance,
  horizontalPosition,
  align,
  size,
  padding = '',
}: PopoverType): string => {
  let popoverPosition = {}
  let indicatorPosition = {}
  if (position === TOP) {
    popoverPosition = { bottom: `calc(100% + ${distance || '13px'})` }
    indicatorPosition = {
      bottom: '-10px',
      borderTop: `10px solid ${getColor('white')}`,
    }
  } else if (position === BOTTOM) {
    popoverPosition = { top: `calc(100% + ${distance || '13px'})` }
    indicatorPosition = {
      top: '-10px',
      borderBottom: `10px solid ${getColor('white')}`,
    }
  }

  return style({
    position: 'absolute',
    ...(horizontalPosition ? horizontalPosition : { left: '50%' }),
    zIndex: 6000,
    width: mapper(
      size || '',
      { small: '200px', medium: '280px', large: '360px' },
      'medium'
    ),
    marginLeft: mapper(
      size || '',
      { small: '-100px', medium: '-140px', large: '-180px' },
      'medium'
    ),
    padding: sizeMapper(padding, 'small'),
    backgroundColor: getColor('white'),
    borderRadius: '5px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    textAlign: align || 'center',
    $nest: {
      '&:before': {
        content: "''",
        display: 'inline-block',
        position: 'absolute',
        ...(horizontalPosition
          ? {
              [Object.keys(horizontalPosition)[0]]:
                Object.values(horizontalPosition)[0] + 5,
            }
          : {
              left: mapper(
                size || '',
                { small: '90px', medium: '130px', large: '170px' },
                'medium'
              ),
            }),
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',

        ...indicatorPosition,
      },
    },
    ...popoverPosition,
  })
}
