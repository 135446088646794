import { OfferTemplateKey, TranslatedCardButton } from '@/types/models'

export type OfferTemplate = {
  [findKey in OfferTemplateKey]: {
    templateName: string
    buttons: Array<TranslatedCardButton>
  }
}

export const OFFER_TEMPLATES: OfferTemplate = {
  TRAFFIC: {
    templateName: 'Traffic',
    buttons: [
      { name: 'CTA1', appearance: 'default' },
      { name: 'CTA2', appearance: 'default' },
    ],
  },
  BUY: {
    templateName: 'Buy now',
    buttons: [
      { name: 'BUY', appearance: 'default' },
      { name: 'Read More', appearance: 'outline' },
    ],
  },
  OFFER: {
    templateName: 'Offer template',
    buttons: [{ name: 'BUY', appearance: 'default' }],
  },
  BOOK: {
    templateName: 'Book now',
    buttons: [
      { name: 'Book', appearance: 'default' },
      { name: 'Read More', appearance: 'outline' },
    ],
  },
  MIX: {
    templateName: 'Mix',
    buttons: [
      { name: 'CTA1', appearance: 'default' },
      { name: 'Buy', appearance: 'default' },
    ],
  },
}
