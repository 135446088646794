
import { defineComponent } from 'vue'
import { useField } from 'vee-validate'
import { inputStyle, dangerText, formError } from './styles'

export default defineComponent({
  name: 'BckMiniInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
      default: 'Placeholder',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    })

    const className = inputStyle({
      withMiniInputStyle: true,
      hasValue: !!inputValue.value,
    })

    return {
      formError,
      dangerText,
      className,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    }
  },
})
