import { MutationTree } from 'vuex'
import { CampaignsState } from './state'
import { Campaign, Funnel, Offer, ReadMoreTemplate } from '@/types/models'

interface Mutations<S = CampaignsState> {
  SET_FUNNEL(state: S, funnel: Funnel | undefined): void
  SET_NEW_CAMPAIGN(state: S, campaign: Campaign | undefined): void
  SET_NEW_OFFER(state: S, offer: Offer | undefined): void
  UPDATE_OFFER(state: S, offer: Offer): void
  SET_SELECTED_LANGUAGE(state: S, language: string): void
  SET_NEW_OFFER_READ_MORE_TEMPLATE(
    state: S,
    readMoreTemplate: ReadMoreTemplate | undefined
  ): void
  SET_READ_MORE_TEMPLATE(
    state: S,
    readMoreTemplate: ReadMoreTemplate | undefined
  ): void
}

export const mutations: MutationTree<CampaignsState> & Mutations = {
  SET_FUNNEL(state, funnel) {
    state.funnel = funnel
  },
  SET_NEW_CAMPAIGN(state, campaign) {
    state.newCampaign = campaign
  },
  SET_NEW_OFFER(state, offer) {
    state.newOffer = offer
  },
  UPDATE_OFFER(state, offer) {
    state.newOffer = { ...state.newOffer, ...offer }
  },
  SET_NEW_OFFER_READ_MORE_TEMPLATE(state, readMoreTemplate) {
    if (state.newOffer) {
      state.newOffer.readMoreTemplate = readMoreTemplate
    }
  },
  /**
   * The existing read more page of an offer.
   */
  SET_READ_MORE_TEMPLATE(state, readMoreTemplate) {
    state.readMoreTemplate = readMoreTemplate
  },

  SET_SELECTED_LANGUAGE(state, selectedLanguage) {
    state.selectedLanguage = selectedLanguage
  },
}
