export const roles = {
  SUPER_ADMIN: {
    label: 'Super Admin',
    value: 'SUPER_ADMIN',
  },
  ADMIN: {
    label: 'User',
    value: 'ADMIN',
  },
  FF_USER: {
    label: 'Project User',
    value: 'FF_USER',
  },
}

export const projectRoles = {
  CLIENT: {
    label: 'Project Admin',
    value: 'CLIENT',
  },
  CLIENT_READ: {
    label: 'Project Viewer',
    value: 'CLIENT_READ',
  },
  CLIENT_WRITE: {
    label: 'Project Editor',
    value: 'CLIENT_WRITE',
  },
}
