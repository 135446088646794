import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      _ctx.css.paginationContainer({
        withoutFirstControl: _ctx.isFirstPage,
        withoutLastControl: _ctx.isLastPage,
      })
    )
  }, [
    (!_ctx.isFirstPage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.css.paginationControl),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePageNumber(_ctx.currentPage - 1)))
        }, " Previous ", 2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.range(_ctx.firstPage, _ctx.lastPage + 1), (page, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(_ctx.css.paginationItem({ isActive: _ctx.currentPage == page })),
        onClick: ($event: any) => (_ctx.updatePageNumber(page))
      }, _toDisplayString(page), 11, _hoisted_1))
    }), 128)),
    (!_ctx.isLastPage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.css.paginationControl),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePageNumber(_ctx.currentPage + 1)))
        }, " Next ", 2))
      : _createCommentVNode("", true)
  ], 2))
}