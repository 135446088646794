import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { authGuard, superAdminGuard, registerGuard, permissionGuard } from './guard'
import ProjectLayout from '@/views/project/layout/ProjectLayout.vue'
import { Permissions } from '@/services/auth/permissions'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "project" */ '@/views/home/views/Projects.vue'),
    beforeEnter: permissionGuard(Permissions.ACCESS_PLATFORM_DASHBOARD),
  },
  {
    path: '/admin-panel',
    name: 'Admin Panel',
    component: () => import(/* webpackChunkName: "project" */ '@/views/user/views/AdminPanel.vue'),
    beforeEnter: permissionGuard(Permissions.ACCESS_ADMIN_PANEL),
    meta: { disableOverflow: true },
  },
  {
    path: '/project-wizard/welcome',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "project" */ '@/views/projectWizard/views/Welcome.vue'),
    beforeEnter: permissionGuard(Permissions.CREATE_NEW_PROJECT),
  },
  {
    path: '/project-wizard/create-project',
    name: 'Create Project',
    component: () => import(/* webpackChunkName: "project" */ '@/views/projectWizard/views/CreateProject.vue'),
    beforeEnter: permissionGuard(Permissions.CREATE_NEW_PROJECT),
  },
  {
    path: '/registration-successful',
    name: 'Registration Successful',
    beforeEnter: registerGuard,
    component: () => import(/* webpackChunkName: "landing" */ '@/views/auth/views/RegistrationSuccessful.vue'),
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: '',
        name: 'Project',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectDashboard.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_DASHBOARD),
      },
      {
        path: 'style',
        name: 'Brand & Style',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectStyle.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_STYLE),
      },
      {
        path: 'preview-layout-styles',
        name: 'Preview Layout Styles',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectPreviewLayoutStyles.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_PREVIEW_LAYOUT),
      },
      {
        path: 'meta-information',
        name: 'Meta Information',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectMetaDescription.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_META_INFORMATION),
      },
      {
        path: 'pwa-configuration',
        name: 'PWA Configuration',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectPwaConfiguration.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_PWA_CONFIG),
      },
      {
        path: 'language',
        name: 'Language',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectLanguage.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_LANGUAGE),
      },
      {
        path: 'configuration-domain-name',
        name: 'Configuration Domain Name',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectConfigurationDomainName.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_CONFIG),
        meta: { configuration: true },
      },
      // {
      //   path: 'configuration-reverse-proxy',
      //   name: 'Configuration Reverse Proxy',
      //   props: true,
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "project" */ '@/views/project/views/ProjectConfigurationReverseProxy.vue'
      //     ),
      //   beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_CONFIG),
      //   meta: { configuration: true },
      // },
      {
        path: 'configuration-proxy-type',
        name: 'Configuration Proxy Type',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectConfigurationProxyType.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_CONFIG),
        meta: { configuration: true },
      },
      {
        path: 'configuration',
        name: 'Configuration',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectConfiguration.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_CONFIG),
        meta: { configuration: true },
      },
      {
        path: 'team_and_users',
        name: 'Team & Users',
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_AUTHORISATION),
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectAuthorization.vue'),
        meta: { disableContentCard: true },
      },
      {
        path: 'advanced',
        name: 'Advanced',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/project/views/ProjectGetHelp.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_ADVANCED_PROJECT_SETTINGS),
      },
      {
        path: 'funnel-manager',
        name: 'Funnel Manager',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CampaignManager.vue'),
        meta: { disableContentCard: true, fullyConfigured: true },
        beforeEnter: permissionGuard(Permissions.ACCESS_CAMPAIGN_MANAGER),
      },
    ],
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: 'analytics-dashboard',
        name: 'Analytics Dashboard',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/analytics/views/AnalyticsDashboard.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_ANALYTICS),
        meta: { fullyConfigured: true },
      },
    ],
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: 'vault',
        name: 'Vault',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/vault/Vault.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_VAULT),
        meta: { fullyConfigured: true, disableContentCard: true },
      },
    ],
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: 'campaigns',
        name: 'Campaigns',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CampaignGrid.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_CAMPAIGNS),
        meta: { fullyConfigured: true, disableContentCard: true },
      },
      {
        path: 'campaigns/create',
        name: 'Create Campaign',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditCampaign.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_CAMPAIGNS),
        meta: { fullyConfigured: true },
      },
      {
        path: 'campaigns/:campaignId',
        name: 'Edit Campaign',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditCampaign.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_CAMPAIGNS),
        meta: { fullyConfigured: true },
      },
    ],
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: 'integrations-dashboard',
        name: 'Integrations',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project" */ '@/views/integrations/views/IntegrationsDashboard.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_INTEGRATIONS),
        meta: { fullyConfigured: true },
      },
    ],
  },

  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: 'integrations-fbpixel',
        name: 'Facebook Pixel',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/integrations/views/IntegrationsFBPixel.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_INTEGRATIONS),
        meta: { fullyConfigured: true },
      },
    ],
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: 'integrations-hotjar',
        name: 'Hotjar',
        props: true,
        component: () => import(/* webpackChunkName: "project" */ '@/views/integrations/views/IntegrationsHotjar.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_INTEGRATIONS),
        meta: { fullyConfigured: true },
      },
    ],
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true },
    children: [
      {
        path: 'integrations-msclarity',
        name: 'Microsoft Clarity',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project" */ '@/views/integrations/views/IntegrationsMsClarity.vue'),
        beforeEnter: permissionGuard(Permissions.ACCESS_PROJECT_INTEGRATIONS),
        meta: { fullyConfigured: true },
      },
    ],
  },

  {
    path: '/project/:id/product-flow-builder',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true, fullyConfigured: true },
    children: [
      {
        path: '',
        name: 'Flow Builder',
        component: () =>
          import(
            /* webpackChunkName: "product-flow-builder" */ '@/views/productFlowBuilder/views/ProductFlowBuilder.vue'
          ),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_FLOW_BUILDER),
      },
      {
        path: 'create-page',
        name: 'Create Page',
        component: () =>
          import(/* webpackChunkName: "product-flow-builder" */ '@/views/productFlowBuilder/views/CreateEditPage.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ADD_NEW_PAGE),
      },
      {
        path: 'page/:pageId',
        name: 'Edit Page',
        component: () =>
          import(/* webpackChunkName: "product-flow-builder" */ '@/views/productFlowBuilder/views/CreateEditPage.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_SPECIFIC_PAGE),
      },
    ],
  },
  {
    path: '/project/:id/funnel-manager',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true, fullyConfigured: true },
    children: [
      {
        path: 'create-campaign/choose-template',
        name: 'Choose Funnel Group Template',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/ChooseFunnelGroupTemplate.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.CREATE_NEW_FUNNEL_GROUP),
      },
      {
        path: 'create-campaign/campaign-info',
        name: 'Create Funnel Group',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditFunnelGroup.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.CREATE_NEW_FUNNEL_GROUP),
      },
      {
        path: 'campaign/:campaignId',
        name: 'Edit Funnel Group',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditFunnelGroup.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_SPECIFIC_FUNNEL_GROUP),
      },
      {
        path: 'campaign/:campaignId/create-funnel',
        name: 'Create Funnel',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditFunnel.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.CREATE_NEW_FUNNEL),
      },
      {
        path: 'campaign/:campaignId/funnels/:funnelId',
        name: 'Edit Funnel',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditFunnel.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_SPECIFIC_FUNNEL),
      },
      {
        path: 'campaign/:campaignId/preview-funnel/:funnelId?',
        name: 'Preview Funnel',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/PreviewFunnel.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_SPECIFIC_FUNNEL),
      },
      {
        path: 'campaign/:campaignId/funnels',
        name: 'Funnels',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CampaignFunnels.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_SPECIFIC_FUNNEL),
      },
    ],
  },
  {
    path: '/project/:id',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true, fullyConfigured: true },
    beforeEnter: superAdminGuard,
    children: [
      // {
      //   path: 'backoffice-api-manager',
      //   name: 'Api Manager',
      //   component: ApiManager,
      // },
      // {
      //   path: 'facebook-automation/create-rule',
      //   name: 'Create Rule',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "project" */ '@/views/facebookAutomation/views/CreateEditRule.vue'
      //     ),
      // },
      // {
      //   path: 'facebook-automation/edit-rule/:ruleId',
      //   name: 'Edit Rule',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "project" */ '@/views/facebookAutomation/views/CreateEditRule.vue'
      //     ),
      //   props: true,
      // },
      // {
      //   path: 'facebook-automation/rules-management',
      //   name: 'Facebook Automation',
      //   component: RulesManagement,
      // },
      // {
      //   path: 'facebook-automation/rule-log/:ruleId',
      //   name: 'Rule Log',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "project" */ '@/views/facebookAutomation/views/RuleLog.vue'
      //     ),
      // },
    ],
  },
  {
    path: '/project/:id/content',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true, fullyConfigured: true },
    children: [
      {
        path: 'offers',
        name: 'Offers',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/Offers.vue'),
        props: true,
        meta: { disableContentCard: true },
        beforeEnter: permissionGuard(Permissions.ACCESS_OFFERS),
      },
      {
        path: 'create-offer/offer-info',
        name: 'Create Offer',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditOffer.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.CREATE_OFFER),
      },
      {
        path: 'offers/:offerId/offer-info',
        name: 'Edit Offer',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/CreateEditOffer.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_SPECIFIC_OFFER),
      },
      {
        path: 'lead-forms',
        name: 'Lead Forms',
        component: () => import(/* webpackChunkName: "project" */ '@/views/catalog/lead-form/LeadFormGrid.vue'),
        props: true,
        meta: { disableContentCard: true },
        beforeEnter: permissionGuard(Permissions.ACCESS_LEAD_FORMS),
      },
      {
        path: 'traffic-cards',
        name: 'Traffic Cards',
        component: () => import(/* webpackChunkName: "project" */ '@/views/catalog/traffic-card/TrafficCardGrid.vue'),
        props: true,
        meta: { disableContentCard: true },
        beforeEnter: permissionGuard(Permissions.ACCESS_TRAFFIC_CARDS),
      },
      {
        path: 'traffic-cards/create',
        name: 'Create Traffic Card',
        component: () =>
          import(/* webpackChunkName: "project" */ '@/views/catalog/traffic-card/CreateEditTrafficCard.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.EDIT_TRAFFIC_CARDS),
      },
      {
        path: 'traffic-cards/:trafficCardId',
        name: 'Edit Traffic Card',
        component: () =>
          import(/* webpackChunkName: "project" */ '@/views/catalog/traffic-card/CreateEditTrafficCard.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.EDIT_TRAFFIC_CARDS),
      },
      {
        path: 'lead-forms/create',
        name: 'Create Lead Form',
        component: () => import(/* webpackChunkName: "project" */ '@/views/catalog/lead-form/CreateEditLeadForm.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.EDIT_LEAD_FORMS),
      },
      {
        path: 'lead-forms/:leadFormId',
        name: 'Edit Lead Form',
        component: () => import(/* webpackChunkName: "project" */ '@/views/catalog/lead-form/CreateEditLeadForm.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.EDIT_LEAD_FORMS),
      },
    ],
  },
  {
    path: '/project/:id/terms-of-service',
    props: true,
    component: ProjectLayout,
    meta: { disableOverflow: true, fullyConfigured: true },
    children: [
      {
        path: '',
        name: 'ToS & PP',
        component: () => import(/* webpackChunkName: "project" */ '@/views/campaigns/views/TermsOfService.vue'),
        props: true,
        beforeEnter: permissionGuard(Permissions.ACCESS_TOS_PP),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/auth/views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/auth/views/ForgotPassword.vue'),
    props: true,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/auth/views/ResetPassword.vue'),
  },
  {
    path: '/register/:token(.*)',
    name: 'Register',
    props: true,
    component: () => import(/* webpackChunkName: "landing" */ '@/views/auth/views/Register.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} - ${String(to.name)}`
  authGuard(to, from, next)
  next()
})

export default router
