
import { defineComponent, ref, watch } from 'vue'
import NavigationMenu from '@/components/common/NavigationMenu.vue'
import { projectLayoutStyle as css } from '../styles'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    NavigationMenu,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { currentRoute } = useRouter()
    const dashboard = ref(currentRoute.value.meta.dashboard)

    watch(
      currentRoute,
      () => (dashboard.value = currentRoute.value.meta.dashboard)
    )

    return { css, dashboard }
  },
})
