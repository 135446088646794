
import { Sizes } from '@/utils'
import { computed, defineComponent, PropType } from 'vue'
import { buttonStyle, ButtonTypes, ButtonSizes, loading } from './styles'
type HtmlType = 'button' | 'submit' | 'reset'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<HtmlType>,
      default: 'button',
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'tiny no',
    },
    buttonStyle: {
      type: String as PropType<ButtonTypes>,
      default: 'primary',
    },
    size: {
      type: String as PropType<ButtonSizes>,
      default: 'large',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isRegister: {
      type: Boolean,
      default: false,
    },
    needsFullWidth: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const className = computed(() =>
      buttonStyle({
        type: props.buttonStyle,
        margin: props.margin,
        size: props.size,
        disabled: props.disabled || props.isLoading,
        isRegister: props.isRegister,
        fullWidth: props.needsFullWidth,
      })
    )

    return {
      className,
      loading,
    }
  },
})
