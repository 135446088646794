import { style } from 'typestyle'
import { getColor, fadeIn, slideDown } from '@/utils'

interface ToggleButtonType {
  isNavigationMenuOpen: boolean
}

export const navigationMenu = style({
  position: 'relative',
  backgroundColor: '#F2F3F5',
  height: 'calc(100vh - 56px)',
  width: '250px',
  padding: '32px 20px',
  //boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.1)',
  transition: 'width 0.3s ease-in-out',
  $nest: {
    '.toggleButton': {
      display: 'none',
    },
    '&:hover': {
      $nest: {
        '.toggleButton': {
          display: 'block',
        },
      },
    },
  },
})

export const navigationMenuItemsContainer = style({
  overflow: 'hidden',
})

export const navigationMenuClosed = style({
  width: '76px !important',
})

export const navigationMenuHoverExtraPadding = style({
  position: 'absolute',
  right: -30,
  top: 0,
  width: '30px',
  height: 'calc(100vh - 56px)',
  background: 'transparent',
})

export const mainNavigationItem = (
  isMenuItemExpanded: string,
  isNavigationMenuOpen: string
): string =>
  style({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '36px',
    width: '200px',
    marginBottom: '16px',
    backgroundColor: '#F2F3F5',
    textDecoration: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    ...(isNavigationMenuOpen
      ? {
          background: !isMenuItemExpanded
            ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right 2px center no-repeat"
            : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMyA3Ljc1TDcgMS43NUwxIDcuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right 2px center no-repeat",
        }
      : { background: 'none' }),
  })

export const mainNavigationItemLink = style({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  height: '36px',
  width: '200px',
  marginBottom: '16px',
  textDecoration: 'none',
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  outline: 'inherit',
  backgroundColor: '#F2F3F5',
})

export const mainNavigationDisabledItem = style({
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iI0M2QzZDNiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right 2px center no-repeat !important",
  color: getColor('secondary-gray') + '!important',
})

export const mainNavigationItemIcon = style({
  height: '36px',
  width: '36px',
})

export const mainNavigationItemTitle = style({
  fontSize: '14px',
  lineHeight: '16px',
  marginLeft: '16px',
})

export const mainNavigationItemSubItems = style({
  marginLeft: '52px',
  display: 'flex',
  flexDirection: 'column',
  animation: `${slideDown} 0.5s ease-in-out`,
})

export const mainNavigationItemSubItemsCollapsed = style({
  maxHeight: 0,
})

export const secondaryNavigationItem = (isSelected: boolean): string =>
  style({
    whiteSpace: 'nowrap',
    width: '147px',
    textAlign: 'left',
    fontSize: '12px',
    color: isSelected ? getColor('primary-blue') : getColor('charcoal-blue'),
    lineHeight: '16px',
    marginBottom: '16px',
    position: 'relative',
    textDecoration: 'none',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    animation: `${fadeIn} 0.5s ease-in-out`,

    $nest: {
      div: {
        animation: 'none !important',
        $nest: {
          '&:before': {
            content: "'•'",
            display: 'inline-block',
            paddingRight: '5px',
          },
          '&.disabled': {
            opacity: 0.5,
          },
        },
      },
    },
  })

export const mainNavigationSubItemWarningIcon = style({
  float: 'right',
  width: '14px',
  height: '14px',
})

export const navigationMenuToggleButton = ({
  isNavigationMenuOpen,
}: ToggleButtonType): string =>
  style({
    position: 'absolute',
    animation: `${fadeIn} 0.2s ease-in-out`,
    top: 41,
    right: -9,
    width: '18px',
    height: '18px',
    borderRadius: '10px',
    backgroundColor: getColor('white'),
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    color: 'inherit',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    background: isNavigationMenuOpen
      ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNiA5IDIgNWw0LTQiIHN0cm9rZT0iI0M2QzZDNiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+') left 5px center no-repeat"
      : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAxIDQgNC00IDQiIHN0cm9rZT0iI0M2QzZDNiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+') right 5px center no-repeat",

    $nest: {
      '&:hover': {
        backgroundColor: getColor('secondary-dark-blue'),
        backgroundImage: isNavigationMenuOpen
          ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNiA5IDIgNWw0LTQiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')"
          : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAxIDQgNC00IDQiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')",
      },
    },
  })

export default {
  navigationMenu,
  navigationMenuItemsContainer,
  mainNavigationDisabledItem,
  navigationMenuClosed,
  navigationMenuHoverExtraPadding,
  mainNavigationItem,
  mainNavigationItemLink,
  mainNavigationItemIcon,
  mainNavigationItemTitle,
  mainNavigationItemSubItems,
  mainNavigationItemSubItemsCollapsed,
  secondaryNavigationItem,
  mainNavigationSubItemWarningIcon,
  navigationMenuToggleButton,
}
