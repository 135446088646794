import { style } from 'typestyle'
import { getColor } from '@/utils'

export const multiselect = style({
  maxWidth: '360px',
  width: '100%',
  minHeight: '30px',
  position: 'static',
  zIndex: 2000,
  color: getColor(),
  backgroundColor: getColor('white'),
  outline: 'none',
  display: 'inline-flex',
  flexWrap: 'nowrap',
  background: 'none',
  border: `1px solid ${getColor('white')}`,
  borderRadius: '3px',
  boxShadow: 'none',
  lineHeight: '14px',
  fontWeight: 500,
  fontSize: '12px',
  marginTop: '0px',
  $nest: {
    '.multiselect-caret': {
      background: `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') center no-repeat`,
      height: '30px',
      width: '30px',
      margin: '0px',
    },
    '.is-open': {
      background: `url('data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') center no-repeat`,
      height: '30px',
    },
    '.multiselect-clear': {
      color: getColor('primary-blue-10'),
    },
    '.multiselect-single-label': {
      flexWrap: 'nowrap',
      overflowX: 'scroll',
      $nest: {
        '::-webkit-scrollbar': {
          backgroundColor: 'none',
          width: 'none',
        },
      },
    },
    '.multiselect-placeholder': {
      paddingRight: '15px',
      marginLeft: '8px',
      color: getColor('primary-navy-60'),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '70%',
    },
    '.multiselect-single-label-text': {
      overflow: 'visible',
    },
    '.multiselect-no-results': {
      backgroundColor: getColor('white'),
    },
    '.multiselect-tag': {
      background: getColor('primary-blue-20'),
      color: getColor('secondary-gray'),
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      marginRight: '7px',
    },
    '.multiselect-tags': {
      overflowX: 'auto',
      flexWrap: 'nowrap',
      $nest: {
        '::-webkit-scrollbar': {
          backgroundColor: 'none',
          width: 'none',
        },
      },
    },
    '.multiselect-options': {
      color: getColor('secondary-gray'),
      backgroundColor: getColor('white'),
      maxHeight: '180px',
    },
    '.multiselect-option': {
      padding: '10px',
      color: getColor('secondary-gray'),
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
    },
    '.multiselect-option.is-pointed': {
      background: getColor('primary-navy-20'),
      color: getColor('black'),
    },
    '.multiselect-option.is-selected': {
      background: 'none',
      color: getColor('black'),
      fontWeight: 'bold',
    },
    '.multiselect-search': {
      background: 'none',
    },
    '.multiselect-tags-search': {
      background: 'none',
    },
    '.multiselect-dropdown': {
      background: 'none',
      border: `1px solid ${getColor('primary-navy-20')}`,
      borderRadius: '4px',
      boxShadow: `0 0 5px 0 ${getColor('secondary-gray')}`,
      zIndex: 2000,
    },
    '.normal-flag': {
      margin: '0px -5px 0px -5px',
    },
  },
})

export default {
  multiselect,
}
