import { classes, style } from 'typestyle'
import { getColor } from '@/utils'

const orderNumber = style({
  fontSize: '20px',
  color: getColor('primary-blue'),
  width: '42px',
  borderRight: `1px solid ${getColor('secondary-gray')}`,
  lineHeight: '66px',
  textAlign: 'center',
})

const title = style({
  paddingLeft: '20px',
  fontSize: '15px',
  color: getColor('primary-blue'),
})

const type = style({
  fontSize: '14px',
  textAlign: 'center',
})

const funnelGroup = style({
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '&.domains': {
      alignItems: 'center',
      flexGrow: 2,
    },
  },
})

const funnelSubgroup = style({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
})

const funnelSubgroupDate = classes(
  funnelSubgroup,
  style({
    $nest: {
      'div:first-child': {
        width: '30%',
        textAlign: 'right',
      },
      'div:nth-child(2)': {
        width: '70%',
        textAlign: 'left',
      },
    },
  })
)

export default {
  orderNumber,
  title,
  type,
  funnelGroup,
  funnelSubgroup,
  funnelSubgroupDate,
}
