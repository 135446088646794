import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const card = (selected: boolean, disabled: boolean): string =>
  style({
    background: selected ? getColor('primary-blue') : 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: sizeMapper('xxSmall'),
    position: 'relative',
    width: '130px',
    height: '100px',
    border: disabled
      ? '1px solid rgba(196, 196, 196, 0.1)'
      : `3px solid ${getColor('primary-blue')}`,
    boxSizing: 'border-box',
    borderRadius: '10px',
    color: getColor('secondary-gray'),
  })

const name = (selected: boolean): string =>
  style({
    position: 'absolute',
    bottom: '5px',
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: selected ? getColor('white') : getColor('secondary-gray'),
  })

export default {
  card,
  name,
}
