/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, Ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { TableFiltersQuery } from '@/types/components'
import { DateRange } from '@/types/models'
import { debounce, isEmpty } from 'lodash'
import { format } from 'date-fns'

export interface TableRow {
  [key: string]: any
}

interface useTableViewComposable {
  queryInitialValues: TableFiltersQuery
  selectedItems: Ref<{ [key: string]: Array<TableRow> }>
  onTableSort: (sortedBy: string, sortOrder: string) => void
  onSearchInputChange: (searchText: string) => void
  onDateInputChange: (range: DateRange) => void
  onMarketingPlatformChange: (marketingPlatform: string) => void
  onTypeChange: (type: string) => void
  getFiltersFromCurrentRoute: () => TableFiltersQuery
  toggleSelectedItems: (entitiesName: string, row: TableRow) => void
}

export const LIMIT_PER_PAGE = 10

export const useTableView = (): useTableViewComposable => {
  const route = useRoute()
  const { push, currentRoute } = useRouter()

  // URL QUERY INITIAL VALUES
  const queryInitialValues: TableFiltersQuery = {
    search: route.query.search ? (route.query.search as string) : '',
    sortedBy: route.query.sortedBy ? (route.query.sortedBy as string) : '',
    sortOrder: route.query.sortOrder ? (route.query.sortOrder as string) : '',
    startDate: route.query.startDate
      ? (route.query.startDate as string)
      : undefined,
    endDate: route.query.endDate ? (route.query.endDate as string) : undefined,
    marketingPlatform: route.query.marketingPlatform
      ? String(route.query.marketingPlatform).split(',')
      : [],
    type: route.query.type ? String(route.query.type).split(',') : [],
    selectedTab: route.query.selectedTab
      ? (route.query.selectedTab as string)
      : '',
  }

  const selectedItems: Ref<{ [key: string]: Array<TableRow> }> = ref({
    users: [],
    invites: [],
  })

  const toggleSelectedItems = (
    entitiesName: string,
    row: {
      [key: string]: any
    }
  ) => {
    const selectedIndex = selectedItems.value[entitiesName].findIndex(
      (item) => item._id === row._id
    )
    if (selectedIndex < 0) {
      selectedItems.value[entitiesName].push(row)
    } else {
      selectedItems.value[entitiesName].splice(selectedIndex, 1)
    }
  }

  const onSearchInputChange = debounce((searchText: string) => {
    if (searchText.length > 2 || searchText.length === 0) {
      push({
        query: { ...route.query, search: searchText, page: 1 },
      })
    }
  }, 300)

  const onDateInputChange = (range: DateRange) => {
    push({
      query: {
        ...route.query,
        startDate: format(range.start, 'yyyy-MM-dd'),
        endDate: format(range.end, 'yyyy-MM-dd'),
        page: 1,
      },
    })
  }

  const onMarketingPlatformChange = (marketingPlatform: string) => {
    push({
      query: {
        ...route.query,
        marketingPlatform: marketingPlatform,
        page: 1,
      },
    })
  }

  const onTypeChange = (type: string) => {
    push({
      query: {
        ...route.query,
        type: type,
        page: 1,
      },
    })
  }

  const onTableSort = (sortedBy: string, sortOrder: string) => {
    push({
      query: {
        ...route.query,
        sortedBy,
        sortOrder,
        page: 1,
      },
    })
  }

  /**
   * Returns the table filters based on query params from current route.
   */
  const getFiltersFromCurrentRoute = () => {
    const {
      sortedBy,
      sortOrder,
      search,
      startDate,
      endDate,
      marketingPlatform,
      type,
      page,
    } = currentRoute.value.query

    return {
      sortedBy: sortedBy as string,
      sortOrder: sortOrder as string,
      search: search ? String(search) : undefined,
      startDate: startDate ? String(startDate) : undefined,
      endDate: endDate ? String(endDate) : undefined,
      marketingPlatform: !isEmpty(marketingPlatform)
        ? String(marketingPlatform).split(',')
        : [],
      type: !isEmpty(type) ? String(type).split(',') : [],
      perPage: LIMIT_PER_PAGE,
      page: Number(page) || 1,
    }
  }

  return {
    onTableSort,
    onSearchInputChange,
    onDateInputChange,
    onMarketingPlatformChange,
    onTypeChange,
    queryInitialValues,
    getFiltersFromCurrentRoute,
    selectedItems,
    toggleSelectedItems,
  }
}
