
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { offerTagCardStyle } from './styles'
import { Offer } from '@/types/models'
import {
  transformOfferFromApi,
  translateOffer,
} from '@/graphqlBackOffice/campaigns/transformers'
import BckOfferCard from '@/components/ui/BckOfferCard.vue'
import { Sizes } from '@/utils'

export default defineComponent({
  components: { BckOfferCard },
  props: {
    offers: {
      type: Object as PropType<Offer[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'small',
    },
  },

  emits: ['change'],

  setup() {
    const translate = (offer: Offer) => {
      return translateOffer(transformOfferFromApi(offer), offer.languages[0])
    }
    const { push } = useRouter()

    const goToOffer = (offerId: string) => {
      push({ name: 'Edit Offer', params: { offerId: offerId } })
    }

    return {
      translate,
      offerTagCardStyle,
      goToOffer,
    }
  },
})
