
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { destroySession } from '@/graphqlBackOffice/auth/session'
import { useStore } from '@/store'
import apolloClient from '@/helpers/apolloClient'
import { isAuthorized } from '@/services/auth/authService'
import { Permissions } from '@/services/auth/permissions'
import {
  headerAccountIcon,
  headerAccountIconExtraPadding,
  headerMenuItem,
  createPopoverStyle,
} from '@/components/layout/styles'
import { headerButton } from './styles/UserNav'

export default defineComponent({
  name: 'UserNavigation',

  setup() {
    const { push } = useRouter()
    const store = useStore()

    const canAccessAdminPanel = isAuthorized(Permissions.ACCESS_ADMIN_PANEL)

    const logout = () => {
      store.dispatch('authModule/setIsLoggedIn', false)
      destroySession()
      apolloClient.cache.reset()

      push({ path: '/login' })
    }

    return {
      canAccessAdminPanel,
      logout,
      popoverButtonContent: headerButton('primary-navy', 'white', '20px'),
      logOutButton: headerButton('primary-navy', 'white'),
      headerAccountIcon,
      headerAccountIconExtraPadding,
      headerMenuItem,
      popover: createPopoverStyle({
        position: 'bottom',
        distance: '0px',
        horizontalPosition: { right: 10 },
        align: 'left',
        size: 'small',
        padding: 'small',
      }),
    }
  },
})
