import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { AuthModuleState } from '@/store/modules/auth/state'
import { User } from '@/types/models'

interface Getters<S = AuthModuleState> {
  isLoggedIn(state: S): boolean
  currentUser(state: S): User | null
}

export const getters: GetterTree<AuthModuleState, RootState> & Getters = {
  isLoggedIn: (state) => {
    return state.isLoggedIn
  },
  currentUser: (state) => {
    return state.user
  },
}
