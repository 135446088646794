const statuses = {
  ACTIVE: {
    label: 'Active',
    color: 'success',
  },
  ACCEPTED: {
    label: 'Accepted',
    color: 'success',
  },
  DISABLED: {
    label: 'Disabled',
    color: 'danger',
  },
  EXPIRED: {
    label: 'Expired',
    color: 'danger',
  },
  PENDING: {
    label: 'Pending',
    color: 'primary',
  },
}

export default statuses
