
import { defineComponent, PropType } from 'vue'
import { funnelContentCard, FunnelCardType } from '@/components/ui/styles'
import { Color, Sizes } from '@/utils'

export default defineComponent({
  name: 'BckFunnelContentCard',
  props: {
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    padding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    color: {
      type: String as PropType<Color>,
      default: 'primary-navy',
    },
    type: {
      type: String as PropType<FunnelCardType>,
      default: 'medium',
    },
    active: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { funnelContentCard }
  },
})
