import { style } from 'typestyle'
import { loadingOverlay } from '@/components/ui/styles/BckLoading'

export const pageContent = (height: string, newPageStyle: boolean): string => {
  return style({
    height: height,
    $nest: {
      '.text': {
        width: '500px',
      },
      '.main-content': {
        ...(newPageStyle
          ? {
              backgroundColor: 'transparent',
              boxShadow: 'unset',
              margin: '20px 0px',
              padding: '0 12px',
            }
          : {}),
        height: '100%',
      },
    },
  })
}

export const pageContentLoading = (isLoading: boolean): string =>
  style({
    height: '100%',
    position: 'relative',
    ...(isLoading && loadingOverlay),
  })
