
import { defineComponent, ref, onMounted, computed, PropType } from 'vue'
import { useField } from 'vee-validate'
import {
  dangerText,
  inputStyle,
  inputWrapper,
  helperText as helperTextStyle,
  formError,
  formErrorSpacingPlaceholder,
} from '@/components/ui/styles'
import { generateRandomString } from '@/utils/textHelpers'
import { Sizes } from '@/utils'

export default defineComponent({
  name: 'BckInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    htmlId: {
      type: String,
      default: '',
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Placeholder',
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    maxCharacters: {
      type: Number,
      default: 0,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: undefined,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    withSpacing: {
      type: Boolean,
      default: true,
    },
    executeOnBlur: {
      type: Function,
      default: undefined,
    },
    executeOnEnter: {
      type: Function,
      default: undefined,
    },
    noError: {
      type: Boolean,
      default: false,
    },
    isFirstTime: {
      type: Boolean,
      default: true,
    },
    // Field name used for error messages
    uiFieldName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const inputRef = ref()
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    })
    const maxCharsError = ref('')

    const onInputBlur = () => {
      // Clear max characters error, the field cannot be invalid because of this so it's just a warning.
      maxCharsError.value = ''
      handleBlur()
      props.executeOnBlur && props.executeOnBlur()
    }

    const onInputEnter = () => {
      props.executeOnEnter && props.executeOnEnter()
    }

    const onKeypress = ($event: KeyboardEvent) => {
      if (!props.maxCharacters) {
        return
      }
      const target = $event.target as HTMLInputElement
      const newVal = target.value + $event.key

      if (newVal.length > props.maxCharacters) {
        maxCharsError.value = `The
        ${props.uiFieldName || props.placeholder?.toLowerCase()} field may not exceed
         ${props.maxCharacters} characters.`
      } else {
        maxCharsError.value = ''
      }
    }

    const onInputChange = (event: Event) => {
      handleChange(event, true)
      if (props.onChange) {
        props.onChange(inputValue.value)
      }

      if (props.maxCharacters && inputValue.value.length <= props.maxCharacters) {
        maxCharsError.value = ''
      }
    }

    const className = inputStyle({
      hasValue: !!inputValue.value,
      fullWidth: props.fullWidth,
      withNoBorderStyle: props.noBorder,
      paddingLeft: props.alignLeft ? '0' : '10px',
      paddingRight: props.maxCharacters > 0 ? (props.maxCharacters > 100 ? '65px' : '58px') : '0',
    })

    const helperText = computed(() => {
      if (props.maxCharacters && inputValue.value) {
        return `${inputValue.value.length} of ${props.maxCharacters}`
      }

      return ''
    })

    onMounted(() => {
      if (props.focusOnMount) {
        const input: HTMLIFrameElement | null = inputRef.value
        input?.focus()
      }
    })

    return {
      inputRef,
      onKeypress,
      onInputChange,
      onInputEnter,
      onInputBlur,
      errorMessage,
      inputValue,
      maxCharsError,
      meta,
      helperText,
      css: {
        inputWrapper,
        helperTextStyle,
        formError,
        formErrorSpacingPlaceholder,
        dangerText,
        className,
      },
      showForTheFirstTime: computed(() => props.isFirstTime),
      id: props.htmlId || generateRandomString(),
    }
  },
})
