import { LANGUAGES } from '@/utils/constants'
import { TranslationItem, TranslationResource } from '@/types/models'
import { forEach, isArray, keys } from 'lodash'
import { ITranslationResource } from '@/types/graphql/product'

export const getLanguageFromKey = (key: string): string => {
  key = key === 'gb' ? 'en' : key
  return LANGUAGES.find((item) => item.value === key)?.label || ''
}

export const transformTranslationsForApi = (
  translations: TranslationItem | undefined,
  languages?: string[]
) => {
  if (!translations) {
    return { translations: [] }
  }
  languages = languages || Object.keys(translations)
  const apiTranslations: any = []
  forEach(languages, (language) => {
    apiTranslations.push({
      lang: language,
      value: isArray(translations[language])
        ? translations[language][0]
        : translations[language],
    })
  })

  return { translations: apiTranslations }
}

export const transformTranslationsFromApi = (
  apiTranslations: ITranslationResource | undefined
): TranslationResource => {
  const translations: TranslationResource = { translations: {} }
  apiTranslations?.translations?.forEach((item) => {
    translations.translations[`${item.lang}`] = item.value
  })
  return translations
}

export const getFirstAvailableLanguage = (field: TranslationResource): string =>
  keys(field?.translations)?.[0] || 'en'

export const getFirstAvailableLanguageFromArray = (field: any): string =>
  field?.translations?.[0].lang || 'en'

export const getFirstTranslationOfField = (
  field: TranslationResource
): string =>
  (field?.translations?.[getFirstAvailableLanguage(field)] as string) || ''

export const setDefaultApiTranslations = (
  languages: string[],
  defaultValue: string
): ITranslationResource => {
  const translations: ITranslationResource = { translations: [] }
  forEach(languages, (language) => {
    translations.translations.push({
      lang: language,
      value: defaultValue,
    })
  })

  return translations
}
