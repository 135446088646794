export const DEFAULT = 'default'
export const BLACK = 'black'
export const WHITE = 'white'
export const BACKGROUND = 'background'
export const PRIMARY_BLUE = 'primary-blue'
export const PRIMARY_BLUE_10 = 'primary-blue-10'
export const PRIMARY_BLUE_20 = 'primary-blue-20'
export const PRIMARY_BLUE_30 = 'primary-blue-30'
export const PRIMARY_BLUE_40 = 'primary-blue-40'
export const PRIMARY_BLUE_50 = 'primary-blue-50'
export const PRIMARY_BLUE_60 = 'primary-blue-60'
export const PRIMARY_BLUE_70 = 'primary-blue-70'
export const PRIMARY_BLUE_80 = 'primary-blue-80'
export const PRIMARY_BLUE_90 = 'primary-blue-90'
export const PRIMARY_NAVY = 'primary-navy'
export const PRIMARY_NAVY_10 = 'primary-navy-10'
export const PRIMARY_NAVY_20 = 'primary-navy-20'
export const PRIMARY_NAVY_30 = 'primary-navy-30'
export const PRIMARY_NAVY_40 = 'primary-navy-40'
export const PRIMARY_NAVY_50 = 'primary-navy-50'
export const PRIMARY_NAVY_60 = 'primary-navy-60'
export const PRIMARY_NAVY_70 = 'primary-navy-70'
export const PRIMARY_NAVY_80 = 'primary-navy-80'
export const PRIMARY_NAVY_90 = 'primary-navy-90'
export const PRIMARY_RED = 'primary-red'
export const PRIMARY_GREEN = 'primary-green'
export const PRIMARY_YELLOW = 'primary-yellow'
export const CHARCOAL_BLUE = 'charcoal-blue'
export const SECONDARY_LIGHT_GRAY = 'secondary-light-gray'
export const SECONDARY_GRAY = 'secondary-gray'
export const SECONDARY_DARK_BLUE = 'secondary-dark-blue'
export const SECONDARY_DARKER_BLUE = 'secondary-darker-blue'
export const SECONDARY_DARK_RED = 'secondary-dark-red'
export const SECONDARY_DARKER_RED = 'secondary-darker-red'
export const SECONDARY_DARK_GREEN = 'secondary-dark-green'
export const SECONDARY_DARKER_GREEN = 'secondary-darker-green'
export const SECONDARY_PURPLE = 'secondary-purple'
export const BRAND_WHATSAPP = 'brand-whatsapp'
export const BRAND_SWASHLY_DARK_BLUE = 'swashly-dark-blue'
export const BRAND_SWASHLY_TRANSPARENT_30_BLACK = 'swashly-transparent-30-black'
export const BRAND_SWASHLY_TRANSPARENT_80_BLACK = 'swashly-transparent-80-black'
export const BRAND_SWASHLY_LIGHT_SKY_BLUE = 'swashly-light-sky-blue'
export const BRAND_SWASHLY_GRAY = 'swashly-gray'
export const BRAND_SWASHLY_RED = 'swashly-red'

