import { ActionContext, ActionTree } from 'vuex'
import { RootState } from '@/store'
import { Campaign, Funnel, Offer, ReadMoreTemplate } from '@/types/models'
import { CampaignsState } from '@/store/modules/campaign/state'

interface Actions<C = ActionContext<CampaignsState, RootState>> {
  setFunnel(commit: C, funnel: Funnel | undefined): void
  setNewCampaign(commit: C, campaign: Campaign | undefined): void
  setNewOffer(commit: C, offer: Offer | undefined): void
  setSelectedLanguage(commit: C, language: string): void
  saveNewOfferReadMoreTemplate(commit: C, readMoreTemplate: ReadMoreTemplate | undefined): void
  saveReadMoreTemplate(commit: C, readMoreTemplate: ReadMoreTemplate | undefined): void
}

export const actions: ActionTree<CampaignsState, RootState> & Actions = {
  setFunnel({ commit }, funnel) {
    commit('SET_FUNNEL', funnel)
  },

  setNewCampaign({ commit }, campaign) {
    commit('SET_NEW_CAMPAIGN', campaign)
  },

  setNewOffer({ commit }, offer) {
    commit('SET_NEW_OFFER', offer)
  },

  updateOffer({ commit }, languages) {
    commit('UPDATE_OFFER', languages)
  },

  setSelectedLanguage({ commit }, language) {
    commit('SET_SELECTED_LANGUAGE', language)
  },

  saveNewOfferReadMoreTemplate({ commit }, readMoreTemplate) {
    commit('SET_NEW_OFFER_READ_MORE_TEMPLATE', readMoreTemplate)
  },

  saveReadMoreTemplate({ commit }, readMoreTemplate) {
    commit('SET_READ_MORE_TEMPLATE', readMoreTemplate)
  },
}
