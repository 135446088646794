import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckButton = _resolveComponent("BckButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.cardClasses)
  }, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.css.cardImage({ image: _ctx.image }), 'img'])
        }, null, 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css.cardContent(!!_ctx.image))
    }, [
      _createElementVNode("div", null, [
        (!_ctx.image && _ctx.iconName)
          ? (_openBlock(), _createBlock(_component_BckIcon, {
              key: 0,
              "icon-name": _ctx.iconName,
              "view-box": "0 0 550 550"
            }, null, 8, ["icon-name"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.cardTitleClass)
        }, _toDisplayString(_ctx.title), 3),
        _createVNode(_component_BckText, {
          margin: "small no medium no",
          class: _normalizeClass(
            _ctx.css.cardSubtitle({
              subtitleAlign: _ctx.subtitleAlign,
            })
          ),
          innerHTML: _ctx.subtitle
        }, null, 8, ["class", "innerHTML"])
      ]),
      _createVNode(_component_BckButton, {
        "button-style": "lightSkyBlue",
        disabled: _ctx.disabled,
        size: "small",
        margin: "no",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('card-selected')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 2)
  ], 2))
}