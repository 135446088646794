import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckCard = _resolveComponent("BckCard")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createBlock(_component_BckGrid, { "multi-line": "" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsData, (cardData, i) => {
        return (_openBlock(), _createBlock(_component_BckCard, {
          key: i,
          margin: 
        i === 0
          ? 'xxSmall xxSmall xxSmall no'
          : i === _ctx.cardsData.length - 1
          ? 'xxSmall no xxSmall xxSmall'
          : 'xxSmall'
      ,
          title: cardData.title,
          subtitle: cardData.subtitle,
          "subtitle-align": cardData.subtitleAlign,
          "button-text": cardData.buttonText,
          image: cardData.image,
          icon: cardData.icon,
          disabled: cardData.disabled,
          selected: cardData.selected,
          "default-selection": cardData.defaultSelection,
          onCardSelected: ($event: any) => (_ctx.$emit('card-selected', i))
        }, null, 8, ["margin", "title", "subtitle", "subtitle-align", "button-text", "image", "icon", "disabled", "selected", "default-selection", "onCardSelected"]))
      }), 128))
    ]),
    _: 1
  }))
}