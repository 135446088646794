import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "value", "disabled", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.checkboxGroup(_ctx.margin))
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(_ctx.css.checkbox)
    }, [
      _createElementVNode("input", {
        name: _ctx.name,
        type: "checkbox",
        value: _ctx.value,
        class: _normalizeClass(_ctx.css.checkboxInput),
        disabled: _ctx.disabled,
        checked: _ctx.checked,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInputChange && _ctx.handleInputChange(...args)))
      }, null, 42, _hoisted_1),
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.css.checkboxCheck(_ctx.disabled), 'checkboxCheck'])
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.css.checkboxText)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2)
  ], 2))
}