
import { defineComponent } from 'vue'
import { helperStyle } from '@/views/campaigns/styles'
import BckTextBubble from '@/components/ui/BckTextBubble.vue'

export default defineComponent({
  name: 'HelperBubble',
  components: { BckTextBubble },
  props: {
    bubbleText: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    left: {
      type: String,
      default: '0px',
    },
    top: {
      type: String,
      default: '0px',
    },
  },

  setup() {
    return { helperStyle }
  },
})
