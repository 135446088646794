import { style } from 'typestyle'
import { getColor } from '@/utils'

interface LayoutPageContentType {
  dashboard: boolean
}

const layout = style({
  display: 'flex',
})

const layoutPageContent = ({ dashboard }: LayoutPageContentType): string =>
  style({
    width: '100%',
    overflow: dashboard ? 'hidden' : 'auto',
    height: 'calc(100vh - 56px)',
    backgroundColor: '#F2F3F5',
    padding: dashboard ? 0 : '20px 20px 40px 20px',
  })

export default {
  layout,
  layoutPageContent,
}
