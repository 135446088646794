
import { useField } from 'vee-validate'
import { defineComponent, PropType } from 'vue'
import { radioButtonStyle } from './styles'
import { IconType, OptionType } from '@/types/components'
import { getColor } from '@/utils'

export default defineComponent({
  props: {
    option: {
      type: Object as PropType<OptionType>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object as PropType<IconType>,
      default: () => {
        return {}
      },
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const { value, checked, handleChange } = useField(props.name, undefined, {
      type: 'radio',
      checkedValue: props.option.value,
    })

    const radioButtonClass = radioButtonStyle({ disabled: props.disabled })

    const handleClick = ($event: Event) => {
      if (props.disabled) return
      if (value.value !== $event) {
        emit('change', $event)
      }
      handleChange($event)
    }

    return {
      value,
      radioButtonClass,
      handleClick,
      checked,
      getColor,
    }
  },
})
