import { style } from 'typestyle'
import { getColor, sizeMapper, Sizes } from '@/utils'

const card = (margin: Sizes, active: boolean): string =>
  style({
    minWidth: '264px',
    width: '100%',
    height: '320px',
    borderRadius: '5px',
    background: !active ? getColor('white') : getColor('primary-blue'),
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
    padding: '15px 18px',
    margin: sizeMapper(margin),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })

const offerTag = style({
  display: 'flex',
  flexDirection: 'column',
})

const container = style({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  overflow: 'scroll',
})

const title = style({
  color: getColor('primary-blue'),
  fontSize: '14px',
  lineHeight: '17px',
})

const name = style({
  color: getColor(),
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 400,
})

export default {
  card,
  offerTag,
  container,
  title,
  name,
}
