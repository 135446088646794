import { GetterTree } from 'vuex'
import { RootState } from '@/store'
import { CampaignsState } from '@/store/modules/campaign/state'
import { Funnel } from '@/types/models'

interface Getters<S = CampaignsState> {
  funnel(state: S): Funnel | undefined
}

export const getters: GetterTree<CampaignsState, RootState> & Getters = {
  funnel: (state) => {
    return state.funnel
  },
}
