import { style } from 'typestyle'
import { NestedCSSProperties } from 'typestyle/lib/types'
import { Color, getColor, sizeMapper, Sizes } from '@/utils'
import { PRIMARY_BLUE, PRIMARY_GREEN, PRIMARY_RED } from '@/utils/constants'
import { WHITE, BRAND_SWASHLY_LIGHT_SKY_BLUE } from '../../../utils/constants/colors'

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'primaryFbAutomation'
  | 'secondaryFbAutomation'
  | 'lightSkyBlue'
  | 'transparent'
  | 'change'
  | 'remove'
  | 'languageSelector'

export type ButtonSizes =
  | 'large'
  | 'medium'
  | 'small'
  | 'tabHeader'
  | 'xsmall'
  | 'xxSmall'
  | 'facebookAutomation'
  | 'icon'
  | 'integration'
  | 'configurePage'
  | 'style-page'

interface ButtonProps {
  type: ButtonTypes
  size: ButtonSizes
  disabled?: boolean
  margin: Sizes
  isRegister?: boolean
  fullWidth?: boolean
}

export default ({ type, size, disabled = false, margin, isRegister, fullWidth }: ButtonProps): string =>
  style({
    textAlign: 'center',
    position: 'relative',
    margin: sizeMapper(margin),
    cursor: 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',
    fontFamily: '"Euclid Circular B", sans-serif',
    lineHeight: '120%',
    ...getSize(size, isRegister, fullWidth),
    ...(!disabled ? getColors(type) : getDisabledColors(type)),
    $nest: {
      '.loading': {
        position: 'absolute',
        marginTop: '2px',
        right: 10,
      },

      svg: {
        margin: '-2px 0',
        position: 'absolute',
        left: '18px',
        top: '10px',
      },
    },
  })

const getSize = (size: ButtonSizes, isRegister?: boolean, fullWidth?: boolean): NestedCSSProperties | undefined => {
  switch (size) {
    case 'large':
      return {
        width: fullWidth ? '100%' : '285px',
        height: isRegister ? '55px' : '48px',
        borderRadius: isRegister ? '35px !important' : '20px',
        fontSize: isRegister ? '20px' : '16px',
      }

    case 'medium':
      return {
        width: '200px',
        height: '36px',
        borderRadius: '20px',
        fontSize: '14px',
        letterSpacing: '0.1em',
      }

    case 'small':
      return {
        width: '200px',
        height: '32px',
        borderRadius: '20px',
        fontSize: '14px',
      }

    case 'xsmall':
      return {
        width: '200px',
        height: '28px',
        borderRadius: '20px',
        fontSize: '14px',
      }

    case 'xxSmall':
      return {
        width: '200px',
        height: '32px',
        borderRadius: '20px',
        fontSize: '14px',
      }

    case 'tabHeader': {
      return {
        width: '200px',
        height: '30px',
        padding: '0 15px',
        borderRadius: '20px',
        fontSize: '12px',
      }
    }

    case 'facebookAutomation': {
      return {
        height: 'auto',
        padding: '8px 16px',
        width: 'auto',
        borderRadius: '20px',
      }
    }

    case 'icon': {
      return {
        width: '200px',
        height: '31px',
        borderRadius: '20px',
        fontSize: '12px',
      }
    }

    case 'integration': {
      return {
        height: '48px',
        width: '260px',
        borderRadius: '20px',
        fontSize: '14px',
      }
    }

    case 'style-page': {
      return {
        height: '40px',
        width: '200px',
        borderRadius: '20px',
        fontSize: '14px',
      }
    }

    case 'configurePage': {
      return {
        height: '40px',
        width: '200px',
        borderRadius: '20px',
        fontSize: '12px',
      }
    }
  }
}

const mapColorType: { [key in ButtonTypes]: Color } = {
  primary: PRIMARY_BLUE,
  secondary: PRIMARY_BLUE,
  danger: PRIMARY_RED,
  success: PRIMARY_GREEN,
  primaryFbAutomation: PRIMARY_BLUE,
  secondaryFbAutomation: PRIMARY_BLUE,
  transparent: WHITE,
  lightSkyBlue: BRAND_SWASHLY_LIGHT_SKY_BLUE,
  change: PRIMARY_BLUE,
  remove: PRIMARY_RED,
  languageSelector: PRIMARY_BLUE,
}

const getColors = (type: ButtonTypes): NestedCSSProperties | undefined => {
  switch (type) {
    case 'primary':
    case 'danger':
    case 'success':
      return {
        border: 'none',
        background: getColor(mapColorType[type]),
        color: getColor('white'),
        $nest: {
          '&:hover': {
            background: getColor('secondary-dark-blue'),
          },
          '&:focus': {
            background: getColor('primary-navy'),
          },
          '&:active': {
            background: getColor('secondary-darker-blue'),
          },
        },
      }

    case 'primaryFbAutomation':
      return {
        border: 'none',
        background: getColor('primary-blue'),
        color: getColor('white'),
        $nest: {
          '&:hover': {
            background: getColor('primary-blue-80'),
          },
        },
      }

    case 'secondaryFbAutomation':
      return {
        border: 'none',
        background: getColor('secondary-gray'),
        color: getColor('black'),
        $nest: {
          '&:hover': {
            background: getColor('primary-blue-80'),
          },
        },
      }

    case 'secondary':
      return {
        border: `1px solid ${getColor('secondary-light-gray')}`,
        background: 'white',
        color: getColor('primary-blue'),
        $nest: {
          '&:hover': {
            border: `1px solid ${getColor('secondary-dark-blue')}`,
            color: getColor('secondary-dark-blue'),
          },
          '&:focus': {
            border: `1px solid ${getColor('primary-navy')}`,
            color: getColor('primary-navy'),
          },
          '&:active': {
            border: 'none',
            background: getColor('secondary-dark-blue'),
            color: getColor('white'),
          },
        },
      }

    case 'lightSkyBlue':
      return {
        border: 0,
        background: '#A4C3F3',
        color: getColor('white'),
        $nest: {
          '&:hover': {
            border: `1px solid ${getColor('secondary-dark-blue')}`,
            color: getColor('secondary-dark-blue'),
          },
          '&:focus': {
            border: `1px solid ${getColor('primary-navy')}`,
            color: getColor('primary-navy'),
          },
          '&:active': {
            border: 'none',
            background: getColor('secondary-dark-blue'),
            color: getColor('white'),
          },
        },
      }
    case 'transparent':
      return {
        border: `1px solid ${getColor('swashly-transparent-30-black')}`,
        //boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        background: 'white',
        color: getColor('swashly-transparent-80-black'),
        $nest: {
          '&:hover': {
            border: `1px solid ${getColor('secondary-dark-blue')}`,
            color: getColor('secondary-dark-blue'),
          },
          '&:focus': {
            border: `1px solid ${getColor('primary-navy')}`,
            color: getColor('primary-navy'),
          },
          '&:active': {
            border: 'none',
            background: getColor('secondary-dark-blue'),
            color: getColor('white'),
          },
        },
      }
    case 'languageSelector':
      return {
        border: `1px solid #3A63ED`,
        background: 'transparent',
        color: '#3A63ED',
        $nest: {
          '&:hover': {
            border: `1px solid ${getColor('secondary-dark-blue')}`,
            color: getColor('secondary-dark-blue'),
          },
          '&:focus': {
            border: `1px solid ${getColor('primary-navy')}`,
            color: getColor('primary-navy'),
          },
          '&:active': {
            border: 'none',
            background: getColor('secondary-dark-blue'),
            color: getColor('white'),
          },
        },
      }
    case 'remove':
      return {
        border: `1px solid ${getColor('secondary-light-gray')}`,
        background: 'transparent',
        color: getColor('swashly-red'),
        $nest: {
          '&:hover': {
            border: `1px solid ${getColor('secondary-dark-blue')}`,
            color: getColor('secondary-dark-blue'),
          },
          '&:focus': {
            border: `1px solid ${getColor('primary-navy')}`,
            color: getColor('primary-navy'),
          },
          '&:active': {
            border: 'none',
            background: getColor('secondary-dark-blue'),
            color: getColor('white'),
          },
        },
      }
    case 'change':
      return {
        border: `1px solid ${getColor('secondary-light-gray')}`,
        background: 'transparent',
        color: '#3A63ED',
        $nest: {
          '&:hover': {
            border: `1px solid ${getColor('secondary-dark-blue')}`,
            color: getColor('secondary-dark-blue'),
          },
          '&:focus': {
            border: `1px solid ${getColor('primary-navy')}`,
            color: getColor('primary-navy'),
          },
          '&:active': {
            border: 'none',
            background: getColor('secondary-dark-blue'),
            color: getColor('white'),
          },
        },
      }
  }
}

const getDisabledColors = (type: ButtonTypes): NestedCSSProperties | undefined => {
  switch (type) {
    case 'primary':
    case 'danger':
    case 'success':
      return {
        border: 'none',
        background: getColor('secondary-light-gray'),
        color: getColor('white'),
      }

    case 'secondary':
      return {
        border: `1px solid ${getColor('secondary-light-gray')}`,
        background: getColor('white'),
        color: getColor('secondary-gray'),
      }
  }
}
