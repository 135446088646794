import { style } from 'typestyle'
import { getColor } from '@/utils'

const ProseMirror = style({
  minHeight: '440px',
  fontSize: '10px',
  boxSizing: 'content-box',
  outline: `1px solid ${getColor('primary-navy-10')}`,
  $nest: {
    '.ProseMirror': {
      padding: '10px',
    },
    '.ProseMirror-focused': {
      minHeight: '440px',
      outline: `1px solid ${getColor('primary-blue')}`,
    },
    ':not(:focus) .is-editor-empty': {
      $nest: {
        '&:before': {
          content: 'attr(data-placeholder)',
          float: 'left',
          color: getColor('secondary-gray'),
          fontSize: '14px',
          pointerEvents: 'none',
        },
      },
    },
  },
})

export default {
  ProseMirror,
}
