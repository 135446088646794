import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const campaignInfoContainer = style({
  borderBottom: `1px solid ${getColor('secondary-gray')}`,
})

const searchInput = style({
  $nest: {
    input: {
      height: '30px',
      width: '260px',
    },
  },
})

const noFunnelsMessage = style({
  color: getColor('secondary-gray'),
  fontSize: '20px',
  letterSpacing: '0.1em',
  margin: sizeMapper('lMedium'),
  textAlign: 'center',
})

export default {
  campaignInfoContainer,
  noFunnelsMessage,
  searchInput,
}
