import {
  RouteRecordName,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router'
import {
  getSessionValue,
  isSuperAdmin,
  isAdmin,
  isAdminOnProject,
} from '@/graphqlBackOffice/auth/session'
import { isAuthorized } from '@/services/auth/authService'

const EXCLUDED_ROUTES: Array<RouteRecordName | null | undefined> = [
  'Login',
  'Register',
  'Forgot Password',
  'Reset Password',
]

export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  const user = getSessionValue('user')
  if (EXCLUDED_ROUTES.indexOf(to.name) < 0) {
    if (!user) {
      next({ name: 'Login' })
    }
  } else if (user) next({ path: '/' })
}

export const superAdminGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  if (isSuperAdmin()) {
    next()
  } else next({ path: '/' })
}

export const adminGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  if (isSuperAdmin() || isAdmin()) {
    next()
  } else next({ path: '/' })
}

export const projectAdminGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  if (
    isSuperAdmin() ||
    isAdmin() ||
    isAdminOnProject((to?.params?.id as string) || '')
  ) {
    next()
  } else next({ path: '/' })
}

export const permissionGuard =
  (permission: string) =>
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void => {
    const projectId = to?.params?.id as string

    if (isAuthorized(permission, projectId)) {
      next()
    } else next({ path: '/' })
  }

export const registerGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  if (from.name === 'Register') {
    next()
  } else next({ path: '/' })
}

export const sessionUser = getSessionValue('user')
