import { style } from 'typestyle'

export default {
  container: style({
    borderRadius: '4px',
    backgroundColor: '#f06e44',
    padding: '10px',
    $nest: { path: { fill: 'white' }, circle: { fill: 'white' } },
  }),
  link: style({ $nest: { '&:hover': { cursor: 'pointer' } } }),
}
