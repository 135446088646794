import { style } from 'typestyle'
import { getColor, fadeAndSlideInUp, Color, sizeMapper } from '@/utils'
import accountIcon from '@/assets/accountLogo.svg'
import logoFullBlack from '@/assets/logoFullBlack.png'

export const header = style({
  display: 'flex',
  justifyContent: 'space-between',
  height: '56px',
  // boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#F2F3F5', //getColor('white'),
  top: 0,
  position: 'sticky',
  zIndex: 2000,
})

export const headerLeft = style({
  alignItems: 'center',
  marginLeft: '18px',
  display: 'flex',
})

export const headerRight = style({
  alignItems: 'center',
  marginRight: '20px',
  display: 'flex',
})

export const headerLogo = style({
  backgroundSize: '140.024px 26.86px', //'109px 21px',
  width: '140.024px', //'109px',
  height: '26.86px', //'21px',
  background: `url(${logoFullBlack}) no-repeat`,
})

export const headerMenuItem = style({
  color: getColor('black'),
  fontSize: '12px',
  cursor: 'pointer',
})

export const headerAccountIcon = style({
  backgroundSize: '30px 30px',
  width: '30px',
  height: '100%',
  background: `url(${accountIcon}) no-repeat center center`,
  position: 'relative',
})

export const headerAccountIconExtraPadding = style({
  width: '100px',
  right: '-20px',
  position: 'absolute',
  height: '100%',
  $nest: {
    '.popover': {
      display: 'none',
    },
    '&:hover': {
      $nest: {
        '.popover': {
          display: 'block',
          animation: `${fadeAndSlideInUp} 0.3s ease-out`,
        },
      },
    },
  },
})

export const verticalLine = style({
  borderLeft: `1px solid ${getColor('black')}`,
  height: '32px',
  margin: '0 16px',
})

export const headerButton = (
  color: Color,
  iconColor: Color,
  marginBottom?: string
): string => {
  return style({
    cursor: 'pointer',
    color: getColor(color),
    marginRight: sizeMapper('small'),
    marginBottom,
    fontSize: '12px',
    paddingLeft: '12px',
    $nest: {
      svg: {
        width: '14px',
        height: '14px',
        color: getColor(iconColor),
        marginRight: '8px',
      },
      a: {
        display: 'flex',
        alignItems: 'center',
      }
    },
  })
}

export default {
  header,
  headerLeft,
  headerRight,
  headerLogo,
  headerMenuItem,
  headerAccountIcon,
  verticalLine,
  headerButton,
}
