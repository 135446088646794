import Cookie from 'js-cookie'
import { find, get } from 'lodash'
import { ProjectRole, User } from '@/types/models'

const COOKIE_KEY = 'session'

export const setProjectRolesCookie = (projectRoles: ProjectRole[]): void => {
  Cookie.set('projectRoles', JSON.stringify({ projectRoles }))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCookieValue = (key: string): any =>
  get(JSON.parse(Cookie.get(key) || '{}'), key)

export const createSession = (
  authToken: string,
  refreshToken: string,
  user: User
): void => {
  Cookie.set(COOKIE_KEY, JSON.stringify({ authToken, refreshToken, user }))
  setProjectRolesCookie(user.projectRoles)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSessionValue = (key: string, defaultValue?: string): any =>
  get(JSON.parse(Cookie.get(COOKIE_KEY) || '{}'), key, defaultValue)

export const setSessionValue = (key: string, value: any): any => {
  const currentSession = JSON.parse(Cookie.get(COOKIE_KEY) || '{}')
  Cookie.set(COOKIE_KEY, JSON.stringify({ ...currentSession, [key]: value }))
}

export const updateAuthToken = (authToken: string): void => {
  Cookie.set(
    COOKIE_KEY,
    JSON.stringify({
      authToken,
      refreshToken: getSessionValue('refreshToken'),
      user: getSessionValue('user'),
    })
  )
}

export const destroySession = (): void => {
  Cookie.remove(COOKIE_KEY)
  Cookie.remove('grafana_session')
}

export const removeUserCookies = (): void => {
  Cookie.remove('projectRoles')
  Cookie.remove('grafana_session')
  destroySession()
}

export const isSuperAdmin = (): boolean => {
  return getSessionValue('user.role') === 'SUPER_ADMIN'
}

export const isAdmin = (): boolean => {
  return getSessionValue('user.role') === 'ADMIN'
}

export const isAdminOnProject = (projectId: string): boolean =>
  getProjectRole(projectId)?.role === 'CLIENT'

export const getProjectRole = (projectId: string): ProjectRole =>
  find(
    getCookieValue('projectRoles'),
    (projectRole: ProjectRole) => projectRole.projectId === projectId
  )

export const getUserRole = (): string => {
  return getSessionValue('user.role')
}
