import { style } from 'typestyle'
import { getColor, sizeMapper, Sizes } from '@/utils'

const card = (margin: Sizes, active = false, disabled = false): string =>
  style({
    width: '264px',
    // height: '374px',
    borderRadius: '20px',
    background: !active ? getColor('white') : getColor('primary-blue'),
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    padding: '21px 19px',
    margin: sizeMapper(margin),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'initial',
    ...(disabled && { opacity: '0.6' }),
  })

const offerGroup = style({
  display: 'flex',
  justifyContent: 'space-between',
  $nest: {
    div: {
      fontSize: '14px',
    },
    '&:first-child': {
      marginBottom: '14px',
    },
    '&:nth-child(2)': {
      marginBottom: '3px',
    },
  },
})

const title = style({
  width: '100%',
  color: getColor('primary-blue'),
  fontSize: '17px',
  lineHeight: '19px',
  cursor: 'pointer',
})

const buttonStyle = {
  width: '32px',
  height: '20px',
  cursor: 'pointer',
  borderRadius: '3px',
}

const copyButton = style({
  ...buttonStyle,
  backgroundColor: getColor('primary-blue'),
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExLjUzNiAxMkg0LjY0MmEuODUuODUgMCAwIDEtLjg0OC0uODZWNC4yMzNjMC0uNDc1LjM4Ni0uODQ3Ljg0OC0uODQ3aDYuOTA3Yy40NzUgMCAuODYuMzg1Ljg2Ljg0N3Y2LjkyYS44NjkuODY5IDAgMCAxLS44NzMuODQ3WiIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9Ii41NTMiLz48cGF0aCBkPSJNMi4zOTUgOC45NDVIMS4yMTRhLjg4OC44ODggMCAwIDEtLjg4Ni0uODg2VjEuMjAzYzAtLjQ4OC4zOTgtLjg4Ni44ODYtLjg4Nmg2Ljg0M2MuNDg4IDAgLjg4Ni4zOTguODg2Ljg4NlYyLjI3YS4yNTcuMjU3IDAgMCAxLS4yNTcuMjU3LjI1Ny4yNTcgMCAwIDEtLjI1Ny0uMjU3VjEuMjAzYS4zNzMuMzczIDAgMCAwLS4zNzItLjM3MkgxLjIxNGEuMzczLjM3MyAwIDAgMC0uMzcyLjM3MlY4LjA2YzAgLjIwNS4xNjcuMzcyLjM3Mi4zNzJoMS4xODFhLjI1Ny4yNTcgMCAwIDEgMCAuNTE0WiIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9Ii41NTMiLz48L3N2Zz4=') right 10px center no-repeat",
})

const deleteButton = style({
  ...buttonStyle,
  backgroundColor: getColor('primary-red'),
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQgMi4yODZ2LS44NTdBLjQyOS40MjkgMCAwIDEgNC40MjkgMUg3YS40MjkuNDI5IDAgMCAxIC40MjkuNDI5di44NTdNMSAyLjcxNGg5LjQyOSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTAgMy41NzFIMS40NUEuNDI5LjQyOSAwIDAgMCAxIDRsLjkgOC4xNDNhLjg1Ny44NTcgMCAwIDAgLjg5Ni44NTdoNS44MzdhLjg1Ni44NTYgMCAwIDAgLjg5Ni0uODU3bC45LTguMTQzQS40MjkuNDI5IDAgMCAwIDEwIDMuNTdaTTQuNDMgMTAuODU3YS40MjkuNDI5IDAgMCAxLS44NTggMFY2LjU3YS40MjkuNDI5IDAgMSAxIC44NTggMHY0LjI4NlptMy40MjggMGEuNDI5LjQyOSAwIDAgMS0uODU3IDBWNi41N2EuNDI5LjQyOSAwIDAgMSAuODU3IDB2NC4yODZaIiBmaWxsPSIjZmZmIi8+PC9zdmc+') right 10px center no-repeat",
})

const addNewButtonStyle = {
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'space-around',
  padding: '100px 75px',
}

const addNewButtonSvg = style({
  width: '100%',
  height: '100%',
  maxWidth: '69.17px',
  maxHeight: '64.12px',
  margin: 'auto',
})

export default {
  card,
  deleteButton,
  offerGroup,
  title,
  copyButton,
  addNewButtonStyle,
  addNewButtonSvg,
}
