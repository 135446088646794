export const theme = {
  // primary
  black: '#000000',
  white: '#ffffff',

  primaryBlue: '#3A63ED',
  primaryBlue10: '#eaf2f9',
  primaryBlue20: '#d5e5f3',
  primaryBlue30: '#c0d8ed',
  primaryBlue40: '#abcbe6',
  primaryBlue50: '#96bee0',
  primaryBlue60: '#81b1d9',
  primaryBlue70: '#6ca4d3',
  primaryBlue80: '#5797cc',
  primaryBlue90: '#428ac6',

  primaryNavy: '#1E2E39',
  primaryNavy10: '#e9ebec',
  primaryNavy20: '#d2d6d8',
  primaryNavy30: '#d2d6d8',
  primaryNavy40: '#a5acb0',
  primaryNavy50: '#a5acb0',
  primaryNavy60: '#788288',
  primaryNavy70: '#788288',
  primaryNavy80: '#4b5860',
  primaryNavy90: '#4b5860',

  primaryRed: '#F06E44',
  primaryGreen: '#12A613',
  primaryYellow: '#FFD452',

  // hues
  lightBlue: '#BDC7E3',
  charcoalBlue: '#232D36',

  // secondary
  secondaryLightGray: '#C4C4C4',
  secondaryGray: '#C6C6C6',
  secondaryDarkBlue: '#1C59D0',
  secondaryDarkerBlue: '#1245A8',
  secondaryDarkRed: '#E05E34',
  secondaryDarkerRed: '#C64B23',
  secondaryDarkGreen: '#25B286',
  secondaryDarkerGreen: '#319A79',
  secondaryPurple: '#B937F5',

  // brand colors
  brandWhatsapp: '#4CAF50',
  //swashly brand colors
  brandSwashlyDarkBlue: '#06103A',
  brandSwashlyTransparent30Black: '#0000004D',
  brandSwashlyTransparent80Black: '#000000CC',
  brandSwashlyLightSkyBlue: 'A4C3F3',
  brandSwashlyRed: '#DB493A',
  brandSwashlyGray: '#808080',
}
