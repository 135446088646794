import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.css.projectCard({ margin: _ctx.margin })),
    type: "button",
    disabled: _ctx.disabled
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css.projectCardHeader)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.css.projectCardHeaderTitle)
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.css.projectCardHeaderSubTitle)
      }, _toDisplayString(_ctx.subTitle), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css.projectCardBody(_ctx.icon))
    }, [
      (_ctx.hasIssues)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.css.projectCardIssues)
          }, "!", 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 10, _hoisted_1))
}