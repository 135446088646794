import { style } from 'typestyle'
import { getColor } from '@/utils'

const menuItem = (isActive: boolean): string =>
  style({
    width: '28px',
    height: '28px',
    color: isActive ? getColor('white') : getColor('primary-navy'),
    border: 'none',
    backgroundColor: isActive ? getColor('primary-navy') : 'transparent',
    borderRadius: '7px',
    padding: '4px',
    marginRight: '4px',
    $nest: {
      svg: {
        width: '100%',
        height: '100%',
        fill: 'currentColor',
      },
      '&:hover': {
        color: getColor('white'),
        backgroundColor: getColor('black'),
      },
    },
  })

export default {
  menuItem,
}
