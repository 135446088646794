
import { defineComponent, PropType } from 'vue'
import { Color, Sizes, TextSize, FontWeight } from '@/utils'
import { regular } from '@/components/ui/styles'

export default defineComponent({
  name: 'BckText',
  props: {
    size: {
      type: String as PropType<TextSize>,
      default: 'regular',
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    padding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    color: {
      type: String as PropType<Color>,
      default: 'primary-navy',
    },
    weight: {
      type: String as PropType<FontWeight>,
      default: 'normal',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: 'start',
    },
    display: {
      type: String,
      default: 'block',
    },
  },

  setup() {
    return { regular }
  },
})
