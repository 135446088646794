import { createApp, h } from 'vue'
import Toast from 'vue-toastification'
import './utils/axiosInterceptors'
import App from './App.vue'
import router from './router'
import { store, key } from './store'
import components from './components'

createApp({
  render: () => h(App),
})
  .use(store, key)
  .use(router)
  .use(Toast)
  .use(components)
  .mount('#app')
