import { isArray } from 'lodash'

export const getImagePreview = (
  file: File | FileList | File[] | string | undefined | null
): string => {
  if (isArray(file) || file instanceof FileList) {
    file = file[0]
  }
  return file && typeof file === 'object'
    ? URL.createObjectURL(file)
    : file || ''
}
