import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckLoading = _resolveComponent("BckLoading")!
  const _component_BckOfferCard = _resolveComponent("BckOfferCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.offerTagCardStyle.card(_ctx.margin))
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_BckLoading, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.offerTagCardStyle.container)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offers, (offer, i) => {
            return (_openBlock(), _createBlock(_component_BckOfferCard, {
              key: i,
              offer: _ctx.translate(offer),
              margin: "xxSmall",
              onClick: ($event: any) => (_ctx.goToOffer(offer._id))
            }, null, 8, ["offer", "onClick"]))
          }), 128))
        ], 2))
  ], 2))
}