import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const tableItemWrapper = style({
  position: 'relative',
  background: getColor('primary-navy-10'),
  padding: sizeMapper('xxSmall'),
  marginTop: sizeMapper('xxSmall'),
})

const templateTitleWrapper = style({
  background: getColor('primary-navy-10'),
  padding: sizeMapper('xxSmall small'),
  marginTop: sizeMapper('xSmall'),
})

export default { tableItemWrapper, templateTitleWrapper }
