import { style } from 'typestyle'
import { getColor } from '@/utils'
import seamlessPreview from '@/assets/seamlessPreview.png'

const container = style({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
})

const descriptionContainer = style({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
})

export const content = style({
  position: 'relative',
  width: '1000px',
})

export const contentWelcome = style({
  position: 'relative',
})

const welcomeTitle = style({
  fontWeight: 400,
  fontSize: '32px',
  letterSpacing: '0.1em',
  lineHeight: '38px',
  color: getColor(),
})

export const title = style({
  fontSize: '32px',
  letterSpacing: '0.1em',
  lineHeight: '38px',
  color: getColor(),
})

const subtitle = style({
  marginTop: '32px',
  fontSize: '16px',
  lineHeight: '21px',
  color: getColor(),
})

export const stepsSubtitle = style({
  marginTop: '16px',
  fontSize: '14px',
  lineHeight: '18px',
  color: getColor(),
})

export const stepsLabel = style({
  marginTop: '48px',
  fontSize: '20px',
  lineHeight: '28px',
  color: getColor(),
})

const mobilePreview = style({
  display: 'flex',
  justifyContent: 'flex-end',
})

const seamlessMobilePreviewImage = style({
  width: '510px',
  height: '700px',
  background: `url(${seamlessPreview}) no-repeat center center`,
  borderRadius: '44px',
  //boxShadow: `0 0 5px 0 ${getColor('secondary-gray')}`,
  backgroundSize: '510px 700px',
})

const checkedIcon = style({
  width: '21px',
  height: '21px',
  zIndex: 1000,
})

const projectSteps = style({
  marginTop: '32px',
  position: 'relative',

  $nest: {
    '&:after': {
      content: "''",
      top: 5,
      left: 10,
      position: 'absolute',
      borderLeft: '1px dashed black',
      height: '133px',
    },
  },
})

const projectStep = style({
  display: 'flex',
  marginBottom: '40px',
  alignItems: 'center',

  $nest: {
    span: {
      color: getColor(),
      fontSize: '16px',
      marginLeft: '16px',
    },
  },
})

const stepMessage = style({
  display: 'flex',
  fontSize: '16px',
  lineHeight: '18px',
  alignItems: 'center',
  position: 'absolute',
  top: -50,
  left: 0,
  color: getColor('secondary-gray'),

  $nest: {
    svg: {
      marginRight: '10px',
    },
  },
})

const cancelIcon = style({
  width: '14px',
  height: '14px',
})

const cardsContainer = style({
  marginTop: '32px',
  marginBottom: '48px',
})

const formContainer = style({
  marginBottom: '167px',
})

const inputContainer = style({
  marginBottom: '48px',
})

const checkboxLabel = style({
  fontSize: '17px',
  lineHeight: '17px',
  cursor: 'pointer',
  fontWeight: 'normal',
  position: 'relative',
  top: '-1px',
  $nest: {
    a: {
      fontSize: '16px',
      lineHeight: '17px',
      textDecoration: 'none',
      color: getColor('primary-blue'),
      cursor: 'pointer',
      $nest: {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
})

const nextButtonContainer = style({
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
})

const line = style({
  borderLeft: `1px solid ${getColor('black')}`,
  height: '480px',
})

export default {
  container,
  descriptionContainer,
  content,
  contentWelcome,
  welcomeTitle,
  title,
  subtitle,
  stepsSubtitle,
  stepsLabel,
  mobilePreview,
  seamlessMobilePreviewImage,
  checkedIcon,
  projectSteps,
  projectStep,
  stepMessage,
  cancelIcon,
  cardsContainer,
  formContainer,
  inputContainer,
  checkboxLabel,
  nextButtonContainer,
  line,
}
