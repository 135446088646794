import { style } from 'typestyle'
import { getColor } from '@/utils'

const dateInput = style({
  cursor: 'pointer',
  width: '100%',
  maxWidth: '230px',
  height: '30px',
  padding: '8px',
  paddingRight: '19px',
  color: getColor('primary-navy-60'),
  backgroundColor: getColor('white'),
  outline: 'none',
  border: `1px solid ${getColor('secondary-light-gray')}`,
  borderRadius: '3px',
  lineHeight: '14px',
  fontWeight: 500,
  fontSize: '12px',
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iIzM5ODJGNyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=') right 8px center no-repeat",
  $nest: {
    '&:placeholder': {
      color: getColor('primary-navy-40'),
    },
  },
})

const dateInputContainer = style({
  position: 'relative',
  $nest: {
    '.loading': {
      position: 'absolute',
      marginTop: '8px',
    },
  },
})

export default {
  dateInput,
  dateInputContainer,
}
