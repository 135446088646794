import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationMenu = _resolveComponent("NavigationMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "projectContainer",
    ref: "projectContainer",
    class: _normalizeClass([_ctx.css.layout, 'projectContainer'])
  }, [
    _createVNode(_component_NavigationMenu, { "project-id": _ctx.id }, null, 8, ["project-id"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css.layoutPageContent({ dashboard: _ctx.dashboard }))
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ], 2))
}