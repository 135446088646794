
import { defineComponent, watch, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
import '@/assets/css/global.css'
import {
  setProjectRolesCookie,
  getSessionValue,
  setSessionValue,
} from './graphqlBackOffice/auth/session'
import { USER } from './graphqlBackOffice/users/queries'
import apolloClient from './helpers/apolloClient'

export default defineComponent({
  setup() {
    const { currentRoute } = useRouter()
    const store = useStore()
    const isLoggedIn = ref(store.state.authModule.isLoggedIn)

    const onPageLoad = () => {
      isLoggedIn.value = store.state.authModule.isLoggedIn
    }

    setInterval(async () => {
      const user = getSessionValue('user')
      const result = await apolloClient.query({
        query: USER,
        variables: { filter: { _id: user._id } },
        fetchPolicy: 'network-only',
      })
      setSessionValue('user', result.data.user)
      setProjectRolesCookie(result.data.user.projectRoles)
    }, 60000)
    watch(currentRoute, onPageLoad)

    return { isLoggedIn }
  },
})
