import { style } from 'typestyle'
import { sizeMapper, Sizes } from '@/utils'
import { Property } from 'csstype'

interface GridType {
  margin?: Sizes
  padding?: Sizes
  justifyContent?: Property.JustifyContent
  direction: Property.FlexDirection
  alignItems?: string
  gap?: string
  multiLine?: boolean
}

export default ({
  margin = 'no',
  padding = 'no',
  gap = 'no',
  justifyContent,
  direction,
  alignItems = 'stretch',
  multiLine,
}: GridType): string =>
  style({
    display: 'flex',
    gap: gap,
    width: '100%',
    alignItems: alignItems,
    justifyContent: 'right',
    margin: sizeMapper(margin),
    padding: sizeMapper(padding),
    flexDirection: direction,
    ...(justifyContent && { justifyContent }),
    ...(multiLine && { flexWrap: 'wrap' }),
  })
