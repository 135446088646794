import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const labelWrapper = style({
  width: '85px',
  whiteSpace: 'nowrap',
  margin: sizeMapper('xSmall medium no no'),

  $nest: {
    label: {
      fontSize: '14px',
    },
  },
})

const colorPicker = style({
  width: '150px',
  margin: sizeMapper('xSmall medium no no'),
  position: 'relative',
  $nest: {
    'input[type="color"]': {
      '-webkit-appearance': 'none',
      width: '18px',
      height: '18px',
      border: `1px solid ${getColor('secondary-gray')}`,
      boxSizing: 'border-box',
      padding: 0,
      position: 'absolute',
      top: '8px',
      right: '10px',
      cursor: 'pointer',
    },
    'input[type="color"]::-webkit-color-swatch': {
      border: 'none',
    },
    'input[type="color"]::-webkit-color-swatch-wrapper': {
      padding: 0,
    },
    'input[type="text"]': {
      textTransform: 'uppercase',
    },
  },
})

export default {
  labelWrapper,
  colorPicker,
}
