import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const movedLabel = {
  color: getColor('primary-blue'),
  lineHeight: '20px',
  fontSize: '12px',
  top: '5px',
  background: 'none',
  padding: '0px 6px',
  left: '5px',
}

const selectWrapper = (hasValue: boolean, hasDownArrow: boolean, hasPlaceholder?: boolean): string =>
  style({
    position: 'relative',
    paddingTop: hasPlaceholder ? '20px' : '0',
    $nest: {
      label: {
        $nest: {
          '&:before': {
            content: 'attr(data-title)',
            position: 'absolute',
            ...(!hasValue
              ? {
                  top: '20px',
                  left: '10px',
                  lineHeight: '40px',
                  fontSize: '14px',
                  color: getColor('secondary-gray'),
                  transition: '300ms all',
                }
              : {
                  ...movedLabel,
                }),
          },
        },
      },
      input: {
        ...(hasDownArrow
          ? {
              backgroundImage:
                "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTkuODE4ODUgMS4zMzQ5Nkw1LjI0MzEgNi4xMTQ3NUwwLjY2NzM2MSAxLjMzNDk2IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utb3BhY2l0eT0iMC44Ii8+Cjwvc3ZnPg==') !important",
            }
          : {}),
        $nest: {
          '&:focus + label::before': {
            ...movedLabel,
          },
        },
      },
    },
  })

const selectOverlay = style({
  position: 'absolute',
  zIndex: 3000,
  width: '100%',
  maxWidth: '285px',
  maxHeight: '200px',
  background: getColor('white'),
  boxShadow: '0 4px 8px rgb(30 46 57 / 25%)',
  overflow: 'auto',
  $nest: {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: getColor('white'),
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: getColor('secondary-light-gray'),
      borderRadius: '3px',
    },
  },
})

const selectOption = style({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingLeft: '10px',
  minHeight: '30px',
  marginBottom: '6px',
  $nest: {
    '&:hover': {
      backgroundColor: getColor('white'),
    },
    '&:first-child': {
      marginTop: '6px',
    },
  },
})

const previewOptionGroup = style({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'baseline',
  width: '100%',
  $nest: {
    '> div': {
      width: '45%',
    },
  },
})

const selectOptionText = style({
  lineHeight: '20px',
  fontSize: '14px',
  color: getColor('secondary-gray'),
  fontWeight: 400,
  flexGrow: 2,
  marginLeft: '10px',
})

const selectedOption = style({
  color: getColor('primary-blue'),
})

const itemRightContent = style({
  margin: sizeMapper('no small'),
})

const previewSelected = style({
  height: '135px',
  margin: sizeMapper('no no xSmall no'),
  overflow: 'scroll',
})

export default {
  selectWrapper,
  selectOverlay,
  selectOption,
  selectOptionText,
  selectedOption,
  itemRightContent,
  previewSelected,
  previewOptionGroup,
}
