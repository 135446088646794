import { style } from 'typestyle'
import { getColor } from '@/utils'

const straightLine = (active: boolean): string =>
  style({
    width: '51px',
    height: '3px',
    backgroundColor: getColor(active ? 'primary-blue' : 'secondary-gray'),
  })

const showingDot = (active: boolean): string =>
  style({
    borderRadius: '50%',
    height: '12px',
    width: '12px',
    backgroundColor: getColor(active ? 'primary-blue' : 'secondary-gray'),
  })

const bubble = ({ left, top }: { left: string; top: string }): string =>
  style({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    left: left,
    top: top,
    zIndex: 5,
    lineHeight: '15px',
  })

export default { straightLine, showingDot, bubble }
