
import { defineComponent } from 'vue'
import { noDataMessage } from '@/components/ui/styles'

export default defineComponent({
  props: {
    message: {
      type: String,
      default: 'No records found',
    },
  },

  setup() {
    return { css: { noDataMessage } }
  },
})
