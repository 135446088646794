import {
  SIZE_AUTO,
  SIZE_NO,
  SIZE_TINY,
  SIZE_XX_SMALL,
  SIZE_X_SMALL,
  SIZE_SMALL,
  SIZE_S_MEDIUM,
  SIZE_MEDIUM,
  SIZE_L_MEDIUM,
  SIZE_LARGE,
  SIZE_X_LARGE,
  SIZE_XX_LARGE,
  SIZE_GRANDE,
  SIZE_X_GRANDE,
  SIZE_XX_GRANDE,
  SIZE_SPECIAL_1,
} from './constants'

import mapper from './mapper'

const SIZE_MAP = {
  [SIZE_AUTO]: 'auto',
  [SIZE_NO]: '0',
  [SIZE_TINY]: '4px',
  [SIZE_XX_SMALL]: '8px',
  [SIZE_X_SMALL]: '12px',
  [SIZE_SMALL]: '16px',
  [SIZE_S_MEDIUM]: '24px',
  [SIZE_MEDIUM]: '32px',
  [SIZE_L_MEDIUM]: '40px',
  [SIZE_LARGE]: '48px',
  [SIZE_X_LARGE]: '64px',
  [SIZE_XX_LARGE]: '92px',
  [SIZE_GRANDE]: '124px',
  [SIZE_X_GRANDE]: '152px',
  [SIZE_XX_GRANDE]: '180px',
  [SIZE_SPECIAL_1]: '7.5px',
}

export type Size = keyof typeof SIZE_MAP | 'auto' | ''

export type Sizes = `${Size} ${Size} ${Size} ${Size}` | `${Size} ${Size} ${Size}` | `${Size} ${Size}` | Size

const sizeMapper = (size: Sizes, defaultSize: Size = SIZE_MEDIUM): string => mapper(size, SIZE_MAP, defaultSize)

export default sizeMapper
