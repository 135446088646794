import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

interface RadioButtonProps {
  disabled?: boolean
}

export default ({ disabled = false }: RadioButtonProps): string =>
  style({
    textAlign: 'center',
    width: 'auto',
    margin: sizeMapper('tiny'),
    cursor: !disabled ? 'pointer' : 'auto',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    padding: '8px 20px',
    background: getColor('white'),
    border: `1px solid ${getColor('secondary-gray')}`,
    color: getColor('primary-blue'),
    strokeWidth: '2px',
    stroke: getColor('secondary-gray'),
    height: '33px',
    lineHeight: '16px',
    $nest: {
      '&.selected': {
        height: '32px',
        boxShadow: 'none',
        background: getColor('primary-blue'),
        border: 'none',
        color: getColor('white'),
      },
      '&:disabled': {
        border: `1px solid ${getColor('secondary-gray')}`,
        background: getColor('white'),
        color: getColor('secondary-gray'),
      },
    },
  })
