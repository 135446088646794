import { getColor } from '@/utils'
import { style } from 'typestyle'

const paginationContainer = ({
  withoutFirstControl,
  withoutLastControl,
}: {
  withoutFirstControl: boolean
  withoutLastControl: boolean
}): string =>
  style({
    margin: '20px 0',
    textAlign: 'center',
    userSelect: 'none',

    ...(withoutFirstControl && { paddingLeft: '47px' }),
    ...(withoutLastControl && { paddingRight: '25px' }),
  })

const paginationControl = style({
  display: 'inline-block',
  fontSize: '12px',
  color: getColor('primary-blue'),
  cursor: 'pointer',

  $nest: {
    '&:first-child': {
      marginRight: '10px',
    },

    '&:last-child': {
      marginLeft: '10px',
    },
  },
})

const paginationItem = ({ isActive }: { isActive: boolean }): string =>
  style({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '18px',
    height: '18px',
    margin: '0 5px',
    fontWeight: 400,
    fontSize: '12px',
    color: getColor('secondary-gray'),
    cursor: 'pointer',

    ...(isActive && {
      borderRadius: '2px',
      backgroundColor: getColor('primary-blue'),
      color: getColor('white'),
    }),
  })

export default {
  paginationContainer,
  paginationControl,
  paginationItem,
}
