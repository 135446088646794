import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckTextBubble = _resolveComponent("BckTextBubble")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['helper-bubble', _ctx.helperStyle.bubble({ left: _ctx.left, top: _ctx.top })])
  }, [
    _createVNode(_component_BckTextBubble, {
      color: _ctx.active ? 'primary-blue' : 'secondary-gray',
      "font-size": "16px",
      outlined: _ctx.outlined,
      weight: "normal"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.bubbleText), 1)
      ]),
      _: 1
    }, 8, ["color", "outlined"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.helperStyle.straightLine(_ctx.active))
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.helperStyle.showingDot(_ctx.active))
    }, null, 2)
  ], 2))
}