export interface ProjectWizardModuleState {
  region: string
  languages: string[]
  defaultLanguage: string
  projectName: string
  termsAndConditions: boolean
  loading: boolean
}

export const state: ProjectWizardModuleState = {
  region: '',
  languages: [],
  defaultLanguage: '',
  projectName: '',
  termsAndConditions: false,
  loading: false,
}
