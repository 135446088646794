const mathematicalOperators = (
  operator: string,
  condition1?: any,
  condition2?: any
): any => {
  if (!operator) {
    return condition1
  }
  if (operator === '&&') {
    return condition1 && condition2
  }

  if (operator === '||') {
    return condition1 || condition2
  }
}

export default mathematicalOperators
