
import { defineComponent, PropType } from 'vue'
import { Color, Sizes } from '@/utils'
import { heading } from '@/components/ui/styles'
import { Property } from 'csstype'

export default defineComponent({
  name: 'BckH3',
  props: {
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    padding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    color: {
      type: String as PropType<Color>,
      default: 'primary-navy',
    },
    align: {
      type: String as PropType<Property.TextAlign>,
      default: '',
    },
  },

  setup(props) {
    const className = heading({
      type: 'h3',
      margin: props.margin,
      padding: props.padding,
      color: props.color,
      align: props.align,
    })
    return { className }
  },
})
