import gql from 'graphql-tag'

export const CUSTOMER_PROJECTS = gql`
  query projects($filter: FilterFindManyProjectInput) {
    projects(filter: $filter, limit: 1000) {
      _id
      name
      archived
      settings {
        style
        metaDescription
        configuration
      }
      branding {
        cdnUrlReference
        assets {
          resourceType
          resourcePath
          updatedAt
        }
      }
      metaData {
        _id
        title
        description
        asset {
          resourceType
          resourcePath
        }
        favicon {
          resourceType
          resourcePath
        }
        appStoreLink
        googlePlayLink
      }
    }
  }
`

export const PROJECT_BY_ID = gql`
  query project($projectId: MongoID!) {
    project(_id: $projectId) {
      _id
      name
      languages
      activeLanguages
      productId {
        _id
        defaultLanguage
      }
      createdAt
      pwaConfiguration {
        _id
        pwaTriggers
        pwaIconId

        popupDescription {
          notificationsDeclined {
            translations {
              lang
              value
            }
          }
          notificationsEnabledSuccesfully {
            translations {
              lang
              value
            }
          }
          appAlreadyInstalled {
            translations {
              lang
              value
            }
          }
          webapp {
            translations {
              lang
              value
            }
          }
        }
        asset {
          resourcePath
        }
      }
      domain {
        _id
        projectId
        domainType
        previewUrl
        productionUrl
        productionDomainConfigurationId
        productionDomainConfiguration {
          domainId
          domainName
          awsChangeId
          status
          _id
        }
        previewDomainConfigurationId
        previewDomainConfiguration {
          domainId
          domainName
          awsChangeId
          status
          _id
        }
      }
      branding {
        _id
        brandColor
        cardDesign
        ctaColor
        iconColor
        ctaTextColor
        googleFontPath
        cdnUrlReference
        fontType
        funnelOnly
        assets {
          _id
          resourcePath
          resourceType
          updatedAt
        }
      }
      settings {
        style
        metaDescription
        configuration
      }
      metaData {
        _id
        title
        description
        asset {
          _id
          resourcePath
          resourceType
        }
        favicon {
          _id
          resourceType
          resourcePath
        }
        appStoreLink
        googlePlayLink
      }
      dataPrivacy
      offerTags
      integrations {
        name
        integrationId
      }
      productId {
        _id
        defaultLanguage
      }
      tosLink
      ppLink
    }
  }
`

export const PROJECT_BY_ID_SETTINGS = gql`
  {
    project(_id: $projectId) @client {
      name
      branding {
        funnelOnly
      }
      settings {
        style
        metaDescription
        configuration
      }
    }
  }
`

export const PROJECT_CONFIGURATION = gql`
  query ProjectConfig($productId: MongoID!) {
    projectConfig(productId: $productId) {
      extensions {
        name
        entryPoint
      }
    }
  }
`
