
import { defineComponent, PropType, computed } from 'vue'
import { gridStyle } from './styles'
import { Sizes } from '@/utils'
import { Property } from 'csstype'

export default defineComponent({
  name: 'BckGrid',
  props: {
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    padding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    gap: {
      type: String,
      default: '0',
    },
    justifyContent: {
      type: String as PropType<Property.JustifyContent>,
      default: 'flex-start',
    },
    alignItems: {
      type: String,
      default: 'stretch',
    },
    multiLine: {
      type: Boolean,
      required: false,
      default: false,
    },
    direction: {
      type: String as PropType<Property.FlexDirection>,
      default: 'row',
    },
  },
  setup(props) {
    const className = computed(() =>
      gridStyle({
        margin: props.margin,
        padding: props.padding,
        justifyContent: props.justifyContent,
        direction: props.direction,
        alignItems: props.alignItems,
        multiLine: props.multiLine,
        gap: props.gap,
      })
    )
    return { className }
  },
})
