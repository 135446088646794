import { computed, ComputedRef, Ref } from 'vue'
import { ApolloError } from '@apollo/client/errors'
import { ServerError } from '@apollo/client'

interface UseErrors {
  networkErrorMessage: ComputedRef<string>
}

export const useErrors = (
  mutationError: Ref<ApolloError | null>
): UseErrors => {
  /**
   * Returns the error message received from graphql
   */
  const networkErrorMessage = computed(() => {
    const errors = (mutationError.value?.networkError as ServerError).result
      ?.errors
    return errors && errors.length > 0 ? errors[0].message : ''
  })
  return { networkErrorMessage }
}
