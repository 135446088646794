import { ComputedRef, computed, ref, Ref } from 'vue'
import { Branding, Asset } from '@/types/models'
import * as yup from 'yup'
import { BaseSchema } from 'yup'
import { isImage, testFileSize, isFontFile } from '@/utils/fieldsValidations'
import { AssetType } from '@/types/models/projects'
import { theme } from '@/utils/constants'

interface UseModalComposable {
  branding: Ref<Branding | undefined>
  setBranding: (projectBranding: Branding | undefined) => void
  brandingValidation: BaseSchema
  updateBrandingLocally: (projectId: string, fieldName: string, value: string) => Promise<void>
  getProjectAsset: (assets: Array<Asset> | undefined, assetType: AssetType) => Asset | undefined
  updateAsset: (projectId: string, updatedFile: File | string, fieldName: AssetType) => void
  activeFont: ComputedRef<string | File | undefined>
  activeBoldFont: ComputedRef<string | File | undefined>
}

export const useProjectStyle = (): UseModalComposable => {
  const branding = ref()

  const getProjectAsset = (assets: Array<Asset> | undefined, assetType: AssetType): Asset | undefined => {
    return assets ? assets.find((asset: Asset) => asset.resourceType === assetType) : undefined
  }

  const setBranding = (projectBranding: Branding | undefined): void => {
    if (!projectBranding) {
      return
    }
    const logo = getProjectAsset(projectBranding.assets, 'logo')
    const font = getProjectAsset(projectBranding.assets, 'font')
    const boldFont = getProjectAsset(projectBranding.assets, 'boldFont')
    const projectBackgroundImage = getProjectAsset(projectBranding.assets, 'projectBackgroundImage')

    branding.value = {
      _id: projectBranding._id || '',
      brandColor: projectBranding.brandColor || theme.primaryBlue,
      cardDesign: projectBranding.cardDesign || 'oldCard',
      ctaColor: projectBranding.ctaColor || theme.primaryBlue,
      iconColor: projectBranding.iconColor || theme.white,
      ctaTextColor: projectBranding.ctaTextColor || theme.white,
      googleFontPath: projectBranding.googleFontPath || '',
      cdnUrlReference: projectBranding.cdnUrlReference || '',
      fontType: projectBranding.fontType || 'custom',
      funnelOnly: !!projectBranding.funnelOnly,
      assets: [
        {
          _id: logo?._id || '',
          resourcePath: logo?.resourcePath || '',
          resourceType: 'logo',
          updatedAt: logo?.updatedAt,
        },
        {
          _id: font?._id || '',
          resourcePath: font?.resourcePath || '',
          resourceType: 'font',
          updatedAt: font?.updatedAt,
        },
        {
          _id: boldFont?._id || '',
          resourcePath: boldFont?.resourcePath || '',
          resourceType: 'boldFont',
          updatedAt: boldFont?.updatedAt,
        },
        {
          _id: projectBackgroundImage?._id || '',
          resourcePath: projectBackgroundImage?.resourcePath || '',
          resourceType: 'projectBackgroundImage',
          updatedAt: projectBackgroundImage?.updatedAt,
        },
      ],
    }
  }
  const colorCodeValidation = yup
    .string()
    .required('This field is required.')
    .test('colorCode', 'Color code is not valid', (value) => {
      if (!value) {
        return true
      }
      return !!value?.match(/^#([0-9a-f]{6})$/i)
    })

  const brandingValidation = yup.object().shape({
    logo: yup
      .mixed()
      .required('This field is required.')
      .test('fileSize', 'File is too large', (value) => !(value?.[0] && !testFileSize(value?.[0])))
      .test('fileType', 'Unsupported file type', (value) => !(value?.[0] && !isImage(value?.[0]))),
    brandColor: colorCodeValidation,
    iconColor: colorCodeValidation,
    ctaColor: colorCodeValidation,
    ctaTextColor: colorCodeValidation,
    font: yup
      .mixed()
      .test('fileSize', 'File is too large', (value) => !(value?.[0] && !testFileSize(value?.[0])))
      .test(
        'fileType',
        'Uploaded font is not a ttf, woff or otf file',
        (value) => !(value?.[0] && !isFontFile(value?.[0]))
      ),
    boldFont: yup
      .mixed()
      .test('fileSize', 'File is too large', (value) => !(value?.[0] && !testFileSize(value?.[0])))
      .test(
        'fileType',
        'Uploaded font is not a ttf, woff or otf file',
        (value) => !(value?.[0] && !isFontFile(value?.[0]))
      ),
  })

  const updateAsset = async (projectId: string, uploadedFile: File | string, fieldName: AssetType) => {
    const index = branding.value?.assets.findIndex((asset: Asset) => asset.resourceType === fieldName)
    if (index > -1 && branding.value) {
      branding.value.assets[index].resourcePath = uploadedFile || ''
    }
  }

  const updateBrandingLocally = async (projectId: string, fieldName: string, value: string): Promise<void> => {
    branding.value[fieldName] = value
  }

  const getActiveFont = (type: 'font' | 'boldFont') => {
    switch (branding.value?.fontType) {
      case 'custom': {
        const font = branding.value?.assets.find(({ resourceType }: Asset) => resourceType === type)
        return font.resourcePath
      }
      case 'google':
        return branding.value?.googleFontPath
      default:
        return undefined
    }
  }

  const activeFont = computed(() => getActiveFont('font'))
  const activeBoldFont = computed(() => getActiveFont('boldFont'))

  return {
    getProjectAsset,
    branding,
    updateBrandingLocally,
    setBranding,
    brandingValidation,
    updateAsset,
    activeFont,
    activeBoldFont,
  }
}
