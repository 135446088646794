
import { defineComponent, PropType } from 'vue'
import { tooltipStyle as css } from './styles'
import { TextSize } from '@/utils'

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 10,
    },
    right: {
      type: Number,
      default: 20,
    },
    fontSize: {
      type: String as PropType<TextSize>,
      default: 'small',
    },
  },

  setup() {
    return { css }
  },
})
