import { Module } from 'vuex'
import { RootState } from '@/store'

import { actions } from '@/store/modules/auth/actions'
import { mutations } from '@/store/modules/auth/mutations'
import { getters } from '@/store/modules/auth/getters'
import { state } from '@/store/modules/auth/state'
import type { AuthModuleState } from '@/store/modules/auth/state'

export { AuthModuleState }

export const authModule: Module<AuthModuleState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
