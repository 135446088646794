
import { computed, defineComponent, PropType, ref, watch } from 'vue'

import { useModal } from '@/composables/useModal'
import { getLanguageFromKey } from '@/utils/languagesHelper'

type Env = {
  name: string
  url: string
}
export default defineComponent({
  props: {
    envs: {
      type: Array as PropType<Env[]>,
      required: true,
    },
    languages: {
      type: Array as PropType<string[]>,
      required: true,
    },
    defaultLanguage: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props) => {
    const activeEnv = ref<Env>()
    const defaultLanguageOption = computed(() => props.defaultLanguage || '')
    const activeLang = ref(defaultLanguageOption.value || undefined)
    const {
      closeModal: onCloseModal,
      isModalVisible,
      showModal: onShowModal,
    } = useModal()

    const languageOptions = computed(
      () =>
        props.languages.map((lang) => ({
          value: lang,
          label: getLanguageFromKey(lang),
        })) || []
    )

    const onEnvSelect = (env: Env) => {
      activeEnv.value = env
      onShowModal()
    }
    const onLanguageChange = (lang: string) => (activeLang.value = lang)

    const onRedirectToEnv = () => {
      // const url = activeEnv.value?.url
      // if (url !== undefined && url.search('beeline.swashly.eu') > 0){
      //   activeEnv.value = {
      //     name:'cellfie',
      //     url:url.replace('beeline.swashly.eu','cellfie.ge/fforward')}
      // }
      window.open(`${activeEnv.value?.url}?lang=${activeLang.value}`, '_blank')
      onCloseModal()
    }

    return {
      activeEnv,
      activeLang,
      isModalVisible,
      languageOptions,
      onCloseModal,
      onEnvSelect,
      onLanguageChange,
      onRedirectToEnv,
      defaultLanguageOption,
    }
  },
})
