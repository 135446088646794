import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckGridColumn = _resolveComponent("BckGridColumn")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createBlock(_component_BckGrid, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsData, (tab, index) => {
        return (_openBlock(), _createBlock(_component_BckGridColumn, {
          key: index,
          size: `${12 / _ctx.tabsData.length}/12`,
          padding: "no",
          align: "center",
          onClick: ($event: any) => (_ctx.$emit('tab-selected', tab.key))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.tabsStyle(_ctx.selectedTab === tab.key))
            }, _toDisplayString(tab.name), 3)
          ]),
          _: 2
        }, 1032, ["size", "onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}