import { classes, style } from 'typestyle'
import { getColor, sizeMapper, Sizes } from '@/utils'

interface InputType {
  hasValue?: boolean
  hasError?: boolean
  withMiniInputStyle?: boolean
  withTextAreaStyle?: boolean
  withNoBorderStyle?: boolean
  withSelectStyle?: boolean
  searchableStyle?: boolean
  iconColor?: string
  fullWidth?: boolean
  paddingRight?: string
  paddingLeft?: string
}

const inputStyle = {
  width: '100%',
  padding: '10px',
  color: getColor(),
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  borderBottom: `1px solid #C4C4C4`,
  borderRadius: 0,
  lineHeight: '16px',
  fontWeight: 400,
  fontSize: '14px',

  $nest: {
    '&:focus': {
      borderBottomColor: getColor('primary-navy'),
      $nest: {
        '+ span.helperText': {
          color: getColor('primary-blue'),
        },
      },
    },
    '&:hover': {
      borderColor: getColor('primary-blue'),
    },
    '&:disabled': {
      borderBottomColor: getColor('secondary-gray'),
      backgroundColor: '#F2F3F5',
      color: 'rgba(0, 0, 0, 0.30)',
    },
  },
}

const noBorderStyle = {
  borderBottom: 'none',
}

const textAreaStyle = {
  resize: 'none',
  display: 'block',
  border: `1px solid ${getColor('secondary-gray')}`,
  marginTop: '8px',
} as const

const miniInputStyle = {
  textAlign: 'center',
} as const

const selectStyle = (iconColor: string | undefined) =>
  style({
    appearance: 'none',
    position: 'relative',
    paddingRight: '36px',
    background: `url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.75L7 6.75L13 0.75' stroke='${escape(
      iconColor || getColor('primary-blue-60')
    )}' stroke-width='2'/%3E%3C/svg%3E%0A") right 5px center no-repeat`,
    $nest: {
      '&:focus': {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 7.75L7 1.75L1 7.75' stroke='${escape(
          iconColor || getColor('primary-blue-60')
        )}' stroke-width='2'/%3E%3C/svg%3E%0A")`,
        $nest: {
          '&::placeholder': {
            color: getColor('secondary-gray'),
          },
        },
      },
      '&:disabled': {
        backgroundImage:
          "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNzVMNyA2Ljc1TDEzIDAuNzUiIHN0cm9rZT0iI0M2QzZDNiIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=')",
      },
    },
  })

const searchSelectStyle = {
  backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuODA1IDBDMy4wNiAwIDAgMy4wNiAwIDYuODA1YzAgMy43NDYgMy4wNiA2LjgwNiA2LjgwNSA2LjgwNmE2Ljc2MiA2Ljc2MiAwIDAgMCAzLjk2NS0xLjI5bDUuMzU4IDUuMzU4YTEuMDk1IDEuMDk1IDAgMSAwIDEuNTUtMS41NWwtNS4zNTctNS4zNTlhNi43NjIgNi43NjIgMCAwIDAgMS4yOS0zLjk2NUMxMy42MSAzLjA2IDEwLjU1IDAgNi44MDQgMFptMCAyLjE5NWE0LjU5NCA0LjU5NCAwIDAgMSA0LjYxIDQuNjEgNC41OTQgNC41OTQgMCAwIDEtNC42MSA0LjYxIDQuNTk0IDQuNTk0IDAgMCAxLTQuNjEtNC42MSA0LjU5MyA0LjU5MyAwIDAgMSA0LjYxLTQuNjFaIiBmaWxsPSIjQzZDNkM2Ii8+PC9zdmc+") !important`,
  backgroundPosition: `right 2px center`,
  backgroundRepeat: `no-repeat`,
  paddingRight: '36px',
} as const

export default ({
  hasValue,
  hasError,
  withMiniInputStyle,
  withTextAreaStyle,
  withSelectStyle,
  searchableStyle,
  withNoBorderStyle,
  iconColor,
  fullWidth,
  paddingRight,
  paddingLeft,
}: InputType): string =>
  classes(
    withSelectStyle ? selectStyle(iconColor) : '',
    style(inputStyle, {
      position: 'relative',
      ...(paddingLeft && { paddingLeft: paddingLeft }),
      maxWidth: fullWidth ? '100%' : '285px',
      ...(paddingRight && { paddingRight: paddingRight }),
      ...(hasValue && { borderBottomColor: getColor('primary-navy') }),
      ...(hasError && { color: getColor('primary-red') }),
      ...(withTextAreaStyle && textAreaStyle),
      ...(withMiniInputStyle && miniInputStyle),
      ...(searchableStyle && searchSelectStyle),
      ...(withNoBorderStyle && noBorderStyle),
    })
  )

export const helperText = (textArea?: boolean): string =>
  style({
    position: 'absolute',
    right: textArea ? '10px' : '3px',
    lineHeight: '16px',
    fontWeight: 400,
    fontSize: '14px',
    color: getColor('secondary-gray'),
    ...(textArea ? { bottom: '30px' } : { top: '30px' }),
  })

const movedLabel = (top = '5px', disabled = false, alignLeft?: boolean) => {
  return {
    color: 'rgba(0, 0, 0, 0.80)',
    lineHeight: '20px',
    fontSize: '12px',
    top: top,
    background: 'none',
    padding: alignLeft ? '0px' : disabled ? '0px 10px' : '0px 6px',
    left: disabled || alignLeft ? '0' : '5px',
  }
}
export const movedLabelClass = (top?: string): string =>
  style({
    ...movedLabel(top),
    position: 'absolute',
    transition: '300ms all',
  })

export const inputWrapper = (
  disabled: boolean,
  hasValue: boolean,
  fullWidth: boolean,
  alignLeft: boolean,
  top?: string,
  margin?: Sizes
): string =>
  style({
    maxWidth: fullWidth ? '100%' : '285px',
    position: 'relative',
    margin: sizeMapper(margin ? margin : 'no'),
    paddingTop: '20px',

    $nest: {
      label: {
        $nest: {
          '&:before': {
            backgroundColor: disabled ? '#F2F3F5' : 'unset',
            width: '265px',
            content: 'attr(data-title)',
            position: 'absolute',
            ...(!hasValue
              ? {
                  top: '17px',
                  left: alignLeft ? '0px' : '10px',
                  lineHeight: '40px',
                  fontSize: '14px',
                  color: getColor('secondary-gray'),
                  transition: '300ms all',
                }
              : {
                  ...movedLabel(top, disabled, alignLeft),
                }),
          },
        },
      },
      input: {
        $nest: {
          '&:focus + label::before': {
            ...movedLabel(top, disabled, alignLeft),
          },
        },
      },
      textarea: {
        $nest: {
          '&:focus + label::before': {
            ...movedLabel(top, disabled, alignLeft),
          },
        },
      },
    },
  })
