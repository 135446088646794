import { keyframes } from 'typestyle'

export const fadeAndSlideInUp = keyframes({
  from: {
    opacity: 0,
    transform: 'translate3d(0, 15px, 0)',
  },
  to: {
    opacity: 1,
    transform: 'none',
  },
})

export const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})

export const slideDown = keyframes({
  from: {
    maxHeight: 0,
  },
  to: {
    maxHeight: '500px',
  },
})

export const slideUp = keyframes({
  from: {
    maxHeight: '500px',
  },
  to: {
    maxHeight: 0,
  },
})

export const rotate = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
})

export const scrollTo = (selector: string) => {
  const element = document.querySelector(selector)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }
}
