import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckFunnelContentCard = _resolveComponent("BckFunnelContentCard")!

  return (_openBlock(), _createBlock(_component_BckFunnelContentCard, {
    key: _ctx.option.value,
    margin: "xxSmall",
    padding: "no xSmall",
    type: "small",
    active: _ctx.checked,
    name: _ctx.name,
    clickable: "",
    value: _ctx.option.value,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick(_ctx.option.value)))
  }, {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_BckIcon, _normalizeProps(_mergeProps({ key: 0 }, _ctx.icon)), null, 16))
        : _createCommentVNode("", true),
      _createVNode(_component_BckText, {
        margin: "no no no small",
        color: _ctx.checked ? 'white' : 'default',
        clickable: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.option.label), 1)
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _: 1
  }, 8, ["active", "name", "value"]))
}