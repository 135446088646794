import { MutationTree } from 'vuex'
import { ProjectWizardModuleState } from './state'

interface Mutations<S = ProjectWizardModuleState> {
  SET_REGION(state: S, region: string): void
  SET_LANGUAGES(state: S, languages: string[]): void
  SET_DEFAULT_LANGUAGE(state: S, language: string): void
  SET_PROJECT_NAME(state: S, projectName: string): void
  SET_TERMS_AND_CONDITIONS(state: S, value: boolean): void
  SET_LOADING(state: S, payload: boolean): void
  RESET_PROJECT_WIZARD(state: S): void
}

export const mutations: MutationTree<ProjectWizardModuleState> & Mutations = {
  SET_REGION(state, region) {
    state.region = region
  },
  SET_LANGUAGES(state, languages) {
    state.languages = languages
  },
  SET_DEFAULT_LANGUAGE(state, language) {
    state.defaultLanguage = language
  },
  SET_PROJECT_NAME(state, projectName) {
    state.projectName = projectName
  },
  SET_TERMS_AND_CONDITIONS(state, value) {
    state.termsAndConditions = value
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  RESET_PROJECT_WIZARD(state) {
    state.projectName = ''
    state.termsAndConditions = false
    state.region = ''
    state.languages = []
  },
}
