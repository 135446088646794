import { style } from 'typestyle'

const selectedCardsContainer = style({
  display: 'flex',
  overflowX: 'scroll',
  paddingBottom: '20px',
  width: '100%',
})

const selectedCards = style({
  display: 'flex',
})

const infoMessage = style({
  fontSize: '10px',
  marginBottom: '10px',
})

export default { infoMessage, selectedCardsContainer, selectedCards }
