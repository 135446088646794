/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty, omit, pick, values, random } from 'lodash'
import {
  Offer,
  CardButton,
  TranslatedCardButton,
  Campaign,
  Funnel,
  OfferListItem,
  ProductPage,
  TranslationItem,
} from '@/types/models'

import {
  getFirstAvailableLanguage,
  getFirstAvailableLanguageFromArray,
  transformTranslationsForApi,
  transformTranslationsFromApi,
} from '@/utils/languagesHelper'
import {
  filterUploads,
  transformButtonsForApi,
  transformReadMoreFromApi,
} from '@/graphqlBackOffice/common/transformers'
import dayjs from 'dayjs'
import { ApiCard } from '@/types/graphql/product'
import {
  processNavigationArray,
  transformNavigationCardFromApi,
  transformPageForApi,
} from '@/graphqlBackOffice/productBuilder/transformers'
import omitDeep from 'omit-deep-lodash'
import { getTranslation } from '@/utils/translation'

/**
 * Process offer before sending it to the API.
 */
export const transformOfferForApi = (offer: Offer): any => {
  // Send only the new uploads.
  const uploadedImages = filterUploads(offer.image)
  const uploadedVerticalImages = filterUploads(offer.verticalImage)

  return {
    ...pick(offer, ['projectId', 'tags', 'whatsappShare', 'languages']),
    offerId: offer.catalogName,
    templateType: offer.template,
    offerPrice: offer.price,
    offerCurrency: offer.currency,
    name: transformTranslationsForApi(offer.name?.translations),
    description: transformTranslationsForApi(offer.description?.translations),
    offerImages: transformTranslationsForApi(uploadedImages),
    offerVerticalImages: transformTranslationsForApi(uploadedVerticalImages),
    buttons: transformButtonsForApi(offer, 'offer'),
    readMorePage: offer.readMoreTemplate ? transformPageForApi(offer.readMoreTemplate, 'ReadMorePage') : null,
  }
}

export const transformOfferFromApi = (offer: any): Offer => {
  const ctaButton = offer.buttons?.find((button: CardButton) => button.appearance === 'default')

  return {
    _id: offer._id,
    id: offer.offerId,
    name: transformTranslationsFromApi(offer.name),
    catalogName: offer.offerId,
    description: transformTranslationsFromApi(offer.description),
    whatsappShare: offer.whatsappShare,
    template: offer.templateType,
    image: transformTranslationsFromApi(offer.offerImageUrls),
    verticalImage: transformTranslationsFromApi(offer.offerVerticalImageUrls),
    languages: offer.languages,
    price: offer.offerPrice,
    currency: offer.offerCurrency,
    buttonName: transformTranslationsFromApi(ctaButton?.name),
    payload: ctaButton?.payload,
    payloadType: ctaButton?.payloadType,
    tags: offer.tags,
    readMoreButtonName: transformTranslationsFromApi(
      offer.buttons?.find((button: any) => button.appearance === 'outline')?.name
    ),
    buttons: offer.buttons,
    readMoreTemplate: transformReadMoreFromApi(offer.readMoreId),
  }
}

export const transformCampaignFromApi = (campaign: Campaign): Campaign => {
  return {
    ...pick(campaign, ['_id', 'name', 'platform', 'type', 'template', 'languages', 'projectId']),
    createdAt: campaign.createdAt ? dayjs(campaign.createdAt).format('DD MMMM YYYY') : '',
    funnelIds: campaign.funnelIds,
    funnelsText: campaign.funnelIds?.length
      ? `${campaign.funnelIds.length} funnel${campaign.funnelIds.length > 1 ? 's' : ''}`
      : 'no funnels',
    status:
      !isEmpty(campaign?.funnels) && campaign?.funnels?.every((funnel) => funnel?.status === 'PUBLISHED')
        ? 'active'
        : 'inactive',
    verticalScrolling: campaign.verticalScrolling,
    showTermsOfService: campaign.showTermsOfService,
    projectId: campaign.projectId,
  }
}

/**
 * Returns (translated) fields of offer to be used in offer form.
 */
export const translateOffer = (
  offer: Offer | undefined,
  language: string
): {
  [key: string]: string | string[] | boolean | number | [File] | TranslatedCardButton[]
} => {
  return {
    id: offer?.id || '',
    name: offer?.name?.translations?.[language] || '',
    catalogName: offer?.id || '',
    image: offer?.image?.translations?.[language] || '',
    verticalImage: offer?.verticalImage?.translations?.[language] || '',
    description: offer?.description?.translations?.[language] || '',
    whatsappShare: !!offer?.whatsappShare || false,
    price: offer?.price || '',
    currency: offer?.currency || '',
    buttonName: offer?.buttonName?.translations?.[language] || '',
    payload: offer?.payload || '',
    payloadType: offer?.payloadType || '',
    readMoreButtonName: offer?.readMoreButtonName?.translations?.[language] || '',
    tags: offer?.tags || [],
    buttons: translateOfferButtons(offer, language),
  }
}

export const translateOfferButtons = (offer: Offer | undefined, language: string): TranslatedCardButton[] => {
  return (
    offer?.buttons?.map((button) => {
      let buttonName = (transformTranslationsFromApi(button?.name)?.translations?.[language] as string) || ''
      if (button.appearance === 'default') {
        buttonName += ' ' + (offer?.price || '') + (offer?.currency || '')
      }
      return {
        name: buttonName,
        appearance: button.appearance,
      }
    }) || []
  )
}

/**
 * Returns translated fields of read more template for the offer being added
 * in the format used in form
 */
export const translateReadMoreTemplate = (readMoreTemplate: ProductPage | undefined, language: string): any => {
  return {
    title: readMoreTemplate?.title?.translations?.[language] || '',
    subtitle: values(readMoreTemplate?.subtitle?.translations)?.map((field) => field?.[language] || ''),
    description: values(readMoreTemplate?.description?.translations as TranslationItem[])?.map(
      (field) => field?.[language] || ''
    ),
  }
}

export const transformFunnelFromApi = (funnel: any): Funnel => {
  const offerCards =
    funnel.offers?.map((offer: any) => {
      return {
        ...getTranslatedOfferPreview(transformOfferFromApi(offer)),
        type: 'offer',
      }
    }) || []

  const leadFormCards =
    funnel.leadForms?.map((leadForm: any) => {
      return {
        ...getTranslatedLeadFormPreview(leadForm),
        type: 'leadForm',
      }
    }) || []

  const trafficCards =
    funnel.trafficCards?.map((trafficCard: any) => {
      return {
        ...getTranslatedTrafficCardPreview(trafficCard),
        type: 'trafficCard',
      }
    }) || []

  const cards = funnel.cards?.map((card: ApiCard) => transformNavigationCardFromApi(card)) || []

  const orderedCards = [...offerCards, ...leadFormCards, ...trafficCards, ...cards]
  orderedCards.sort((a, b) => {
    return funnel.cardsOrder?.indexOf(a._id) - funnel.cardsOrder?.indexOf(b._id)
  })

  return {
    ...pick(funnel, ['_id', 'name', 'status', 'productionLink', 'stageLink']),
    startDate: funnel?.startDate && new Date(funnel.startDate),
    endDate: funnel?.endDate && new Date(funnel.endDate),
    title: transformTranslationsFromApi(funnel.title),
    description: transformTranslationsFromApi(funnel.description),
    cards: orderedCards,
  }
}

export const transformFunnelForApi = (funnel: Funnel, campaignId: string, campaignName: string, projectId: string) => {
  return {
    ...pick(funnel, ['_id', 'name', 'startDate', 'endDate']),
    campaignId,
    title: transformTranslationsForApi(funnel.title?.translations),
    description: transformTranslationsForApi(funnel.description?.translations),
    navigationArray: processNavigationArray(funnel.cards || [], projectId, false),
  }
}

/**
 * Translates an offer for seamless preview.
 */
export const getTranslatedOfferPreview = (offer: Offer, language?: string): OfferListItem => {
  language = language || getFirstAvailableLanguage(offer.name)
  return {
    _id: offer._id || '',
    id: offer.id || '',
    tags: offer.tags || [],
    name: (offer.name?.translations?.[language] as string) || '',
    catalogName: offer.id || '',
    description: (offer.description?.translations?.[language] as string) || '',
    image: (offer.image?.translations?.[language] as string) || '',
    verticalImage: (offer.verticalImage?.translations?.[language] as string) || '',
    buttons: translateOfferButtons(offer, language),
    languages: offer.languages || [],
  }
}

export const getTranslatedLeadFormPreview = (leadForm: any, language?: string): any => {
  language = language || getFirstAvailableLanguageFromArray(leadForm.title)
  const buttons = [
    {
      appearance: 'default',
      name: getTranslation(leadForm.formButtonLabel, language),
    },
  ]
  if (!isEmpty(leadForm.readMoreButtonLabel.translations))
    buttons.push({
      appearance: 'outline',
      name: getTranslation(leadForm.readMoreButtonLabel, language),
    })
  return {
    _id: leadForm._id,
    leadFormId: leadForm.leadFormId,
    tags: leadForm.tags,
    name: getTranslation(leadForm.title, language),
    description: getTranslation(leadForm.description, language),
    image: getTranslation(leadForm.image, language),
    buttons: buttons,
    languages: leadForm.languages,
  }
}

export const getTranslatedTrafficCardPreview = (trafficCard: any, language?: string): any => {
  language = language || getFirstAvailableLanguageFromArray(trafficCard.title)
  const buttons = []
  if (!isEmpty(trafficCard.ctaButtonLabel.translations)) {
    buttons.push({
      appearance: 'default',
      name: getTranslation(trafficCard.ctaButtonLabel, language),
    })
  }
  if (!isEmpty(trafficCard.readMoreButtonLabel.translations))
    buttons.push({
      appearance: 'outline',
      name: getTranslation(trafficCard.readMoreButtonLabel, language),
    })
  if (trafficCard.whatsappShare)
    buttons.push({
      appearance: 'whatsapp',
      name: { translations: [{ lang: 'en', value: 'Share in Whatsapp' }] },
    })
  return {
    _id: trafficCard._id,
    catalogName: trafficCard.leadFormId,
    tags: trafficCard.tags,
    name: getTranslation(trafficCard.title, language),
    description: getTranslation(trafficCard.description, language),
    image: getTranslation(trafficCard.image, language),
    buttons: buttons,
    languages: trafficCard.languages,
  }
}

export const duplicateFunnel = (funnel: any, campaignId: string): any => {
  funnel = omitDeep(funnel, '__typename')

  const cards =
    funnel.cards?.map((card: any) => {
      return { ...pick(card, '_id'), type: 'CARD' }
    }) || []
  const offers =
    funnel.offers?.map((offer: any) => {
      return { ...pick(offer, '_id'), type: 'OFFER' }
    }) || []
  const leadForms =
    funnel.leadForms?.map((leadForm: any) => {
      return { ...pick(leadForm, '_id'), type: 'LEAD_FORM' }
    }) || []
  const trafficCards =
    funnel.trafficCards?.map((trafficCard: any) => {
      return { ...pick(trafficCard, '_id'), type: 'TRAFFIC_CARD' }
    }) || []

  const orderedCards = [...offers, ...leadForms, ...cards, ...trafficCards]
  orderedCards.sort((a, b) => {
    return funnel.cardsOrder?.indexOf(a._id) - funnel.cardsOrder?.indexOf(b._id)
  })

  return {
    ...pick(funnel, ['startDate', 'endDate']),
    name: funnel.name + ' (1)',
    campaignId,
    title: funnel.title,
    description: funnel.description,
    navigationArray: orderedCards,
  }
}

export const duplicateCampaign = (campaign: Campaign, projectId: string): Campaign => {
  return {
    ...(omit(campaign, ['_id', 'funnelsText', 'funnelIds', 'status', 'createdAt']) as Campaign),
    name: campaign.name + ' (1)',
    projectId,
  }
}
