export const testFileSize = (file: File, maxSize?: number): boolean => {
  maxSize = maxSize || 2 * 1024 * 1024
  return file.size < maxSize
}

export const isImage = (
  file: File,
  supportedTypes?: Array<string>
): boolean => {
  supportedTypes = supportedTypes || [
    'image/svg+xml',
    'image/jpeg',
    'image/png',
  ]

  return supportedTypes.includes(file.type)
}

export const isFontFile = (file: File): boolean => {
  return ['ttf', 'otf', 'eot', 'woff', 'woff2'].includes(
    file.name.split('.').pop() || ''
  )
}
