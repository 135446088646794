
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useRoute } from 'vue-router'
import { multiselectStyle as css, movedLabelClass } from './styles'
import { Sizes } from '@/utils'

interface OptionType {
  value: string
  label: string
  unavailable?: boolean
}

export default defineComponent({
  name: 'BckMultiselect',
  components: { Multiselect },

  props: {
    name: {
      type: String,
      default: 'multiselect',
    },
    value: {
      type: String,
      default: '',
    },
    values: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    options: {
      type: Array as PropType<OptionType[]>,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'single',
    },
    placeholder: {
      type: String,
      default: '',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'sMedium no no no',
    },
    optionPadding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    resetSelectInputByRouteChange: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: 'label',
    },
    labelOffset: {
      type: Array as PropType<string[]>,
      default: () => ['-25px', '-35px'],
    },
    max: {
      type: Number,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    appendNewOption: {
      type: Boolean,
      default: true,
    },
    createOption: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const route = useRoute()
    const initialRouteValue = ref(route.query[props.resetSelectInputByRouteChange || ''])
    const multiselect = ref()
    const modelValue = ref(props.mode === 'single' ? props.value : props.values)

    const resetSelectInput = () => {
      if (route.query[props.resetSelectInputByRouteChange || ''] !== initialRouteValue.value) {
        delete route.query[props.name]
        const emptyValue = props.mode === 'single' ? '' : []
        modelValue.value = emptyValue
        emit('change', emptyValue)
        initialRouteValue.value = route.query[props.resetSelectInputByRouteChange || '']
      }
    }

    if (props.resetSelectInputByRouteChange) {
      watch(route, resetSelectInput)
    }

    const isOpen = computed(() => multiselect.value?.isOpen)
    const hasSelected = computed(() => multiselect.value?.hasSelected)

    const labelPosition = computed(() => {
      // needed to trigger transition
      if (!(isOpen.value || hasSelected.value)) {
        return '0'
      }
      return props.mode === 'single' ? '-38px' : '-26px'
    })

    watch(
      () => props.values,
      (newValues) => (modelValue.value = props.mode !== 'single' ? newValues : modelValue.value)
    )
    watch(
      () => props.value,
      (newValue) => (modelValue.value = props.mode === 'single' ? newValue : modelValue.value)
    )

    return {
      multiselect,
      css: { ...css, movedLabelClass },
      initialRouteValue,
      isOpen,
      hasSelected,
      labelPosition,
      open,
      close,
      modelValue,
    }
  },
})
