import { Property } from 'csstype'
import { style, classes } from 'typestyle'
import { getColor, sizeMapper, Sizes } from '@/utils'

interface CardProps {
  selected?: boolean
  disabled?: boolean
  defaultSelection?: boolean
  margin: Sizes
}

interface CardTitleProps {
  margin?: Sizes
}

interface CardImageProps {
  image: string
}
interface CardSubtitleProps {
  subtitleAlign: Property.TextAlign
}

const card = ({
  selected,
  disabled,
  margin,
  defaultSelection = false,
}: CardProps): string =>
  classes(
    style({
      margin: sizeMapper(margin),
      background: getColor('white'),
      color: getColor(),
      textAlign: 'center',
      width: '238px',
      height: 'auto',
      boxSizing: 'border-box',
      boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25);',
      borderRadius: '20px',
      $nest: {
        svg: {
          width: '64px',
          height: '64px',
          margin: sizeMapper('small no'),
        },
      },
    }),
    disabled ? disabledCard : '',
    selected || defaultSelection ? selectedCard(defaultSelection) : ''
  )

const cardContent = (hasImage: boolean): string =>
  style({
    flexDirection: 'column',
    display: 'flex',
    padding: !hasImage ? '0' : '20px',
    justifyContent: 'space-between',
    height: !hasImage ? '100%' : 'calc(100% - 137px)',
  })

const greyedOutStyles = {
  '-webkit-filter': 'grayscale(100%)',
  filter: 'grayscale(100%)',
  opacity: '0.5',
}

const disabledCard = style({
  boxShadow: `0 0 0 1px ${getColor('secondary-gray')} !important`,
  pointerEvents: 'none',
  $nest: {
    div: {
      color: getColor('secondary-gray'),
      $nest: {
        svg: greyedOutStyles,
      },
    },
    'div.img': greyedOutStyles,
  },
})

const selectedCard = (defaultSelection: boolean) =>
  style({
    background: defaultSelection
      ? getColor('primary-navy') + '!important'
      : getColor('primary-blue') + '!important',
    boxShadow: 'none',
    $nest: {
      div: {
        color: getColor('white'),
      },
    },
  })

const cardImage = ({ image }: CardImageProps): string =>
  style({
    width: '238px',
    height: '137px',
    background: `url(${image}) center`,
    backgroundSize: 'cover',
    borderRadius: '10px 10px 0 0',
  })

const cardSubtitle = ({ subtitleAlign }: CardSubtitleProps): string =>
  style({
    margin: sizeMapper('small no medium no'),
    fontSize: '14px',
    lineHeight: '18px',
    whiteSpace: 'pre-wrap',
    textAlign: subtitleAlign || 'center',
  })

const cardTitle = ({ margin = 'no' }: CardTitleProps): string =>
  style({
    whiteSpace: 'pre-wrap',
    textTransform: 'uppercase',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.1em',
    textAlign: 'left',
    margin: sizeMapper(margin),
  })

export default {
  card,
  cardContent,
  cardImage,
  cardSubtitle,
  cardTitle,
}
