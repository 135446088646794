import gql from 'graphql-tag'

export const CREATE_PROJECT = gql`
  mutation CreateProjectMutation(
    $name: String!
    $region: String!
    $languages: [String!]
    $defaultLanguage: String
  ) {
    createProject(
      name: $name
      region: $region
      languages: $languages
      defaultLanguage: $defaultLanguage
    ) {
      _id
      name
      region
      languages
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation UpdateProjectMutation(
    $record: UpdateOneProjectInput!
    $filter: FilterUpdateOneProjectInput
  ) {
    updateProject(record: $record, filter: $filter) {
      error {
        message
      }
    }
  }
`

export const UPDATE_PROJECT_BRANDING = gql`
  mutation UpdateBrandingMutation($branding: BrandingInputInput) {
    updateBranding(branding: $branding) {
      assets {
        resourcePath
        resourceType
        _id
      }
      brandColor
      cardDesign
      iconColor
      ctaColor
      ctaTextColor
      googleFontPath
      cdnUrlReference
      fontType
      funnelOnly
      _id
    }
  }
`

export const UPDATE_PROJECT_CONFIG_DOMAIN_TYPE = gql`
  mutation UpdateDomainTypeMutation(
    $updateDomainTypeId: MongoID!
    $record: UpdateByIdDomain_Input!
  ) {
    updateDomainType(_id: $updateDomainTypeId, record: $record) {
      recordId
    }
  }
`

export const UPDATE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN = gql`
  mutation UpdateFFSocialDomainMutation(
    $domainId: MongoID!
    $domainName: String
  ) {
    updateFFSocialDomain(domainId: $domainId, domainName: $domainName) {
      success
    }
  }
`

export const UPDATE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN_STATUS = gql`
  mutation UpdateFFSocialDomainStatus(
    $productionId: MongoID!
    $previewId: MongoID!
    $domainId: MongoID!
  ) {
    updateFFSocialDomainStatus(
      productionId: $productionId
      previewId: $previewId
      domainId: $domainId
    ) {
      status {
        aws {
          error
          success
        }
        gtm {
          error
          success
        }
        # firebase {
        #   success
        #   error
        # }
        template {
          success
          error
        }
        publish {
          success
          error
        }
      }
      record {
        domainName
        status
      }
    }
  }
`

export const DELETE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN = gql`
  mutation DeleteFFSocialDomainMutation($id: MongoID!) {
    deleteFFSocialDomain(_id: $id) {
      recordId
      error {
        message
      }
    }
  }
`

export const UPDATE_PROJECT_ARCHIVATION = gql`
  mutation UpdateProjectMutation($projectId: MongoID!, $archived: Boolean!) {
    updateProjectArchivation(projectId: $projectId, archived: $archived) {
      archived
    }
  }
`
export const UPDATE_PROJECT_METADATA = gql`
  mutation UpdateMetaDataMutation(
    $_id: MongoID!
    $title: String!
    $description: String!
    $faviconId: String
    $appStoreLink: String
    $googlePlayLink: String
  ) {
    updateMetaData(
      _id: $_id
      title: $title
      description: $description
      faviconId: $faviconId
      appStoreLink: $appStoreLink
      googlePlayLink: $googlePlayLink
    ) {
      project {
        metaData {
          _id
          title
          description
          appStoreLink
          googlePlayLink
        }
      }
    }
  }
`
export const UPDATE_PROJECT_TRANSLATIONS = gql`
  mutation Mutation(
    $projectId: MongoID!
    $defaultLang: String
    $langs: [String]
    $activeLanguages: [String]
  ) {
    updateTranslation(
      projectId: $projectId
      defaultLang: $defaultLang
      langs: $langs
      activeLanguages: $activeLanguages
    ) {
      success
    }
  }
`

export const CREATE_ASSET = gql`
  mutation CreateAsset(
    $resourceType: String!
    $file: Upload!
    $projectName: String!
  ) {
    createAsset(
      resourceType: $resourceType
      file: $file
      projectName: $projectName
    ) {
      _id
      resourceType
      resourcePath
    }
  }
`

export const UPDATE_ASSET = gql`
  mutation UpdateAssetMutation(
    $id: MongoID!
    $resourceType: String!
    $file: Upload
    $projectName: String!
  ) {
    updateAsset(
      _id: $id
      resourceType: $resourceType
      file: $file
      projectName: $projectName
    ) {
      resourcePath
      resourceType
    }
  }
`

export const REMOVE_ASSET = gql`
  mutation RemoveAssetMutation($id: MongoID!, $projectName: String!) {
    removeAsset(_id: $id, projectName: $projectName) {
      resourcePath
      resourceType
    }
  }
`
export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: MongoID!) {
    deleteProject(_id: $id) {
      recordId
      error {
        message
      }
    }
  }
`

export const UPDATE_PROJECT_TERMS_OF_SERVICE_LINKS = gql`
  mutation UpdateProjectTermsOfServiceLinks(
    $projectId: MongoID!
    $tosLink: String
    $ppLink: String
  ) {
    updateProjectTermsOfServiceLinks(
      projectId: $projectId
      tosLink: $tosLink
      ppLink: $ppLink
    )
  }
`

export const UPDATE_PROJECT_INTEGRATIONS = gql`
  mutation UpdateProjectIntegrationsMutation(
    $projectId: MongoID!
    $integrations: [projectIntegrationInput]
  ) {
    updateProjectIntegrations(
      projectId: $projectId
      integrations: $integrations
    ) {
      name
      integrationId
    }
  }
`
