import { style } from 'typestyle'
import { Color, FontWeight, getColor } from '@/utils'
import { getFontWeight } from '@/components/ui/styles/Typography'

export default ({
  size,
  color = 'primary-blue',
  centered,
  fontSize,
  outlined = false,
  weight = 'normal',
  disabled = false,
  padding = '12px',
}: {
  size?: string
  color: Color
  fontSize: string
  centered?: boolean
  outlined?: boolean
  weight?: FontWeight
  disabled?: boolean
  padding: string
}): string =>
  style({
    backgroundColor: disabled ? getColor('secondary-gray') : `${outlined ? 'transparent' : getColor(color)} !important`,
    cursor: disabled ? 'default' : 'pointer',
    width: size || 'auto',
    padding: padding,
    borderRadius: '18px',
    color: getColor(outlined ? color : 'white'),
    border: outlined ? `3px solid ${getColor(color)}` : 'none',
    fontWeight: getFontWeight(weight),
    fontSize: fontSize,
    whiteSpace: 'nowrap',
    ...(centered && { textAlign: 'center' }),
  })
