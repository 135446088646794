import {
  FORGOT_PASSWORD,
  LOG_IN,
  REFRESH_TOKEN,
  REGISTER_USER,
  RESET_PASSWORD,
} from '@/graphqlBackOffice/auth/mutations'
import {
  GET_EMAIL_FROM_INVITATION,
  GET_EMAIL_FROM_RECOVERY_HASH,
} from '@/graphqlBackOffice/auth/queries'
import { useAction, useFetch } from '@/graphqlBackOffice/composables'
class AuthService {
  public logIn(email: string, password?: string) {
    const { mutate, loading, error } = useAction(LOG_IN)

    const login = mutate({ email, password })

    return { login, loading, error }
  }

  public refreshToken(refreshToken: string) {
    const { mutate, loading, error } = useAction(REFRESH_TOKEN)

    const refreshTokenMutation = mutate({ refreshToken })

    return { refreshTokenMutation, loading, error }
  }

  public getEmailFromInvitation(token: string) {
    const { result, loading, error } = useFetch(
      GET_EMAIL_FROM_INVITATION,
      {
        token: token,
      },
      {
        fetchPolicy: 'network-only',
      }
    )
    return { result, loading, error }
  }

  public getEmailFromRecoveryHash(recoveryHash: string, userId: string) {
    const { result, loading, error } = useFetch(
      GET_EMAIL_FROM_RECOVERY_HASH,
      { recoveryHash, userId },
      { fetchPolicy: 'network-only' }
    )

    return { result, loading, error }
  }

  public useMutations() {
    return {
      registerUser: useAction(REGISTER_USER),
      forgotPassword: useAction(FORGOT_PASSWORD),
      resetPassword: useAction(RESET_PASSWORD),
    }
  }
}

export default new AuthService()
