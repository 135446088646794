/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ref } from 'vue'
import { useQuery, provideApolloClient } from '@vue/apollo-composable'
import { DocumentNode } from 'graphql'
import { ApolloError } from '@apollo/client/errors'
import {
  OptionsParameter,
  VariablesParameter,
} from '@vue/apollo-composable/dist/useQuery'
import apolloClient from '@/helpers/apolloClient'

export interface UseFetch {
  result: Readonly<Ref<Readonly<any>>>
  refetch: any
  loading: Ref<boolean>
  error?: Ref<ApolloError | null>
}

export const useFetch = (
  graphQLDoc: DocumentNode,
  params?: VariablesParameter<Record<string, any>>,
  options?: OptionsParameter<any, any>
): UseFetch => {
  provideApolloClient(apolloClient)
  const { result, loading, error, refetch } = useQuery(
    graphQLDoc,
    params || {},
    options || {}
  )

  return { result, refetch, loading, error }
}
