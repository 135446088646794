import { style } from 'typestyle'
import { getColor } from '@/utils'

export { default as offersListStyle } from './OffersList'
export { default as campaignFunnelsStyle } from './CampaignFunnels'
export { default as funnelScheduleStyle } from './FunnelSchedule'
export { default as createOfferStyle } from './CreateEditOffer'
export { default as campaignManagerStyle } from '../../campaigns/styles/CampaignManager'
export { default as helperStyle } from './HelperBubble'
export { default as readMoreStyle } from './ReadMoreTemplateModal'
export { default as offersStyle } from './Offers'

export const leftContent = style({
  borderRight: `1px solid ${getColor('secondary-gray')}`,
})

export const rightContent = style({
  overflow: 'hidden',
})
