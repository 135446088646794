
import { defineComponent, watch, ref, reactive, ComputedRef } from 'vue'
import { useRouter } from 'vue-router'
import Header from '@/components/layout/BckHeader.vue'
import { pageStyle as css } from './styles'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { omit, reduce } from 'lodash'
import { Project } from '@/types/models'

export default defineComponent({
  name: 'BckPage',
  components: { Header },

  setup() {
    const { currentRoute, push } = useRouter()
    const disableOverflow = ref(currentRoute.value.meta.disableOverflow)
    const loading = ref(true)
    const allowAccess = ref()
    const project = reactive({
      name: '',
      id: '',
    })

    const setProjectData = (projectById: ComputedRef<Project | null>) => {
      if (projectById.value) {
        project.name = projectById.value.name
        project.id = projectById.value._id
      }
    }

    const onPageLoad = () => {
      document.getElementById('content')?.scrollTo(56, 0)
      disableOverflow.value = currentRoute.value.meta.disableOverflow

      if (!currentRoute.value?.params.id) {
        loading.value = false
      } else {
        const { project: projectById, loading: loadingProject } =
          ProjectsService.getProjectById(
            currentRoute.value?.params.id as string,
            'cache-first'
          )
        loading.value = loadingProject.value
        setProjectData(projectById)

        watch(
          () => loadingProject.value,
          (loadingP) => {
            if (!loadingP) {
              setProjectData(projectById)
              const routeMeta = currentRoute.value.meta
              if (routeMeta?.fullyConfigured || routeMeta?.configuration) {
                const omitFields = routeMeta?.fullyConfigured
                  ? ['__typename']
                  : ['__typename', 'configuration']
                allowAccess.value = reduce(
                  omit(projectById.value?.settings, omitFields),
                  (res, value) => res && !!value,
                  true
                )

                if (!allowAccess.value) {
                  return push({ name: 'Projects' })
                }
              }
            }
            loading.value = false
          }
        )
      }
    }
    watch(currentRoute, onPageLoad)

    onPageLoad()

    return { css, disableOverflow, project, loading }
  },
})
