
import { defineComponent, ref } from 'vue'
import { toggleSwitchStyle } from './styles'
import { useField } from 'vee-validate'
import { useModal } from '@/composables/useModal'

export default defineComponent({
  props: {
    name: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: true,
    },
    showConfirmationModal: {
      type: Boolean,
      default: false,
    },
    confirmationMessage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],

  setup(props, { emit }) {
    const checkboxInput = ref()
    const { isModalVisible, closeModal, showModal } = useModal()
    const { handleChange } = useField(props.name, undefined, {
      initialValue: props.checked,
      type: 'checkbox',
      checkedValue: true,
      uncheckedValue: false,
    })

    const handleInputChange = ($event: Event) => {
      handleChange($event)
      emit('change')
    }

    const clicked = () => {
      if (props.showConfirmationModal) {
        showModal()
      } else {
        checkboxInput.value?.click()
      }
    }

    const confirmAction = () => {
      checkboxInput.value?.click()
      closeModal()
    }

    return {
      clicked,
      closeModal,
      checkboxInput,
      confirmAction,
      isModalVisible,
      toggleSwitchStyle,
      handleInputChange,
    }
  },
})
