import { Module } from 'vuex'
import { RootState } from '@/store'

import { actions } from '@/store/modules/campaign/actions'
import { mutations } from '@/store/modules/campaign/mutations'
import { getters } from '@/store/modules/campaign/getters'
import { state } from '@/store/modules/campaign/state'
import type { CampaignsState } from '@/store/modules/campaign/state'

export { CampaignsState }

export const campaignsModule: Module<CampaignsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
