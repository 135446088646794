import { Permissions } from '@/services/auth/permissions'

export const DASHBOARD = 'dashboard'
export const SETTINGS = 'settings'
export const CONTENT = 'content'
export const FUNNELS = 'funnels'
export const ANALYTICS = 'analytics'
export const VAULT = 'vault'
export const CAMPAIGNS = 'campaigns'

export const PAGE_PROJECT = 'Project'

export const PAGE_STYLE = 'Brand & Style'
export const SUB_ITEM_STYLE = 'style'

export const PAGE_PREVIEW_LAYOUT = 'Preview Layout Styles'
export const SUB_ITEM_PREVIEW_LAYOUT = 'previewLayoutStyles'

export const PAGE_META_DESCRIPTION = 'Meta Information'
export const SUB_ITEM_META_DESCRIPTION = 'metaDescription'

export const PAGE_PWA_CONFIGURATION = 'PWA Configuration'
export const SUB_ITEM_PWA_CONFIGURATION = 'pwaConfiguration'

export const PAGE_LANGUAGE = 'Language'
export const SUB_ITEM_LANGUAGE = 'language'

export const PAGE_CONFIGURATION_DOMAIN_TYPE = 'Configuration Domain Type'

export const PAGE_CONFIGURATION_DOMAIN_NAME = 'Configuration Domain Name'
export const PAGE_CONFIGURATION_REVERSE_PROXY = 'Configuration Reverse Proxy'
export const PAGE_CONFIGURATION_PROXY_TYPE = 'Configuration Proxy Type'
export const PAGE_CONFIGURATION_SERVER = 'Configuration Server'

export const PAGE_CONFIGURATION = 'Configuration'
export const SUB_ITEM_CONFIGURATION = 'configuration'

export const PAGE_AUTHORIZATION = 'Team & Users'
export const SUB_ITEM_AUTHORIZATION = 'team_and_users'

export const PAGE_GET_HELP = 'Advanced'
export const SUB_ITEM_GET_HELP = 'advanced'

export const PAGE_FUNNEL_MANAGER = 'Funnel Manager'
export const SUB_ITEM_FUNNEL_MANAGER = 'funnel-manager'

export const PAGE_RULES_MANAGEMENT = 'Facebook Automation'
export const SUB_ITEM_RULES_MANAGEMENT = 'rules-management'

export const PAGE_RULE_LOG = 'Rule Log'
export const SUB_ITEM_RULE_LOG = 'rule-log'

export const SUB_ITEM_API_MANAGER = 'backoffice-api-manager'
export const PAGE_API_MANAGER = 'Api Manager'

export const SUB_ITEM_CREATE_RULE = 'create-rule'

export const PAGE_CREATE_RULE = 'Create Rule'
export const PAGE_EDIT_RULE = 'Edit Rule'

export const SUB_ITEM_EDIT_RULE = 'edit-rule'

export const PAGE_CHOOSE_FUNNEL_GROUP_TEMPLATE = 'Choose Funnel Group Template'

export const PAGE_CREATE_FUNNEL_GROUP = 'Create Funnel Group'
export const PAGE_EDIT_FUNNEL_GROUP = 'Edit Funnel Group'
export const PAGE_CREATE_FUNNEL = 'Create Funnel'
export const PAGE_EDIT_FUNNEL = 'Edit Funnel'
export const PAGE_PREVIEW_FUNNEL = 'Preview Funnel'
export const PAGE_CAMPAIGN_FUNNELS = 'Funnels'
export const SUB_ITEM_CONTENT = 'content'
export const SUB_ITEM_LEAD_FORMS = 'lead-forms'
export const SUB_ITEM_TRAFFIC_CARDS = 'traffic-cards'
export const SUB_ITEM_PRODUCT_FLOW_BUILDER = 'product-flow-builder'

export const PAGE_OFFERS = 'Offers'
export const PAGE_LEAD_FORMS = 'Lead Forms'
export const PAGE_TRAFFIC_CARDS = 'Traffic Cards'
export const PAGE_TERMS_OF_SERVICE = 'ToS & PP'
export const SUB_ITEM_TERMS_OF_SERVICE = 'terms-of-service'

export const PAGE_CREATE_OFFER = 'Create Offer'
export const PAGE_EDIT_OFFER = 'Edit Offer'
export const PAGE_CREATE_LEAD_FORM = 'Create Lead Form'
export const PAGE_EDIT_LEAD_FORM = 'Edit Lead Form'
export const PAGE_CREATE_TRAFFIC_CARD = 'Create Traffic Card'
export const PAGE_EDIT_TRAFFIC_CARD = 'Edit Traffic Card'
export const PAGE_FLOW_BUILDER = 'Flow Builder'
export const PAGE_CREATE_PAGE = 'Create Page'
export const PAGE_EDIT_PAGE = 'Edit Page'

export const PAGE_ANALYTICS_DASHBOARD = 'Analytics Dashboard'

export const INTEGRATIONS = 'Integrations'
export const PAGE_INTEGRATIONS_FACEBOOK_PIXEL = 'Facebook Pixel'
export const PAGE_INTEGRATIONS_HOTJAR = 'Hotjar'
export const PAGE_INTEGRATIONS_MICROSOFT_CLARITY = 'Microsoft Clarity'

export const PAGE_VAULT = 'Vault'

export const PAGE_CAMPAIGNS = 'Campaigns'
export const PAGE_CREATE_CAMPAIGN = 'Create Campaign'
export const PAGE_EDIT_CAMPAIGN = 'Edit Campaign'

export type NAVIGATION_MENU_SUBITEMS_TYPE = keyof typeof NAVIGATION_MENU_SUBITEMS_MAP

export const NAVIGATION_MENU_SUBITEMS_MAP = {
  [PAGE_PROJECT]: {
    parent: 'none',
    subItem: 'none',
  },
  [PAGE_STYLE]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_STYLE,
  },
  [PAGE_PREVIEW_LAYOUT]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_PREVIEW_LAYOUT,
  },
  [PAGE_META_DESCRIPTION]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_META_DESCRIPTION,
  },
  [PAGE_PWA_CONFIGURATION]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_PWA_CONFIGURATION,
  },
  [PAGE_CONFIGURATION_DOMAIN_TYPE]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_CONFIGURATION,
  },
  [PAGE_CONFIGURATION_DOMAIN_NAME]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_CONFIGURATION,
  },
  [PAGE_CONFIGURATION_REVERSE_PROXY]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_CONFIGURATION,
  },
  [PAGE_CONFIGURATION_PROXY_TYPE]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_CONFIGURATION,
  },
  [PAGE_CONFIGURATION_SERVER]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_CONFIGURATION,
  },
  [PAGE_CONFIGURATION]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_CONFIGURATION,
  },
  [PAGE_AUTHORIZATION]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_AUTHORIZATION,
  },
  [PAGE_LANGUAGE]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_LANGUAGE,
  },
  // [PAGE_API_MANAGER]: {
  //   parent: SETTINGS,
  //   subItem: SUB_ITEM_API_MANAGER,
  // },
  [PAGE_GET_HELP]: {
    parent: SETTINGS,
    subItem: SUB_ITEM_GET_HELP,
  },
  [PAGE_FUNNEL_MANAGER]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  // [PAGE_RULES_MANAGEMENT]: {
  //   parent: FUNNELS,
  //   subItem: SUB_ITEM_RULES_MANAGEMENT,
  // },
  [PAGE_RULE_LOG]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_RULE_LOG,
  },
  [PAGE_CREATE_RULE]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_CREATE_RULE,
  },
  [PAGE_EDIT_RULE]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_EDIT_RULE,
  },

  [PAGE_CHOOSE_FUNNEL_GROUP_TEMPLATE]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  [PAGE_CREATE_FUNNEL_GROUP]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  [PAGE_EDIT_FUNNEL_GROUP]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  [PAGE_CREATE_FUNNEL]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  [PAGE_EDIT_FUNNEL]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  [PAGE_PREVIEW_FUNNEL]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  [PAGE_CAMPAIGN_FUNNELS]: {
    parent: FUNNELS,
    subItem: SUB_ITEM_FUNNEL_MANAGER,
  },
  [PAGE_OFFERS]: {
    parent: CONTENT,
    subItem: SUB_ITEM_CONTENT,
  },
  [PAGE_LEAD_FORMS]: {
    parent: CONTENT,
    subItem: SUB_ITEM_LEAD_FORMS,
  },
  [PAGE_TRAFFIC_CARDS]: {
    parent: CONTENT,
    subItem: SUB_ITEM_TRAFFIC_CARDS,
  },
  [PAGE_CREATE_TRAFFIC_CARD]: {
    parent: CONTENT,
    subItem: SUB_ITEM_TRAFFIC_CARDS,
  },
  [PAGE_EDIT_TRAFFIC_CARD]: {
    parent: CONTENT,
    subItem: SUB_ITEM_TRAFFIC_CARDS,
  },
  [PAGE_TERMS_OF_SERVICE]: {
    parent: CONTENT,
    subItem: SUB_ITEM_TERMS_OF_SERVICE,
  },
  [PAGE_CREATE_OFFER]: {
    parent: CONTENT,
    subItem: SUB_ITEM_CONTENT,
  },
  [PAGE_EDIT_OFFER]: {
    parent: CONTENT,
    subItem: SUB_ITEM_CONTENT,
  },
  [PAGE_CREATE_LEAD_FORM]: {
    parent: CONTENT,
    subItem: SUB_ITEM_LEAD_FORMS,
  },
  [PAGE_EDIT_LEAD_FORM]: {
    parent: CONTENT,
    subItem: SUB_ITEM_LEAD_FORMS,
  },
  [PAGE_FLOW_BUILDER]: {
    parent: CONTENT,
    subItem: SUB_ITEM_PRODUCT_FLOW_BUILDER,
  },
  [PAGE_CREATE_PAGE]: {
    parent: CONTENT,
    subItem: SUB_ITEM_PRODUCT_FLOW_BUILDER,
  },
  [PAGE_EDIT_PAGE]: {
    parent: CONTENT,
    subItem: SUB_ITEM_PRODUCT_FLOW_BUILDER,
  },
  [PAGE_ANALYTICS_DASHBOARD]: {
    parent: 'none',
    subItem: 'none',
  },
  [INTEGRATIONS]: {
    parent: 'none',
    subItem: 'none',
  },
  [PAGE_INTEGRATIONS_FACEBOOK_PIXEL]: {
    parent: INTEGRATIONS,
    subItem: PAGE_INTEGRATIONS_FACEBOOK_PIXEL,
  },
  [PAGE_INTEGRATIONS_HOTJAR]: {
    parent: INTEGRATIONS,
    subItem: PAGE_INTEGRATIONS_HOTJAR,
  },
  [PAGE_INTEGRATIONS_MICROSOFT_CLARITY]: {
    parent: INTEGRATIONS,
    subItem: PAGE_INTEGRATIONS_MICROSOFT_CLARITY,
  },
  [PAGE_VAULT]: {
    parent: 'none',
    subItem: 'none',
  },
  [PAGE_CAMPAIGNS]: {
    parent: 'none',
    subItem: 'none',
  },
  [PAGE_CREATE_CAMPAIGN]: {
    parent: PAGE_CAMPAIGNS,
    subItem: 'none',
  },
  [PAGE_EDIT_CAMPAIGN]: {
    parent: PAGE_CAMPAIGNS,
    subItem: 'none',
  },
}

export const NAVIGATION_MENU = {
  [DASHBOARD]: {
    title: 'Home',
    key: DASHBOARD,
    page: PAGE_PROJECT,
    children: [],
  },
  [SETTINGS]: {
    title: 'Settings',
    key: SETTINGS,
    page: PAGE_PROJECT,
    children: [
      {
        title: PAGE_STYLE,
        key: SUB_ITEM_STYLE,
        useWarning: true,
      },
      {
        title: PAGE_PREVIEW_LAYOUT,
        key: SUB_ITEM_PREVIEW_LAYOUT,
      },
      {
        title: PAGE_META_DESCRIPTION,
        key: SUB_ITEM_META_DESCRIPTION,
        useWarning: true,
      },

      {
        title: PAGE_CONFIGURATION,
        key: SUB_ITEM_CONFIGURATION,
        useWarning: true,
        permission: Permissions.ACCESS_PROJECT_CONFIG,
      },
      {
        title: PAGE_PWA_CONFIGURATION,
        key: SUB_ITEM_PWA_CONFIGURATION,
      },
      {
        title: PAGE_LANGUAGE,
        key: SUB_ITEM_LANGUAGE,
      },
      {
        title: PAGE_AUTHORIZATION,
        key: SUB_ITEM_AUTHORIZATION,
        permission: Permissions.ACCESS_PROJECT_AUTHORISATION,
      },

      // {
      //   title: PAGE_API_MANAGER,
      //   key: SUB_ITEM_API_MANAGER,
      //   authorize: ['SUPER_ADMIN'],
      // },
      {
        title: PAGE_GET_HELP,
        key: SUB_ITEM_GET_HELP,
      },
    ],
  },

  [INTEGRATIONS]: {
    title: INTEGRATIONS,
    key: INTEGRATIONS,
    page: INTEGRATIONS,
    children: [
      {
        title: PAGE_INTEGRATIONS_FACEBOOK_PIXEL,
        key: PAGE_INTEGRATIONS_FACEBOOK_PIXEL,
      },
      {
        title: PAGE_INTEGRATIONS_HOTJAR,
        key: PAGE_INTEGRATIONS_HOTJAR,
      },
      {
        title: PAGE_INTEGRATIONS_MICROSOFT_CLARITY,
        key: PAGE_INTEGRATIONS_MICROSOFT_CLARITY,
      },
    ],
  },
  [CONTENT]: {
    title: 'Catalog',
    key: CONTENT,
    page: PAGE_PROJECT,
    children: [
      {
        title: PAGE_OFFERS,
        key: SUB_ITEM_CONTENT,
        permission: Permissions.ACCESS_OFFERS,
      },
      {
        title: PAGE_LEAD_FORMS,
        key: SUB_ITEM_LEAD_FORMS,
        permission: Permissions.ACCESS_LEAD_FORMS,
      },
      {
        title: PAGE_TRAFFIC_CARDS,
        key: SUB_ITEM_TRAFFIC_CARDS,
        permission: Permissions.ACCESS_TRAFFIC_CARDS,
      },
      {
        title: PAGE_FLOW_BUILDER,
        key: SUB_ITEM_PRODUCT_FLOW_BUILDER,
        permission: Permissions.ACCESS_FLOW_BUILDER,
      },
      {
        title: PAGE_TERMS_OF_SERVICE,
        key: SUB_ITEM_TERMS_OF_SERVICE,
        permission: Permissions.ACCESS_TOS_PP,
      },
    ],
  },
  [FUNNELS]: {
    title: 'Funnels',
    key: FUNNELS,
    page: PAGE_PROJECT,
    children: [
      {
        title: PAGE_FUNNEL_MANAGER,
        key: SUB_ITEM_FUNNEL_MANAGER,
      },
      // {
      //   title: PAGE_RULES_MANAGEMENT,
      //   key: SUB_ITEM_RULES_MANAGEMENT,
      //   authorize: ['SUPER_ADMIN'],
      // },
    ],
  },
  [ANALYTICS]: {
    title: 'Analytics',
    key: ANALYTICS,
    page: PAGE_ANALYTICS_DASHBOARD,
    children: [],
  },
  [VAULT]: {
    title: 'Vault',
    key: VAULT,
    page: PAGE_VAULT,
    children: [],
  },
  [CAMPAIGNS]: {
    title: 'Campaigns',
    key: CAMPAIGNS,
    page: PAGE_CAMPAIGNS,
    children: [],
  },
}
