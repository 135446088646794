import { getSessionValue } from '@/graphqlBackOffice/auth/session'
import axios from 'axios'

axios.interceptors.request.use(
  (config) => {
    if (config.url?.startsWith('/api/')) {
      config.url = `${process.env.VUE_APP_NEW_API_ENDPOINT}${config.url}`
      config.headers['Authorization'] = `Bearer ${getSessionValue('authToken')}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
