
import { defineComponent, PropType } from 'vue'
import { addProjectCard, addProjectCardText } from '@/components/ui/styles'
import { Sizes } from '@/utils'

export default defineComponent({
  name: 'BckAddProjectCard',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
  },

  setup(props) {
    const textClassName = addProjectCardText({
      margin: 'small no no no',
      padding: 'no',
      color: props.disabled ? 'secondary-gray' : 'swashly-transparent-30-black',
    })

    return {
      projectCard: addProjectCard({ margin: props.margin }),
      textClassName,
      iconName: props.disabled ? 'addProjectDisabled' : 'addProject',
    }
  },
})
