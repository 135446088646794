
import { defineComponent, ref, computed, watch, PropType, Ref } from 'vue'
import { useField } from 'vee-validate'
import { fileStyle as css } from './styles'
import { Size } from '@/utils'
import BckText from '@/components/ui/BckText.vue'
import { getImagePreview } from '@/utils/uploadsHelpers'

export default defineComponent({
  components: { BckText },
  props: {
    uploadText: {
      type: String,
      default: 'Upload',
    },
    removeText: {
      type: String,
      default: 'Remove',
    },
    changeText: {
      type: String,
      default: 'Change',
    },
    name: {
      type: String,
      required: true,
    },
    extensions: {
      type: String,
      default: '',
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    isFont: {
      type: Boolean,
      default: false,
    },
    previewIcon: {
      type: String,
      default: 'textFile',
    },
    initialValue: {
      type: Object as PropType<File>,
      default: null,
    },
    storedFile: {
      type: String,
      default: '',
    },
    margin: {
      type: String as PropType<Size>,
      default: 'no',
    },
    dropArea: {
      type: Boolean,
      default: false,
    },
    showHelperText: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Upload',
    },
    placeholder1: {
      type: String,
      default: 'upload your',
    },
    placeholder2: {
      type: String,
      default: 'image',
    },
    placeholder3: {
      type: String,
      default: 'here',
    },
    imageSizeText: {
      type: String,
      default: 'BANNER (1200 x 628 pixels)',
    },
    imageRatioText: {
      type: String,
      default: '1:2',
    },
    imageContainText: {
      type: String,
      default: 'little or no overlaid text',
    },
    imageType: {
      type: String,
      default: 'BANNER',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isStylePage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['file-changed'],

  setup(props, { emit }) {
    // Current upload
    const uploadedFile: Ref<File | undefined> = ref(props.initialValue)
    const dragging = ref(false)
    const isDirty = ref(false)
    // Path of stored file.
    const filePath: Ref<string> = ref(props.storedFile)
    const { value, validate, errorMessage, handleChange, meta } = useField(props.name)

    const fileName = computed(() => {
      return filePath.value ? filePath.value.split('?')[0].split('/').pop() : uploadedFile.value?.name
    })

    const previewImage = computed(() => {
      if (filePath.value) {
        return filePath.value
      }
      const upload = uploadedFile.value || props.initialValue
      return getImagePreview(upload)
    })

    watch(
      () => value.value,
      async (newFileUploaded) => {
        const isUpload = typeof newFileUploaded !== 'string'
        const newUpload = !isUpload ? newFileUploaded : newFileUploaded?.[0]
        if (!newUpload) {
          value.value = null
        }
        await validate()
        if (!newUpload || !errorMessage.value) {
          uploadedFile.value = !isUpload ? null : newUpload
          emit('file-changed', newUpload)
          filePath.value = isUpload ? '' : newFileUploaded
        }

        isDirty.value = true
      }
    )

    const dropFile = (event: DragEvent) => {
      if (props.disabled) {
        return
      }

      dragging.value = false
      let droppedFiles = event?.dataTransfer?.files
      if (!droppedFiles) return
      handleChange(droppedFiles)
      uploadedFile.value = droppedFiles[0]
    }

    const handleFileChange = (event: Event) => {
      const target = event.target as HTMLInputElement
      const files = target.files
      target.value.length !== 0 && handleChange(files)
    }

    return {
      filePath,
      uploadedFile,
      fileName,
      previewImage,
      errorMessage,
      handleChange,
      isDirty,
      meta,
      css,
      dropFile,
      dragging,
      handleFileChange,
    }
  },
})
