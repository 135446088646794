import { reduce } from 'lodash'
import { SIZE_MEDIUM } from './constants'

interface MapType {
  [key: string]: string
}

const mapper = (
  properties: string,
  map: MapType,
  defaultValue = SIZE_MEDIUM
): string =>
  reduce(
    (properties || defaultValue).split(' '),
    (result, property) => `${result} ${map[property]} `,
    ''
  ).trim()

export default mapper
