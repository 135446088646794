
import { defineComponent } from 'vue'
import { headerStyle as css } from './styles'
import UserNavigation from '@/components/common/UserNav.vue'

export default defineComponent({
  name: 'BckHeader',
  components: { UserNavigation },
  props: {
    projectId: {
      type: String,
      default: '',
    },
    projectName: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      css,
    }
  },
})
