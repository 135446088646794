
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { offerCardStyle } from './styles'
import {
  NavigationCard,
  OfferListItem,
  OfferTemplateKey,
  TranslatedCardButton,
  TranslationResource,
} from '@/types/models'
import { Sizes } from '@/utils'
import BckIcon from '@/components/ui/BckIcon.vue'
import imagePlaceholder from '@/assets/offerCardImagePlaceholder.png'
import { OFFER_TEMPLATES } from '@/views/campaigns/constants/campaigns'
import HelperBubble from '@/views/campaigns/components/HelperBubble.vue'
import { CarouselPageCard } from '@/types/components/UiTypes'
import { getFirstTranslationOfField } from '@/utils/languagesHelper'
import { getImagePreview } from '@/utils/uploadsHelpers'
import { get } from 'lodash'
import Disclaimer from '@/components/common/Disclaimer.vue'

export default defineComponent({
  components: { HelperBubble, BckIcon, Disclaimer },

  props: {
    offer: {
      type: Object as PropType<OfferListItem | NavigationCard>,
      default: () => null,
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'small',
    },
    templateKey: {
      type: String as PropType<OfferTemplateKey>,
      default: 'OFFER',
    },
    // If set to true, it will show the name of the card template instead
    // of offer image/placeholder image
    showTemplateName: {
      type: Boolean,
      default: false,
    },
    showMockData: {
      type: Boolean,
      default: false,
    },
    showHelpers: {
      type: Boolean,
      default: false,
    },
    ctaColor: {
      type: String,
      default: '',
    },
    ctaTextColor: {
      type: String,
      default: '',
    },
    projectID: {
      type: String,
      default: '',
    },
    cardDesign: {
      type: String,
      default: 'oldCard',
    },
  },

  emits: ['selected', 'read-more'],

  setup(props) {
    const descriptionItems = computed(() =>
      props.offer?.description
        ? String(props.offer.description)
            .split('\n')
            .filter((i: string) => i)
        : ['Card Subtitle: Max. 110 characters with bullet points.', 'Max. 3 bullet points.', 'Your information here.']
    )

    const buttonClass = (button: TranslatedCardButton) => {
      switch (button.appearance) {
        case 'whatsapp':
          return offerCardStyle.whatsappButton

        case 'default':
          return offerCardStyle.ctaButton(props.ctaColor, props.ctaTextColor)

        case 'outline':
          return ''
      }
    }

    const cardButtons = computed(() => {
      if (props.offer && (get(props.offer, 'name') || props.offer.title)) {
        return props.offer.buttons
      }
      return OFFER_TEMPLATES[props.templateKey].buttons
    })

    const getButtonName = (button: TranslatedCardButton): string => {
      switch (button.appearance) {
        case 'default':
          return button.buttonName || button.name || 'BUY'

        case 'outline':
          return button.buttonName || button.name || 'Read More'

        default:
          return button.buttonName || button.name || 'Button'
      }
    }

    const cardImage = (offer: CarouselPageCard) => {
      if (!offer || !offer.image) {
        return imagePlaceholder
      }
      // Preview for navigation card.
      if (offer?.type === 'card') {
        offer.image = getFirstTranslationOfField(offer.image as TranslationResource)
      }
      return getImagePreview(offer.image as File | string)
    }

    const helperButtonSettings = (button: TranslatedCardButton, isE2eDesign: boolean) => {
      return {
        bubbleText: button.appearance === 'default' ? 'Price' : 'Read More',
        active: button.name !== '',
        top: button.appearance === 'default' ? (isE2eDesign ? '-18px' : '-6px') : isE2eDesign ? '0px' : '-1px',
        left: button.appearance === 'default' ? (isE2eDesign ? '-293px' : '-145px') : isE2eDesign ? '-336px' : '-189px',
      }
    }

    const cardClass = computed(() => {
      switch (props.cardDesign) {
        case 'oldCard':
          return offerCardStyle.card(props.margin, cardImage(props.offer))
        case 'newCard':
          return offerCardStyle.card(props.margin, cardImage(props.offer))
        case 'e2eCard':
          return offerCardStyle.e2eCard(cardImage(props.offer))
        default:
          return offerCardStyle.card(props.margin, cardImage(props.offer))
      }
    })

    const cardContentClass = computed(() => {
      switch (props.cardDesign) {
        case 'oldCard':
          return offerCardStyle.oldCardContent
        case 'newCard':
          return offerCardStyle.newCardContent
        case 'e2eCard':
          return offerCardStyle.e2eCardContent
        default:
          return offerCardStyle.oldCardContent
      }
    })

    const titleClass = computed(() => {
      switch (props.cardDesign) {
        case 'oldCard':
          return offerCardStyle.oldCardTitle
        case 'newCard':
          return offerCardStyle.newCardTitle
        case 'e2eCard':
          return offerCardStyle.e2eCardTitle
        default:
          return offerCardStyle.oldCardTitle
      }
    })

    const buttonsWrapperClass = computed(() => {
      switch (props.cardDesign) {
        case 'oldCard':
          return offerCardStyle.oldCardButtons(cardButtons.value?.length ?? 0)
        case 'newCard':
          return offerCardStyle.newCardButtons(cardButtons.value?.length ?? 0)
        case 'e2eCard':
          return offerCardStyle.e2eCardButtons
        default:
          return offerCardStyle.oldCardButtons(cardButtons.value?.length ?? 0)
      }
    })

    const selectedImageClass = computed(() => {
      switch (props.cardDesign) {
        case 'oldCard':
          return offerCardStyle.oldCardImage(cardImage(props.offer))
        case 'newCard':
          return offerCardStyle.newCardImage
        case 'e2eCard':
          return offerCardStyle.e2eCardImage
        default:
          return offerCardStyle.oldCardImage(cardImage(props.offer))
      }
    })

    const descriptionWrapperClass = computed(() => {
      switch (props.cardDesign) {
        case 'oldCard':
          return offerCardStyle.descriptionWrapper
        case 'newCard':
          return offerCardStyle.descriptionWrapper
        case 'e2eCard':
          return offerCardStyle.descriptionWrapperE2e
        default:
          return offerCardStyle.descriptionWrapper
      }
    })

    const descriptionListClass = computed(() => {
      switch (props.cardDesign) {
        case 'oldCard':
          return offerCardStyle.oldDescriptionList
        case 'newCard':
          return offerCardStyle.newDescriptionList
        case 'e2eCard':
          return offerCardStyle.e2eDescriptionList
        default:
          return offerCardStyle.oldDescriptionList
      }
    })

    return {
      buttonClass,
      cardButtons,
      buttonsWrapperClass,
      cardImage,
      getButtonName,
      helperButtonSettings,
      offerCardStyle,
      descriptionItems,
      imagePlaceholder,
      OFFER_TEMPLATES,
      selectedImageClass,
      cardContentClass,
      cardClass,
      titleClass,
      descriptionWrapperClass,
      descriptionListClass,
    }
  },
})
