import { style } from 'typestyle'
import { getColor } from '@/utils'

const container = style({
  maxHeight: '347px',
  position: 'relative',
})

export const dateInput = style({
  width: '85%',
  maxWidth: '185px',
  padding: '10px',
  marginBottom: '20px',
  color: getColor(),
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  borderBottom: `1px solid ${getColor('secondary-gray')}`,
  borderRadius: 0,
  lineHeight: '16px',
  fontWeight: 400,
  fontSize: '14px',

  $nest: {
    '&:focus': {
      borderBottomColor: getColor('primary-navy'),
      $nest: {
        '&::placeholder': {
          color: 'transparent',
        },
      },
    },
    '&:hover': {
      borderBottomColor: getColor('primary-blue'),
    },
    '&:placeholder': {
      color: getColor('secondary-gray'),
    },
    '&:disabled': {
      borderBottomColor: getColor('secondary-gray'),
    },
  },
})

export const verticalLine = style({
  borderLeft: `1px solid ${getColor('white')}`,
  height: '250px',
  margin: '0 16px',
})

export default {
  dateInput,
  container,
  verticalLine,
}
