import { computed, ComputedRef } from 'vue'
import { useFetch, useAction } from '@/graphqlBackOffice/composables'
import {
  CREATE_PROJECT,
  UPDATE_PROJECT_CONFIG_DOMAIN_TYPE,
  UPDATE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN,
  UPDATE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN_STATUS,
  DELETE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN,
  REMOVE_ASSET,
  UPDATE_PROJECT_TERMS_OF_SERVICE_LINKS,
} from '@/graphqlBackOffice/projects/mutations'
import {
  CUSTOMER_PROJECTS,
  PROJECT_BY_ID,
  UPDATE_PROJECT_BRANDING,
  transformProject,
  transformProjects,
  UPDATE_PROJECT_ARCHIVATION,
  UPDATE_PROJECT_METADATA,
  UPDATE_PROJECT_TRANSLATIONS,
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_PROJECT,
  UPDATE_PROJECT_INTEGRATIONS,
} from '@/graphqlBackOffice/projects'
import { Project, ProjectModel, Branding } from '@/types/models'
import { useErrors } from '@/graphqlBackOffice/composables/useErrors'
import { WatchQueryFetchPolicy } from '@apollo/client'
import omitDeep from 'omit-deep-lodash'
import { PROJECT_CONFIGURATION } from '@/graphqlBackOffice/projects/queries'
import axios from 'axios'

class ProjectsService {
  public async getConfig() {
    return await axios.get(
      `/routes.json`
    )
  }

  public getProjectConfiguration(productId: string) {
    const { result, loading } = useFetch(
      PROJECT_CONFIGURATION,
      { productId },
      { fetchPolicy: 'cache-first' }
    )

    return { loading, result }
  }

  public getProjects(isArchived: boolean) {
    const { result, refetch, loading } = useFetch(
      CUSTOMER_PROJECTS,
      { filter: { archived: isArchived } },
      {
        fetchPolicy: 'cache-and-network',
      }
    )

    const projects: ComputedRef<Project[]> = computed(() => {
      return transformProjects(result.value?.projects || [])
    })

    const refetchProjects = (isArchived: boolean) =>
      refetch({ filter: { archived: isArchived } })

    return { loading, refetchProjects, projects: projects }
  }

  public getDefaultLogo = (): string => {
    return process.env.VUE_APP_ASSETS_URL + 'assets/tier1/web/en/logo.png'
  }

  public getProjectById(
    id: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
    applyNextFetchPolicy: WatchQueryFetchPolicy = 'cache-first'
  ) {
    const { result, loading, refetch } = useFetch(
      PROJECT_BY_ID,
      { projectId: id },
      {
        fetchPolicy: fetchPolicy,
        nextFetchPolicy: applyNextFetchPolicy,
      }
    )

    const refetchProject = () => {
      refetch({ projectId: id })
    }

    const project: ComputedRef<Project | null> = computed(() => {
      if (!loading.value && result.value) {
        const projectResult = result.value?.project as ProjectModel
        return projectResult ? transformProject(projectResult) : null
      }

      return null
    })

    return { loading, project, refetchProject }
  }

  public async updateBranding(branding: Branding | undefined) {
    const { mutate: updateBranding } = useAction(UPDATE_PROJECT_BRANDING)
    branding = omitDeep(branding, 'updatedAt')
    await updateBranding({ branding: branding })
  }

  public createProject(
    name: string,
    region: string,
    languages: string[],
    defaultLanguage: string
  ) {
    const { mutate: createProject, loading, error } = useAction(CREATE_PROJECT)

    const project = createProject({
      name,
      region,
      languages,
      defaultLanguage,
    })
    const { networkErrorMessage } = useErrors(error)

    return { project, loading, networkErrorMessage }
  }

  public useMutations() {
    return {
      updateDomainType: useAction(UPDATE_PROJECT_CONFIG_DOMAIN_TYPE),
      updateFFSocialDomainConfig: useAction(
        UPDATE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN
      ),
      updateFFSocialDomainStatus: useAction(
        UPDATE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN_STATUS
      ),
      deleteFFSocialDomainConfig: useAction(
        DELETE_PROJECT_CONFIG_FF_SOCIAL_DOMAIN
      ),
      updateProjectArchivation: useAction(UPDATE_PROJECT_ARCHIVATION),
      createAsset: useAction(CREATE_ASSET),
      updateAsset: useAction(UPDATE_ASSET),
      removeAsset: useAction(REMOVE_ASSET),
      updateMetaData: useAction(UPDATE_PROJECT_METADATA),
      updateTranslation: useAction(UPDATE_PROJECT_TRANSLATIONS),
      deleteProject: useAction(DELETE_PROJECT),
      updateProjectTermsOfServiceLinks: useAction(UPDATE_PROJECT_TERMS_OF_SERVICE_LINKS),
      updateProjectIntegrations: useAction(UPDATE_PROJECT_INTEGRATIONS)
    }
  }
}

export default new ProjectsService()
