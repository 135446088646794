import { getProjectRole, isAdmin, isSuperAdmin } from '@/graphqlBackOffice/auth/session'
import { Permissions } from './permissions'
import { projectRoles } from '@/utils/constants'
import { ProjectUserRole } from '@/types/models'

// Super Admin        - SUPER_ADMIN
// User               - ADMIN
// Project User       - FF_USER
// -- Project Admin   - CLIENT
// -- Project Editor  - CLIENT_WRITE
// -- Project Viewer  - CLIENT_READ

const everyone = () => true

const superAdmin = () => false

const user = () => isAdmin()

const anyProjectRole = (projectRole: ProjectUserRole | null) => !!projectRole

const projectAdmin = (projectRole: ProjectUserRole | null) => projectRole === projectRoles.CLIENT.value

const projectAdminOrEditor = (projectRole: ProjectUserRole | null) =>
  projectRole === projectRoles.CLIENT.value || projectRole === projectRoles.CLIENT_WRITE.value

export const isAuthorized = (permission: string, projectId?: string): boolean => {
  if (isSuperAdmin()) {
    return true
  }

  const projectRole = projectId ? getProjectRole(projectId)?.role : null

  switch (permission) {
    case Permissions.ACCESS_ADMIN_PANEL:
      return superAdmin()

    case Permissions.INVITE_USERS_TO_PLATFORM:
      return superAdmin()

    case Permissions.CHANGE_PLATFORM_USER_ROLES:
      return superAdmin()

    case Permissions.INVITE_USERS_TO_PROJECT:
      return projectAdmin(projectRole)

    case Permissions.ACCESS_PLATFORM_DASHBOARD:
      return everyone()

    case Permissions.CREATE_NEW_PROJECT:
      return user()

    case Permissions.ACCESS_PROJECT_DASHBOARD:
      return anyProjectRole(projectRole)

    case Permissions.PREVIEW_PUBLISH_PROJECT:
      return projectAdminOrEditor(projectRole)

    case Permissions.OPEN_PROJECT_LINKS:
      return anyProjectRole(projectRole)

    case Permissions.ACCESS_ADVANCED_PROJECT_SETTINGS:
      return anyProjectRole(projectRole)

    case Permissions.ARCHIVE_PROJECT:
      return projectAdmin(projectRole)

    case Permissions.ACCESS_PROJECT_STYLE:
      return anyProjectRole(projectRole)

    case Permissions.ACCESS_PROJECT_PREVIEW_LAYOUT:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_PROJECT_STYLE:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_PROJECT_META_INFORMATION:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_PROJECT_META_INFORMATION:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_PROJECT_CONFIG:
      return projectAdmin(projectRole)

    case Permissions.CONFIGURE_PROJECT_DOMAIN:
      return projectAdmin(projectRole)

    case Permissions.ACCESS_PROJECT_PWA_CONFIG:
      return anyProjectRole(projectRole)

    case Permissions.ACCESS_PROJECT_LANGUAGE:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_PROJECT_PWA_CONFIG:
      return projectAdminOrEditor(projectRole)

    case Permissions.EDIT_PROJECT_LANGUAGE:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_PROJECT_AUTHORISATION:
      return projectAdmin(projectRole)

    case Permissions.CHANGE_PROJECT_USER_ROLES:
      return projectAdmin(projectRole)

    case Permissions.ACCESS_OFFERS:
      return anyProjectRole(projectRole)

    case Permissions.ACCESS_SPECIFIC_OFFER:
      return anyProjectRole(projectRole)

    case Permissions.CREATE_OFFER:
      return projectAdminOrEditor(projectRole)

    case Permissions.DELETE_OFFER:
      return projectAdminOrEditor(projectRole)

    case Permissions.DUPLICATE_OFFER:
      return projectAdminOrEditor(projectRole)

    case Permissions.EDIT_FIELDS_ON_OFFER:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_FLOW_BUILDER:
      return anyProjectRole(projectRole)

    case Permissions.MAKE_CHANGES_ON_CANVAS:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_SPECIFIC_PAGE:
      return anyProjectRole(projectRole)

    case Permissions.ADD_NEW_EXTENSION:
      return false

    case Permissions.ADD_NEW_PAGE:
      return projectAdminOrEditor(projectRole)

    case Permissions.EDIT_FIELDS_ON_PAGE:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_TOS_PP:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_TOS_PP:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_NAVIGATION:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_NAVIGATION:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_CAMPAIGN_MANAGER:
      return anyProjectRole(projectRole)

    case Permissions.CREATE_NEW_FUNNEL_GROUP:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_SPECIFIC_FUNNEL_GROUP:
      return anyProjectRole(projectRole)

    case Permissions.ACCESS_SPECIFIC_FUNNEL:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_CAMPAIGN_TITLE:
      return projectAdminOrEditor(projectRole)

    case Permissions.CREATE_NEW_FUNNEL:
      return projectAdminOrEditor(projectRole)

    case Permissions.DELETE_FUNNEL:
      return projectAdminOrEditor(projectRole)

    case Permissions.DUPLICATE_FUNNEL:
      return projectAdminOrEditor(projectRole)

    case Permissions.EDIT_FIELDS_ON_DRAFT_FUNNEL:
      return projectAdminOrEditor(projectRole)

    case Permissions.PUBLISH_DRAFT_FUNNEL:
      return projectAdminOrEditor(projectRole)

    case Permissions.OPEN_FUNNEL_LINKS:
      return anyProjectRole(projectRole)

    case Permissions.ACCESS_ANALYTICS:
      return anyProjectRole(projectRole)

    case Permissions.DELETE_PROJECT:
      return projectAdmin(projectRole)

    case Permissions.ACCESS_PROJECT_INTEGRATIONS:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_PROJECT_INTEGRATIONS:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_LEAD_FORMS:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_LEAD_FORMS:
      return projectAdminOrEditor(projectRole)

    case Permissions.ACCESS_TRAFFIC_CARDS:
      return anyProjectRole(projectRole)

    case Permissions.EDIT_TRAFFIC_CARDS:
      return projectAdminOrEditor(projectRole)
    case Permissions.ACCESS_VAULT:
      return anyProjectRole(projectRole)

    case Permissions.ACCESS_CAMPAIGNS:
      return projectAdminOrEditor(projectRole)
  }

  return false
}
