import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor_content = _resolveComponent("editor-content")!
  const _component_BckEditorMenuBar = _resolveComponent("BckEditorMenuBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_editor_content, {
      name: "description",
      editor: _ctx.editor,
      class: _normalizeClass(_ctx.css.ProseMirror),
      onClick: _ctx.contentClicked
    }, null, 8, ["editor", "class", "onClick"]),
    _createVNode(_component_BckEditorMenuBar, {
      disabled: _ctx.disabled,
      editor: _ctx.editor,
      "font-size": _ctx.fontSize
    }, null, 8, ["disabled", "editor", "font-size"])
  ], 64))
}