
import { defineComponent, PropType } from 'vue'
import { Color, FontWeight } from '@/utils'
import { textBubbleStyle } from '@/components/ui/styles'

export default defineComponent({
  name: 'BckTextBubble',
  props: {
    size: {
      type: String,
      default: 'auto',
    },
    color: {
      type: String as PropType<Color>,
      default: 'primary-blue',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    weight: {
      type: String as PropType<FontWeight>,
      default: 'normal',
    },
    fontSize: {
      type: String,
      default: 'small',
    },
    padding: {
      type: String,
      default: '12px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { textBubbleStyle }
  },
})
