import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      _ctx.textBubbleStyle({
        size: _ctx.size,
        color: _ctx.color,
        centered: _ctx.centered,
        fontSize: _ctx.fontSize,
        outlined: _ctx.outlined,
        weight: _ctx.weight,
        disabled: _ctx.disabled,
        padding: _ctx.padding,
      })
    )
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}