
import { defineComponent, ref, watch } from 'vue'
import { useRouter, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { includes } from 'lodash'
import { navigationMenuStyle as css } from './styles'
import {
  NAVIGATION_MENU,
  PAGE_PROJECT,
  NAVIGATION_MENU_SUBITEMS_MAP,
  NAVIGATION_MENU_SUBITEMS_TYPE,
  CONTENT,
  FUNNELS,
  ANALYTICS,
  SUB_ITEM_PRODUCT_FLOW_BUILDER,
  INTEGRATIONS,
} from './constants'
import { isAuthorized } from '@/services/auth/authService'
import { useProjectRestrictedRoutes } from '@/composables/useProjectRestrictedRoutes'

export default defineComponent({
  name: 'NavigationMenu',
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { projectSettingsProgress, allowConfigurationAccess, configurationComplete, funnelOnly } =
      useProjectRestrictedRoutes(props.projectId)
    const tooltip = ref({
      isOpen: false,
      top: 0,
    })
    const isNavigationMenuOpen = ref(true)
    const expandedMenuItem = ref('none')
    const subMenuItem = ref('none')

    const checkRoute = (route: RouteLocationNormalized) => {
      const { matched } = route
      if (matched[1]) {
        const routeName = matched[1].name as NAVIGATION_MENU_SUBITEMS_TYPE

        const { parent, subItem } = NAVIGATION_MENU_SUBITEMS_MAP[routeName]
        if (routeName !== PAGE_PROJECT) expandedMenuItem.value = parent
        subMenuItem.value = subItem
      }
    }

    const { push, currentRoute } = useRouter()
    checkRoute(currentRoute.value)

    watch(currentRoute, checkRoute)

    const toggleNavigationMenu = () => {
      isNavigationMenuOpen.value = !isNavigationMenuOpen.value
    }

    const expandMenuItem = (menuItem: string) => {
      if (menuItem === expandedMenuItem.value) {
        expandedMenuItem.value = 'none'
      } else {
        expandedMenuItem.value = menuItem
      }
    }
    const expandAndNavigate = (menuItem: string, route: RouteLocationRaw) => {
      expandMenuItem(menuItem)
      push(route)
    }

    const isDisabled = (key: string) =>
      !configurationComplete.value && includes([CONTENT, FUNNELS, ANALYTICS, INTEGRATIONS], key)

    const isSubItemDisabled = (key: string) => key === 'configuration' && !allowConfigurationAccess.value

    const openTooltip = (event: Event, index: string) => {
      event.stopPropagation()
      if (!isDisabled(index)) return

      let target = event.target as HTMLElement | null
      while (target) {
        if (target.id === 'NAVIGATION_ITEM') {
          tooltip.value.top = target.getBoundingClientRect().top
          tooltip.value.isOpen = true
          return
        }
        target = target.parentElement as HTMLElement | null
      }
    }

    const canAccess = (permission: string) => {
      return permission ? isAuthorized(permission, props.projectId) : true
    }

    const filterItemChildren = (children: any) => {
      return children.filter((x: any) => !(x.key === SUB_ITEM_PRODUCT_FLOW_BUILDER && funnelOnly.value))
    }

    return {
      isNavigationMenuOpen,
      expandedMenuItem,
      push,
      expandMenuItem,
      subMenuItem,
      toggleNavigationMenu,
      NAVIGATION_MENU,
      SUB_ITEM_PRODUCT_FLOW_BUILDER,
      projectSettingsProgress,
      allowConfigurationAccess,
      funnelOnly,
      css,
      canAccess,
      isDisabled,
      isSubItemDisabled,
      tooltip,
      openTooltip,
      closeTooltip: () => (tooltip.value.isOpen = false),
      filterItemChildren,
      expandAndNavigate,
    }
  },
})
