import { style } from 'typestyle'
import { getColor, sizeMapper, Sizes } from '@/utils'

const defaultSizes = {
  fonts: {
    content: '10px',
    templateName: '16px',
    buttons: '10px',
  },
  buttonHeight: '22px',
}

const content = style({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const card = (margin: Sizes, image: string): string =>
  style({
    height: '270px',
    width: '199px',
    display: 'flex',
    flexDirection: 'column',
    margin: sizeMapper(margin),
    position: 'relative',
    flexShrink: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '12px',
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.76) 81.77%),url("${image}") center`,
  })

const e2eCard = (image: string): string =>
  style({
    height: '305px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    cursor: 'pointer',
    flexShrink: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.76) 81.77%),url("${image}") center`,
  })

const oldCardContent = style({
  padding: '3% 8% 0 8%',
  height: '61%',
  backgroundColor: getColor('white'),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  borderRadius: `12px`,
  border: `0.7px solid ${getColor('secondary-gray')}`,
  borderTop: 'none',
  fontSize: defaultSizes.fonts.content,
})

const newCardContent = style({
  padding: '3% 8% 0 8%',
  height: '61%',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  borderRadius: `12px`,
  borderTop: 'none',
  fontSize: defaultSizes.fonts.content,
})

const e2eCardContent = style({
  padding: '0 6%',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  borderRadius: `12px`,
  borderTop: 'none',
  fontSize: defaultSizes.fonts.content,
  paddingBottom: '5%',
  gap: '16px',
})

const oldCardImage = (image: string): string =>
  style({
    height: '39%',
    background: `url("${image}") centerxxx`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '12px 12px 0 0',
    border: `0px solid ${getColor('secondary-gray')}`,
    boxSizing: 'border-box',
    position: 'relative',
  })
const newCardImage = style({
  height: '39%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '12px 12px 0 0',
  boxSizing: 'border-box',
  position: 'relative',
})
const e2eCardImage = style({
  flex: '1',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '12px 12px 0 0',
  boxSizing: 'border-box',
  position: 'relative',
})

const templateName = style({
  height: '39%',
  backgroundColor: getColor('primary-blue'),
  textAlign: 'center',
  borderRadius: '14px 14px 0 0',
  border: `0.7px solid ${getColor('secondary-gray')}`,
  boxSizing: 'border-box',
  fontSize: defaultSizes.fonts.templateName,
  color: getColor('white'),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const oldCardTitle = style({
  position: 'relative',
  lineHeight: '110%',
  fontSize: defaultSizes.fonts.content,
  color: getColor('black'),
  marginRight: '25%',
  marginBottom: '5%',
})

const newCardTitle = style({
  position: 'relative',
  lineHeight: '110%',
  fontSize: '16px',
  color: getColor('white'),
  marginRight: '25%',
  marginBottom: '5%',
})

const e2eCardTitle = style({
  position: 'relative',
  lineHeight: '110%',
  fontSize: '16px',
  color: getColor('white'),
  flex: '1',
})

const descriptionWrapper = style({ position: 'relative', maxHeight: '50%' })

const descriptionWrapperE2e = style({ position: 'relative', padding: '10px 12px 0 10px', width: '100%' })

const oldDescriptionList = style({
  lineHeight: '120%',
  color: getColor('secondary-gray'),
  listStyle: 'none',
  overflow: 'hidden',
  textAlign: 'initial',
  marginBottom: '0',
  $nest: {
    li: {
      position: 'relative',
      paddingLeft: '5%',
    },
    'li::before': {
      position: 'absolute',
      left: '0',
      content: '"•"',
    },
  },
})
const newDescriptionList = style({
  lineHeight: '120%',
  color: getColor('white'),
  listStyle: 'none',
  overflow: 'hidden',
  textAlign: 'initial',
  marginBottom: '5%',
  $nest: {
    li: {
      position: 'relative',
      paddingLeft: '5%',
    },
    'li::before': {
      position: 'absolute',
      left: '0',
      content: '"•"',
    },
  },
})
const e2eDescriptionList = style({
  lineHeight: '120%',
  color: getColor('secondary-gray'),
  listStyle: 'none',
  overflow: 'hidden',
  textAlign: 'initial',
  marginBottom: '0',
  $nest: {
    li: {
      fontSize: '11px',
      position: 'relative',
      paddingLeft: '5%',
      marginBottom: '4px',
    },
    'li::before': {
      position: 'absolute',
      left: '0',
      content: '"•"',
    },
  },
})

const oldCardButtons = (length: number): string =>
  style({
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: '4%',
    $nest: {
      button: {
        padding: length < 3 ? '5%' : '2%',
        cursor: 'pointer',
        width: '98%',
        borderRadius: '20px',
        fontSize: defaultSizes.fonts.buttons,
        lineHeight: '110%',
        fontWeight: 600,
        background: getColor('white'),
        border: `0.7px solid ${getColor('secondary-gray')}`,
        boxSizing: 'border-box',
        marginBottom: '2%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        position: 'relative',
        color: getColor('black'),
      },
    },
  })

const newCardButtons = (length: number): string =>
  style({
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: '15%',
    $nest: {
      button: {
        padding: length < 3 ? '5%' : '2%',
        cursor: 'pointer',
        width: '98%',
        borderRadius: '20px',
        fontSize: defaultSizes.fonts.buttons,
        lineHeight: '110%',
        fontWeight: 600,
        background: 'transparent',
        border: `0.9px solid ${getColor('white')}`,
        boxSizing: 'border-box',
        marginBottom: '2%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        position: 'relative',
        color: getColor('white'),
      },
    },
  })

const e2eCardButtons = style({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  gap: '4px',
  $nest: {
    button: {
      padding: '5%',
      cursor: 'pointer',
      width: '98%',
      borderRadius: '20px',
      fontSize: defaultSizes.fonts.buttons,
      lineHeight: '110%',
      fontWeight: 600,
      background: 'transparent',
      border: `0.9px solid ${getColor('white')}`,
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      color: getColor('white'),
    },
  },
})

const ctaButton = (backgroundColor: string, textColor: string): string =>
  style({
    position: 'relative',
    backgroundColor: (backgroundColor || getColor('white')) + '!important',
    color: (textColor || getColor('black')) + '!important',
    ...(backgroundColor ? { border: 'none !important' } : {}),
  })

const whatsappButton = style({
  position: 'relative',
  backgroundColor: `${getColor('brand-whatsapp')} !important`,
  color: getColor('white'),
})

export default {
  card,
  oldCardTitle,
  newCardTitle,
  e2eCardTitle,
  oldCardButtons,
  newCardButtons,
  e2eCardButtons,
  descriptionWrapperE2e,
  ctaButton,
  oldDescriptionList,
  newDescriptionList,
  e2eDescriptionList,
  descriptionWrapper,
  oldCardContent,
  newCardContent,
  e2eCardContent,
  oldCardImage,
  newCardImage,
  e2eCardImage,
  templateName,
  whatsappButton,
  e2eCard,
  content,
}
