import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const overlay = style({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.4)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 8000,
  overflow: 'hidden',
})

const modalTransition = (transitionName: string): string =>
  style({
    $nest: {
      [`&.${transitionName}-enter-active,
       &.${transitionName}-leave-active`]: {
        transition: 'opacity 0.2s',
      },
      [`&.${transitionName}-enter-from,
       &.${transitionName}-leave-to`]: {
        opacity: 0,
      },
    },
  })

const modal = (portrait: boolean, fullWidth: boolean): string =>
  style({
    width: fullWidth ? '1044px' : portrait ? '480px' : '612px',
    minHeight: portrait ? '440px' : '250px',
    maxHeight: '85%',
    backgroundColor: getColor('white'),
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: `1px solid ${getColor('secondary-gray')}`,
    boxSizing: 'border-box',
    borderRadius: '20px',
    padding: portrait ? sizeMapper('sMedium') : sizeMapper('medium'),
    position: 'relative',
    $nest: {
      '.close': {
        cursor: 'pointer',
        position: 'absolute',
        top: '5px',
        right: '5px',
        width: '14px',
        height: '14px',
        margin: sizeMapper('xxSmall'),
      },
      button: {
        maxWidth: portrait ? '123px' : '100%',
      },
      '.modal-body': {
        flexGrow: 2,
        margin: '30px 0 10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        fontSize: '13px',
      },
    },
  })

export default {
  overlay,
  modalTransition,
  modal,
}
