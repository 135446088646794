import gql from 'graphql-tag'

export const GET_EMAIL_FROM_INVITATION = gql`
  query GetEmailFromInvitation($token: String!) {
    getEmailFromInvitation(token: $token) {
      userEmail
      role
    }
  }
`

export const GET_EMAIL_FROM_RECOVERY_HASH = gql`
  query GetEmailFromRecoveryHash($recoveryHash: String!, $userId: String!) {
    getEmailFromRecoveryHash(recoveryHash: $recoveryHash, userId: $userId) {
      email
    }
  }
`
