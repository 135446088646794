
import { defineComponent, PropType } from 'vue'
import { Color, Sizes } from '@/utils'
import { contentCardStyle } from '@/components/ui/styles'

export default defineComponent({
  name: 'BckContentCard',
  props: {
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    padding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    color: {
      type: String as PropType<Color>,
      default: 'white',
    },
    bottomBar: {
      type: Boolean,
      default: false,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    borderLess: {
      type: Boolean,
      default: false,
    },
    onlyPadding: {
      type: Boolean,
      default: false,
    },
    overflowHidden: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const contentCardClass = contentCardStyle(
      {
        margin: props.margin,
        padding: props.padding,
        color: props.color,
      },
      props.sticky,
      props.borderLess,
      props.onlyPadding,
      props.overflowHidden
    )
    return { contentCardClass }
  },
})
