import { style } from 'typestyle'
import { transparentize } from 'polished'
import { rotate, getColor } from '@/utils'

const LOADING_BACKGROUND_IMAGES = {
  DARK: 'PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMjQyODMzIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCIvPjwvZz48L3N2Zz4=',
  LIGHT:
    'PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCIvPjwvZz48L3N2Zz4=',
}

type COLOR = 'DARK' | 'LIGHT'
type LoadingSize = 'small' | 'medium' | 'large'
type LoadingSizes = {
  [key in LoadingSize]: {
    width: string
    height: string
    backgroundSize: string
  }
}

const SIZE_MAP: LoadingSizes = {
  small: {
    width: '14px',
    height: '10px',
    backgroundSize: '10px',
  },
  medium: {
    width: '14px',
    height: '14px',
    backgroundSize: '14px',
  },
  large: {
    width: '40px',
    height: '40px',
    backgroundSize: '40px',
  },
}

const loadingStyle = (color: COLOR, size: LoadingSize = 'medium') => {
  const { backgroundSize, height, width } = SIZE_MAP[size]
  return {
    display: 'inline-block',
    width,
    height,
    background: `url('data:image/svg+xml;base64,${LOADING_BACKGROUND_IMAGES[color]}') no-repeat center`,
    backgroundSize,
    animation: `${rotate} 0.6s linear infinite`,
  }
}

export const loading = (color: COLOR, size: LoadingSize = 'medium'): string =>
  style(loadingStyle(color, size))

export const loadingOverlay = {
  $nest: {
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2000,
      // background: transparentize(0.4, getColor('white')),
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 3000,
      margin: '-13px 0 0 -13px',
      ...loadingStyle('DARK'),
      width: '40px',
      height: '40px',
      backgroundSize: '40px',
    },
  },
} as const
