import {
  ProjectModel,
  Project,
  Branding,
  Asset,
  AssetType,
} from '@/types/models/projects'
import dayjs from 'dayjs'
import { isAfter, subHours } from 'date-fns'
import { generateRandomString } from '@/utils/textHelpers'
import { useProjectStyle } from '@/composables/useProjectStyle'
import ProjectsService from '@/graphqlBackOffice/projects/service'
import { transformTranslationsFromApi } from '@/utils/languagesHelper'
import { ITranslationResource } from '@/types/graphql/product'

export const transformProject = (project: ProjectModel): Project => {
  const requiredFields =
    project.pwaConfiguration &&
    !!project.pwaConfiguration.popupDescription?.appAlreadyInstalled &&
    !!project.pwaConfiguration.asset?.resourcePath
  return {
    ...project,
    id: project._id,
    createdAt: project.createdAt
      ? dayjs(project.createdAt).format('DD MMM YYYY')
      : '',
    incomplete: Object.values(project.settings).includes(false),
    metaData: project.metaData || undefined,
    // pwaConfigurationSet is set to true if the required fields have values
    pwaConfigurationSet: requiredFields,
    pwaConfiguration: {
      ...project.pwaConfiguration,
      popupDescription: {
        appAlreadyInstalled: transformTranslationsFromApi(
          project.pwaConfiguration?.popupDescription
            .appAlreadyInstalled as ITranslationResource
        ),
        notificationsDeclined: transformTranslationsFromApi(
          project.pwaConfiguration?.popupDescription
            .notificationsDeclined as ITranslationResource
        ),
        notificationsEnabledSuccesfully: transformTranslationsFromApi(
          project.pwaConfiguration?.popupDescription
            .notificationsEnabledSuccesfully as ITranslationResource
        ),
        webapp: transformTranslationsFromApi(
          project.pwaConfiguration?.popupDescription
            .webapp as ITranslationResource
        ),
      },
    },
  }
}

export const transformProjects = (
  items: Array<ProjectModel>
): Array<Project> => {
  return items.map(transformProject)
}

export const getFullCdnUrlByAssetType = (
  branding: Branding | undefined,
  assetType: AssetType
): string | null => {
  if (!branding) {
    return null
  }
  const { getProjectAsset } = useProjectStyle()
  const asset = getProjectAsset(branding.assets, assetType)

  return getFullCdnUrlByAsset(asset)
}

export const getFullCdnUrlByAsset = (
  asset: Asset | undefined
): string | null => {
  if (!asset?.resourcePath || typeof asset.resourcePath !== 'string') {
    return null
  }

  // Add unique key if asset was updated recently to avoid fetching it from cache
  const refetchAsset =
    !asset?.updatedAt ||
    isAfter(new Date(asset.updatedAt), subHours(new Date(), 1))
  return `${asset?.resourcePath}${
    refetchAsset ? '?key=' + generateRandomString() : ''
  }`
}

export const getProjectLogo = (project: Project): string => {
  const { getProjectAsset } = useProjectStyle()
  const resourcePath = getProjectAsset(
    project.branding?.assets,
    'logo'
  )?.resourcePath
  if (resourcePath && typeof resourcePath === 'string') {
    return getFullCdnUrlByAssetType(project.branding, 'logo') || ''
  }

  return ProjectsService.getDefaultLogo()
}
