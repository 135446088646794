import { ReadMoreTemplate } from '@/types/models'

export function getTranslation(field: any, language: string) {
  return field.translations.find((x: any) => x.lang === language).value
}

export function getReadMoreTemplate(readMore: any): ReadMoreTemplate {
  return {
    title: { translations: transform(readMore.title) },
    subtitle: { translations: readMore.readMoreRows.map((x: any) => transform(x.subtitle)) },
    description: { translations: readMore.readMoreRows.map((x: any) => transform(x.description)) },
  }
}

export function getTranslatedField(translations: any, field: string, language: string) {
  if (translations && translations[field] && translations[field][language]) {
    return translations[field][language]
  }

  return ''
}

function transform(translationObject: any) {
  const result: Record<string, string> = {}

  translationObject.translations.forEach((x: any) => {
    result[x.lang] = x.value
  })

  return result
}
