import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckMultiselect = _resolveComponent("BckMultiselect")!
  const _component_BckEditorMenuItem = _resolveComponent("BckEditorMenuItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.type === 'divider')
          ? (_openBlock(), _createElementBlock("div", {
              key: `divider${index}`,
              class: _normalizeClass(_ctx.css.divider)
            }, null, 2))
          : (item.type === 'font-size')
            ? (_openBlock(), _createBlock(_component_BckMultiselect, {
                key: `${index}`,
                searchable: true,
                mode: "single",
                width: "120px",
                disabled: _ctx.disabled,
                value: _ctx.fontSize,
                options: _ctx.fontOptions,
                "class-name": _ctx.css.editorMultiselect('125px'),
                onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editor.chain().focus().setFontSize($event).run()))
              }, null, 8, ["disabled", "value", "options", "class-name"]))
            : (_openBlock(), _createBlock(_component_BckEditorMenuItem, _mergeProps({
                key: 2,
                key: index,
                disabled: _ctx.disabled
              }, item), null, 16, ["disabled"]))
      ], 64))
    }), 256))
  ]))
}