import { style } from 'typestyle'
import { getColor } from '@/utils'

const searchInputStyle = {
  width: '100%',
  height: '30px',
  padding: '8px 8px 8px 31px',
  color: getColor(),
  backgroundColor: getColor('primary-blue-10'),
  outline: 'none',
  border: `1px solid ${getColor('secondary-light-gray')}`,
  borderRadius: '3px',
  lineHeight: '14px',
  fontWeight: 500,
  fontSize: '12px',
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTE1LjgxNyAxNC40MzMtNC4yNjYtNC4yNjVhNS45MDkgNS45MDkgMCAwIDAgMS4zMjQtMy43M0E1Ljk0NCA1Ljk0NCAwIDAgMCA2LjkzNy41IDUuOTQ0IDUuOTQ0IDAgMCAwIDEgNi40MzhhNS45NDQgNS45NDQgMCAwIDAgNS45MzcgNS45MzdjMS40MTMgMCAyLjcxLS40OTggMy43My0xLjMyNGw0LjI2NiA0LjI2NmEuNjIzLjYyMyAwIDAgMCAuODg0IDAgLjYyNC42MjQgMCAwIDAgMC0uODg0Wm0tOC44OC0zLjMwOEE0LjY5MiA0LjY5MiAwIDAgMSAyLjI1IDYuNDM3IDQuNjkyIDQuNjkyIDAgMCAxIDYuOTM3IDEuNzVhNC42OTIgNC42OTIgMCAwIDEgNC42ODggNC42ODggNC42OTIgNC42OTIgMCAwIDEtNC42ODggNC42ODdaIiBmaWxsPSJncmF5IiBzdHJva2U9ImdyYXkiIHN0cm9rZS13aWR0aD0iLjMiLz48L3N2Zz4=') left 8px center no-repeat",

  $nest: {
    '&:placeholder': {
      color: getColor('secondary-gray'),
    },
  },
}

const searchInput = style(searchInputStyle, {})

const searchInputContainer = style({
  position: 'relative',
  $nest: {
    '.loading': {
      position: 'absolute',
      marginTop: '8px',
      right: 10,
    },
  },
})

export default {
  searchInput,
  searchInputContainer,
}
