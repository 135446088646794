
import { defineComponent, ref, watch } from 'vue'
import { EditorContent, Editor } from '@tiptap/vue-3'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'
import { CustomOrderedList, CustomBulletList, FontSize } from '@/plugins/tiptap'
import { textEditorStyle as css } from './styles'

export default defineComponent({
  name: 'BckEditor',

  components: { EditorContent },

  props: {
    placeholder: {
      type: String,
      default: 'Insert the text here...',
    },
    characterLimit: {
      type: Number,
      default: 50000,
    },
    content: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['content-updated'],

  setup(props, { emit }) {
    const fontSize = ref('10px')
    const editor = new Editor({
      editable: !props.disabled,
      extensions: [
        StarterKit.configure({ bulletList: false, orderedList: false }),
        FontSize,
        CustomOrderedList,
        CustomBulletList,
        TextStyle,
        Placeholder.configure({
          placeholder: () => {
            return props.placeholder
          },
        }),

        CharacterCount.configure({
          limit: props.characterLimit,
        }),
      ],

      onUpdate({ editor }) {
        emit('content-updated', editor.getHTML())
      },
    })

    const setContent = (value: string) => {
      editor.commands.setContent(value)
    }

    setContent(props.content)

    watch(
      () => props.content,
      () => setContent(props.content)
    )

    const contentClicked = () => {
      editor.chain().focus().run()
      fontSize.value = editor.getAttributes('textStyle').fontSize || '10px'
    }

    return {
      contentClicked,
      editor,
      fontSize,
      css,
    }
  },
})
