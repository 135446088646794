
import { defineComponent, PropType } from 'vue'
import { Property } from 'csstype'
import { gridColumnStyle } from './styles'
import { Sizes } from '@/utils'

export default defineComponent({
  name: 'BckGridColumn',
  props: {
    padding: {
      type: String as PropType<Sizes>,
      required: false,
      default: 'no xSmall',
    },
    align: {
      type: String as PropType<Property.TextAlign>,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    shift: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { gridColumnStyle }
  },
})
