
import { defineComponent, ref } from 'vue'
import { tableStyle as css } from './styles'
import { pageContentLoading } from '@/components/common/styles'
import { LIMIT_PER_PAGE } from '@/composables/useTableView'
import NoDataMessage from '@/components/common/NoDataMessage.vue'

export default defineComponent({
  components: { NoDataMessage },
  props: {
    cells: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    initialSortedBy: {
      type: String,
      default: '',
    },
    initialSortOrder: {
      type: String,
      default: '',
    },
  },

  emits: ['sort'],

  setup(props, { emit }) {
    const sortedBy = ref(props.initialSortedBy)
    const sortedBySortOrder = ref(props.initialSortOrder)

    const onSortableHeaderPress = (cellName: string) => {
      if (!sortedBy.value) {
        sortedBy.value = cellName
        sortedBySortOrder.value = 'ASC'
      } else if (
        sortedBy.value === cellName &&
        sortedBySortOrder.value === 'ASC'
      ) {
        sortedBySortOrder.value = 'DESC'
      } else if (sortedBy.value !== cellName) {
        sortedBy.value = cellName
        sortedBySortOrder.value = 'ASC'
      } else {
        sortedBy.value = ''
        sortedBySortOrder.value = ''
      }

      emit('sort', sortedBy.value, sortedBySortOrder.value)
    }

    return {
      css: { ...css, pageContentLoading },
      LIMIT_PER_PAGE,
      sortedBySortOrder,
      sortedBy,
      onSortableHeaderPress,
    }
  },
})
