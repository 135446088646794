import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const editIcon = style({
  cursor: 'pointer',
  display: 'inline',
})

const input = style({
  width: '100%',
  color: getColor(),
  backgroundColor: 'transparent',
  outline: 'none',
  border: 'none',
  lineHeight: '16px',
  fontWeight: 400,
  fontSize: '14px',
  margin: sizeMapper('no no xxSmall'),
  caretColor: getColor('primary-blue'),
})

export default {
  editIcon,
  input,
}
