import {
  NavigationCard,
  Offer,
  PageCardType,
  TranslationItem,
  TranslationResource,
} from '@/types/models'
import {
  setDefaultApiTranslations,
  transformTranslationsForApi,
  transformTranslationsFromApi,
} from '@/utils/languagesHelper'
import { IButton } from '@/types/graphql/product'

export const transformButtonsForApi = (
  entity: Offer | NavigationCard,
  type: PageCardType
): IButton[] => {
  const buttons = []
  const languages = Object.keys(entity?.description?.translations || {})
  if (type === 'card') {
    const card = entity as NavigationCard
    const customButtons = card.buttons?.filter(
      (button) => button.appearance === 'default'
    )
    card?.buttonName?.translations?.forEach(
      (buttonName: TranslationItem, index: number) => {
        buttons.push({
          _id: customButtons?.[index]?._id,
          name: transformTranslationsForApi(buttonName),
          payload: card?.payload?.[index],
          payloadType: card?.payloadType?.[index],
          appearance: 'default',
        } as IButton)
      }
    )
  } else {
    const offer = entity as Offer
    const ctaButton = offer.buttons?.find(
      (button) => button.appearance === 'default'
    )

    buttons.push({
      _id: ctaButton?._id,
      name: transformTranslationsForApi(offer.buttonName?.translations),
      payload: offer.payload,
      payloadType: offer.payloadType,
      appearance: 'default',
    } as IButton)
  }

  if (entity.readMoreTemplate) {
    const readMoreButton = entity.buttons?.find(
      (button) => button.appearance === 'outline'
    )
    buttons.push({
      _id: readMoreButton?._id,
      name: transformTranslationsForApi(
        entity.readMoreButtonName?.translations
      ),
      payloadType: 'INTERNAL',
      appearance: 'outline',
      readMoreButton: true,
    } as IButton)
  }
  if (entity.whatsappShare) {
    buttons.push({
      _id: entity.buttons?.find((button) => button.appearance === 'whatsapp')
        ?._id,
      appearance: 'whatsapp',
      payloadType: 'PAGE_ACTION',
      payload: 'WHATSAPP_SHARE',
      name: setDefaultApiTranslations(languages, 'Share in WhatsApp'),
    } as IButton)
  }

  return buttons
}

export const transformReadMoreFromApi = (readMorePage: any) => {
  if (!readMorePage) {
    return undefined
  }
  return {
    _id: readMorePage._id,
    pageId: readMorePage.pageTypeDetails._id,
    name: readMorePage.name,
    title: transformTranslationsFromApi(readMorePage.pageTypeDetails.title),
    subtitle: {
      translations: readMorePage.pageTypeDetails.readMoreRows?.map(
        (item: any) => transformTranslationsFromApi(item.subtitle)?.translations
      ),
    },
    description: {
      translations: readMorePage.pageTypeDetails.readMoreRows?.map(
        (item: any) =>
          transformTranslationsFromApi(item.description)?.translations
      ),
    },
  }
}

/**
 * Returns the uploads from an array of images (urls & uploaded files)
 */
export const filterUploads = (
  images: TranslationResource | undefined
): TranslationItem | undefined => {
  return Object.fromEntries(
    Object.entries(images?.translations || {})
      .filter((entry) => typeof entry[1] !== 'string' || !entry[1])
      .map((entry: Array<any>) => [entry[0], entry[1]])
  )
}
