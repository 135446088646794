import { Language } from '@/types/models'

export const LANGUAGES: Array<Language> = [
  {
    label: 'Afar',
    value: 'aa',
    flagKey: 'dj',
  },
  {
    label: 'Afrikaans',
    value: 'af',
    flagKey: 'za',
  },
  {
    label: 'Akan',
    value: 'ak',
    flagKey: 'gh',
  },
  {
    label: 'Albanian',
    value: 'sq',
    flagKey: 'al',
  },
  {
    label: 'Amharic',
    value: 'am',
    flagKey: 'et',
  },
  {
    label: 'Arabic',
    value: 'ar',
    flagKey: 'sa',
  },
  {
    label: 'Armenian',
    value: 'hy',
    flagKey: 'am',
  },
  {
    label: 'Aymara',
    value: 'ay',
    flagKey: 'bo',
  },
  {
    label: 'Azerbaijani',
    value: 'az',
    flagKey: 'az',
  },
  {
    label: 'Belarusian',
    value: 'be',
    flagKey: 'by',
  },
  {
    label: 'Bengali',
    value: 'bn',
    flagKey: 'bd',
  },
  {
    label: 'Bislama',
    value: 'bi',
    flagKey: 'vu',
  },
  {
    label: 'Bulgarian',
    value: 'bg',
    flagKey: 'bg',
  },
  {
    label: 'Burmese',
    value: 'my',
    flagKey: 'mm',
  },
  {
    label: 'Chinese',
    value: 'zh',
    flagKey: 'cn',
  },
  {
    label: 'Croatian',
    value: 'hr',
    flagKey: 'hr',
  },
  {
    label: 'Czech',
    value: 'cs',
    flagKey: 'cz',
  },
  {
    label: 'Danish',
    value: 'da',
    flagKey: 'dk',
  },
  {
    label: 'Dutch',
    value: 'nl',
    flagKey: 'nl',
  },
  {
    label: 'Dzongkha',
    value: 'dz',
    flagKey: 'bt',
  },
  {
    label: 'English',
    value: 'en',
    flagKey: 'gb',
  },
  {
    label: 'Estonian',
    value: 'et',
    flagKey: 'ee',
  },
  {
    label: 'Faroese',
    value: 'fo',
    flagKey: 'fo',
  },
  {
    label: 'Fijian',
    value: 'fj',
    flagKey: 'fj',
  },
  {
    label: 'Finnish',
    value: 'fi',
    flagKey: 'fi',
  },
  {
    label: 'French',
    value: 'fr',
    flagKey: 'fr',
  },
  {
    label: 'Georgian',
    value: 'ka',
    flagKey: 'ge',
  },
  {
    label: 'German',
    value: 'de',
    flagKey: 'de',
  },
  {
    label: 'Greek',
    value: 'el',
    flagKey: 'gr',
  },
  {
    label: 'Greenlandic',
    value: 'kl',
    flagKey: 'gl',
  },
  {
    label: 'Guarani',
    value: 'gn',
    flagKey: 'py',
  },
  {
    label: 'Haitian Creole',
    value: 'ht',
    flagKey: 'ht',
  },
  {
    label: 'Hausa',
    value: 'ha',
    flagKey: 'ne',
  },
  {
    label: 'Hebrew',
    value: 'he',
    flagKey: 'il',
  },
  {
    label: 'Hindi',
    value: 'hi',
    flagKey: 'in',
  },
  {
    label: 'Hungarian',
    value: 'hu',
    flagKey: 'hu',
  },
  {
    label: 'Icelandic',
    value: 'is',
    flagKey: 'is',
  },
  {
    label: 'Indonesian',
    value: 'id',
    flagKey: 'id',
  },
  {
    label: 'Irish',
    value: 'ga',
    flagKey: 'ie',
  },
  {
    label: 'Italian',
    value: 'it',
    flagKey: 'it',
  },
  {
    label: 'Japanese',
    value: 'ja',
    flagKey: 'jp',
  },
  {
    label: 'Kazakh',
    value: 'kk',
    flagKey: 'kz',
  },
  {
    label: 'Khmer',
    value: 'km',
    flagKey: 'kh',
  },
  {
    label: 'Kinyarwanda',
    value: 'rw',
    flagKey: 'rw',
  },
  {
    label: 'Kirghiz',
    value: 'ky',
    flagKey: 'kg',
  },
  {
    label: 'Korean',
    value: 'ko',
    flagKey: 'kr',
  },
  {
    label: 'Kurdish',
    value: 'ku',
    flagKey: 'iq',
  },
  {
    label: 'Lao',
    value: 'lo',
    flagKey: 'la',
  },
  {
    label: 'Latin',
    value: 'la',
    flagKey: 'va',
  },
  {
    label: 'Latvian',
    value: 'lv',
    flagKey: 'lv',
  },
  {
    label: 'Lingala',
    value: 'ln',
    flagKey: 'cg',
  },
  {
    label: 'Lithuanian',
    value: 'lt',
    flagKey: 'lt',
  },
  {
    label: 'Luxembourgish',
    value: 'lb',
    flagKey: 'lu',
  },
  {
    label: 'Macedonian',
    value: 'mk',
    flagKey: 'mk',
  },
  {
    label: 'Malagasy',
    value: 'mg',
    flagKey: 'mg',
  },
  {
    label: 'Malay',
    value: 'ms',
    flagKey: 'my',
  },
  {
    label: 'Maltese',
    value: 'mt',
    flagKey: 'mt',
  },
  {
    label: 'Maori',
    value: 'mi',
    flagKey: 'nz',
  },
  {
    label: 'Mongolian',
    value: 'mn',
    flagKey: 'mn',
  },
  {
    label: 'Nauru',
    value: 'na',
    flagKey: 'nr',
  },
  {
    label: 'Nepali',
    value: 'ne',
    flagKey: 'np',
  },
  {
    label: 'Norwegian',
    value: 'no',
    flagKey: 'no',
  },
  {
    label: 'Pashto',
    value: 'ps',
    flagKey: 'af',
  },
  {
    label: 'Persian',
    value: 'fa',
    flagKey: 'ir',
  },
  {
    label: 'Polish',
    value: 'pl',
    flagKey: 'pl',
  },
  {
    label: 'Portuguese',
    value: 'pt',
    flagKey: 'pt',
  },
  {
    label: 'Quechua',
    value: 'qu',
    flagKey: 'pe',
  },
  {
    label: 'Romanian',
    value: 'ro',
    flagKey: 'ro',
  },
  {
    label: 'Rundi',
    value: 'rn',
    flagKey: 'bi',
  },
  {
    label: 'Russian',
    value: 'ru',
    flagKey: 'ru',
  },
  {
    label: 'Samoan',
    value: 'sm',
    flagKey: 'ws',
  },
  {
    label: 'Sango',
    value: 'sg',
    flagKey: 'cf',
  },
  {
    label: 'Serbian',
    value: 'sr',
    flagKey: 'rs',
  },
  {
    label: 'Sesotho',
    value: 'st',
    flagKey: 'ls',
  },
  {
    label: 'Shona',
    value: 'sn',
    flagKey: 'zw',
  },
  {
    label: 'Sinhalese',
    value: 'si',
    flagKey: 'lk',
  },
  {
    label: 'Slovak',
    value: 'sk',
    flagKey: 'sk',
  },
  {
    label: 'Slovenian',
    value: 'sl',
    flagKey: 'si',
  },
  {
    label: 'Somali',
    value: 'so',
    flagKey: 'so',
  },
  {
    label: 'Spanish',
    value: 'es',
    flagKey: 'es',
  },
  {
    label: 'Swahili',
    value: 'sw',
    flagKey: 'ke',
  },
  {
    label: 'Swati',
    value: 'ss',
    flagKey: 'sz',
  },
  {
    label: 'Swedish',
    value: 'sv',
    flagKey: 'se',
  },
  {
    label: 'Tagalog',
    value: 'tl',
    flagKey: 'ph',
  },
  {
    label: 'Tajik',
    value: 'tg',
    flagKey: 'tj',
  },
  {
    label: 'Thai',
    value: 'th',
    flagKey: 'th',
  },
  {
    label: 'Tigrinya',
    value: 'ti',
    flagKey: 'er',
  },
  {
    label: 'Tonga',
    value: 'to',
    flagKey: 'to',
  },
  {
    label: 'Tswana',
    value: 'tn',
    flagKey: 'bw',
  },
  {
    label: 'Turkish',
    value: 'tr',
    flagKey: 'tr',
  },
  {
    label: 'Turkmen',
    value: 'tk',
    flagKey: 'tm',
  },
  {
    label: 'Ukrainian',
    value: 'uk',
    flagKey: 'ua',
  },
  {
    label: 'Urdu',
    value: 'ur',
    flagKey: 'pk',
  },
  {
    label: 'Uzbek',
    value: 'uz',
    flagKey: 'uz',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    flagKey: 'vn',
  },
  {
    label: 'Wolof',
    value: 'wo',
    flagKey: 'sn',
  },
  {
    label: 'Yoruba',
    value: 'yo',
    flagKey: 'ng',
  },
]

export const LANGUAGE_KEYS = LANGUAGES.map((l) => l.value)
