import { style } from 'typestyle'
import { Color, FontWeight, getColor } from '@/utils'
import { getFontWeight } from '@/components/ui/styles/Typography'

export default ({
  backgroundColor = '#5930FF',
  color = '#fff',
  centered,
  fontSize = '14px',
  weight = 'normal',
  disabled = false,
}: {
  color: string
  backgroundColor: string
  fontSize: string
  centered?: boolean
  weight?: FontWeight
  disabled?: boolean
}): string =>
  style({
    backgroundColor: disabled ? getColor('secondary-gray') : backgroundColor,
    cursor: disabled ? 'default' : 'pointer',
    padding: '12px 18px',
    width: 'fit-content',
    borderRadius: '20px',
    color: color,
    fontWeight: getFontWeight(weight),
    fontSize: fontSize,
    whiteSpace: 'nowrap',
    ...(centered && { textAlign: 'center' }),
  })
