
import { defineComponent } from 'vue'
import { dateInputStyle as css, longText } from '@/components/ui/styles'

export default defineComponent({
  name: 'BckDateInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { css: { ...css, longText } }
  },
})
