
import { defineComponent, ref } from 'vue'
import { pageContent, pageContentLoading } from '@/components/common/styles'
import { useRouter } from 'vue-router'
import ConfigurationBanner from '../../views/project/components/ConfigurationBanner.vue'

export default defineComponent({
  name: 'BckPageContent',
  components: { ConfigurationBanner },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: String,
      required: false,
      default: undefined,
    },
    configBanner: {
      type: Boolean,
      required: false,
      default: false,
    },
    newPageStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { currentRoute } = useRouter()
    const disableContentCard = ref(currentRoute.value.meta.disableContentCard)
    return {
      pageContent,
      pageContentLoading,
      disableContentCard,
    }
  },
})
