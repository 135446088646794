import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.multiselectWrapper)
  }, [
    _createVNode(_component_Multiselect, {
      ref: "multiselect",
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
      disabled: _ctx.disabled,
      name: _ctx.name,
      options: _ctx.options,
      mode: _ctx.mode,
      searchable: _ctx.searchable,
      class: _normalizeClass([_ctx.className ? _ctx.className : _ctx.css.multiselect(_ctx.width, _ctx.margin, _ctx.optionPadding), _ctx.css.isActive]),
      "close-on-select": _ctx.closeOnSelect,
      "can-clear": _ctx.isClearable,
      label: _ctx.label,
      placeholder: _ctx.isOpen ? '' : _ctx.placeholder,
      max: _ctx.max,
      "append-new-option": _ctx.appendNewOption,
      "create-option": _ctx.createOption,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event)))
    }, {
      option: _withCtx(({ option }) => [
        _renderSlot(_ctx.$slots, "option", { option: option })
      ]),
      _: 3
    }, 8, ["modelValue", "disabled", "name", "options", "mode", "searchable", "class", "close-on-select", "can-clear", "label", "placeholder", "max", "append-new-option", "create-option"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.css.movedLabelClass(_ctx.labelPosition))
    }, _toDisplayString(_ctx.isOpen || _ctx.hasSelected ? _ctx.placeholder : ''), 3)
  ], 2))
}