import { ActionContext, ActionTree } from 'vuex'
import { RootState } from '@/store'
import { ProjectWizardModuleState } from '@/store/modules/projectWizard/state'

interface Actions<C = ActionContext<ProjectWizardModuleState, RootState>> {
  setRegion(commit: C, region: string): void
  setLanguages(commit: C, languages: string[]): void
  setDefaultLanguage(commit: C, language: string): void
  setProjectName(commit: C, projectName: string): void
  setTermsAndConditions(commit: C, value: boolean): void
  resetProjectWizard(commit: C): void
}

export const actions: ActionTree<ProjectWizardModuleState, RootState> &
  Actions = {
  setRegion({ commit }, region) {
    commit('SET_REGION', region)
  },
  setLanguages({ commit }, languages) {
    commit('SET_LANGUAGES', languages)
  },
  setDefaultLanguage({ commit }, language) {
    commit('SET_DEFAULT_LANGUAGE', language)
  },
  setProjectName({ commit }, projectName) {
    commit('SET_PROJECT_NAME', projectName)
  },
  setTermsAndConditions({ commit }, value) {
    commit('SET_TERMS_AND_CONDITIONS', value)
  },
  resetProjectWizard({ commit }) {
    commit('RESET_PROJECT_WIZARD')
  },
}
