import { style } from 'typestyle'
import { getColor, sizeMapper, Sizes } from '@/utils'

const card = (margin: Sizes, active: boolean): string =>
  style({
    width: '170px',
    height: '100px',
    borderRadius: '5px',
    background: !active ? getColor('white') : getColor('primary-blue-10'),
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.25)',
    padding: '23px 22px',
    margin: sizeMapper(margin),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    position: 'relative',
  })

const offerGroup = style({
  display: 'flex',
  justifyContent: 'flex-start',
  $nest: {
    div: {
      fontSize: '14px',
    },
  },
})

const title = style({
  color: getColor('primary-blue'),
  fontSize: '14px',
  lineHeight: '19px',
})

const number = style({
  color: getColor('primary-blue'),
  fontSize: '14px',
  lineHeight: '19px',
})

const detail = style({
  color: getColor('primary-blue'),
  fontSize: '14px',
  lineHeight: '19px',
  fontWeight: 400,
  marginLeft: '3px',
})

const triangle = style({
  display: 'flex',
  position: 'absolute',
  bottom: '-16px',
  left: '0',
  right: '0',
  margin: 'auto',
  width: '0',
  height: '0',
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderBottom: `12px solid ${getColor('white')}`,
  zIndex: '10',
})

export default {
  card,
  offerGroup,
  title,
  triangle,
  number,
  detail,
}
