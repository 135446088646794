// Common components used through the app
import { App } from '@vue/runtime-core'

import BckInput from '@/components/ui/BckInput.vue'
import BckSearchInput from '@/components/ui/BckSearchInput.vue'
import BckMiniInput from '@/components/ui/BckMiniInput.vue'
import BckTextArea from '@/components/ui/BckTextArea.vue'
import BckCheckbox from '@/components/ui/BckCheckbox.vue'
import BckSelect from '@/components/ui/BckSelect.vue'
import BckIcon from '@/components/ui/BckIcon.vue'
import BckH1 from '@/components/ui/BckH1.vue'
import BckH2 from '@/components/ui/BckH2.vue'
import BckH3 from '@/components/ui/BckH3.vue'
import BckH4 from '@/components/ui/BckH4.vue'
import BckH5 from '@/components/ui/BckH5.vue'
import BckH6 from '@/components/ui/BckH6.vue'
import BckText from '@/components/ui/BckText.vue'
import BckBold from '@/components/ui/BckBold.vue'
import BckLink from '@/components/ui/BckLink.vue'
import BckCaption from '@/components/ui/BckCaption.vue'
import BckLabel from '@/components/ui/BckLabel.vue'
import BckRadioButton from '@/components/ui/BckRadioButton.vue'
import BckRadioGroup from '@/components/ui/BckRadioGroup.vue'
import BckButton from '@/components/ui/BckButton.vue'
import BckCard from '@/components/ui/BckCard.vue'
import BckCardGrid from '@/components/ui/BckCardGrid.vue'
import BckContentCard from '@/components/ui/BckContentCard.vue'
import BckModal from '@/components/ui/BckModal.vue'
import BckMultiselect from '@/components/ui/BckMultiselect.vue'
import BckPage from '@/components/layout/BckPage.vue'
import BckGrid from '@/components/layout/BckGrid.vue'
import BckGridColumn from '@/components/layout/BckGridColumn.vue'
import BckAddProjectCard from '@/components/ui/BckAddProjectCard.vue'
import BckProjectCard from '@/components/ui/BckProjectCard.vue'
import BckTabs from '@/components/ui/BckTabs.vue'
import BckTable from '@/components/ui/BckTable.vue'
import BckPagination from '@/components/ui/BckPagination.vue'
import BckPreviewButton from '@/components/ui/BckPreviewButton.vue'
import BckTextBubble from '@/components/ui/BckTextBubble.vue'
import CustomBubbleText from '@/components/ui/CustomBubbleText.vue'
import BckFile from '@/components/ui/BckFile.vue'
import BckFunnelContentCard from '@/components/ui/BckFunnelContentCard.vue'
import BckOfferListCard from '@/components/ui/BckOfferListCard.vue'
import BckOfferTagCard from '@/components/ui/BckOfferTagCard.vue'
import BckToggleSwitch from '@/components/ui/BckToggleSwitch.vue'
import BckPageContent from '@/components/common/BckPageContent.vue'
import BckCalendar from '@/components/ui/BckCalendar.vue'
import BckDateInput from '@/components/ui/BckDateInput.vue'
import BckEditor from '@/components/ui/BckEditor.vue'
import BckEditorMenuBar from '@/components/ui/BckEditorMenuBar.vue'
import BckEditorMenuItem from '@/components/ui/BckEditorMenuItem.vue'
// import BckCodeEditor from '@/components/ui/BckCodeEditor.vue'
import BckLoading from '@/components/ui/BckLoading.vue'
import BckTooltip from '@/components/ui/BckTooltip.vue'

export default {
  install: (app: App): void => {
    app.component('BckH1', BckH1)
    app.component('BckH2', BckH2)
    app.component('BckH3', BckH3)
    app.component('BckH4', BckH4)
    app.component('BckH5', BckH5)
    app.component('BckH6', BckH6)
    app.component('BckText', BckText)
    app.component('BckBold', BckBold)
    app.component('BckLink', BckLink)
    app.component('BckCaption', BckCaption)
    app.component('BckLabel', BckLabel)
    app.component('BckInput', BckInput)
    app.component('BckSearchInput', BckSearchInput)
    app.component('BckMiniInput', BckMiniInput)
    app.component('BckTextArea', BckTextArea)
    app.component('BckCheckbox', BckCheckbox)
    app.component('BckSelect', BckSelect)
    app.component('BckRadioGroup', BckRadioGroup)
    app.component('BckRadioButton', BckRadioButton)
    app.component('BckCard', BckCard)
    app.component('CustomBubbleText', CustomBubbleText)
    app.component('BckCardGrid', BckCardGrid)
    app.component('BckPage', BckPage)
    app.component('BckIcon', BckIcon)
    app.component('BckGrid', BckGrid)
    app.component('BckGridColumn', BckGridColumn)
    app.component('BckButton', BckButton)
    app.component('BckToggleSwitch', BckToggleSwitch)
    app.component('BckAddProjectCard', BckAddProjectCard)
    app.component('BckProjectCard', BckProjectCard)
    app.component('BckContentCard', BckContentCard)
    app.component('BckModal', BckModal)
    app.component('BckMultiselect', BckMultiselect)
    app.component('BckTabs', BckTabs)
    app.component('BckTable', BckTable)
    app.component('BckTextBubble', BckTextBubble)
    app.component('BckPagination', BckPagination)
    app.component('BckPreviewButton', BckPreviewButton)
    app.component('BckFile', BckFile)
    app.component('BckFunnelContentCard', BckFunnelContentCard)
    app.component('BckOfferListCard', BckOfferListCard)
    app.component('BckOfferTagCard', BckOfferTagCard)
    app.component('BckPageContent', BckPageContent)
    app.component('BckCalendar', BckCalendar)
    app.component('BckDateInput', BckDateInput)
    app.component('BckEditor', BckEditor)
    app.component('BckEditorMenuBar', BckEditorMenuBar)
    app.component('BckEditorMenuItem', BckEditorMenuItem)
    // app.component('BckCodeEditor', BckCodeEditor)
    app.component('BckLoading', BckLoading)
    app.component('BckTooltip', BckTooltip)
  },
}
