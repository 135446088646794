import { style } from 'typestyle'
import { Color, getColor, rotate, sizeMapper } from '@/utils'

export const configurationContent = style({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
})

export const configurationMobilePreviewImage = style({
  width: '150.183px',
  position: 'absolute',
  left: '600px',
  top: '26px',
  height: '441.508px',
})

export const configurationInputContainer = style({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  marginTop: '28px',
  width: '275px',
})

export const configurationOutputContainer = style({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
})

export const configurationSubtitle = style({
  marginTop: '16px',
  fontSize: '14px',
  lineHeight: '18px',
  width: '476px',
  color: getColor(),
})

export const configurationArrowImage = style({
  position: 'absolute',
  bottom: '40px',
  left: '254px',
  height: '180px',
})

export const configurationDomainTypeLabel = style({
  position: 'absolute',
  left: '290px',
  top: '215px',
  padding: '10px',
})

export const configurationDomainLabel = style({
  color: getColor('primary-blue'),
  cursor: 'pointer',
})

export const configurationCheckedIcon = style({
  width: '20px',
  marginLeft: '10px',
})

export const configurationProtocolSelect = style({
  marginTop: '20px',
})

export const loading = style({
  animation: `${rotate} 0.6s linear infinite`,
})

export const loadingBar = style({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: '35px',
})

export const loadingChip = (color: Color): string =>
  style({
    height: '13px',
    width: '23px',
    backgroundColor: getColor(color),
    borderRadius: '4px',
    margin: sizeMapper('no tiny'),
  })

export const instructionsList = style({
  paddingLeft: '15px',
  $nest: {
    li: {
      marginBottom: '15px',
    },
  },
})

export const topImage = style({
  margin: '48px 0',
})

export const bottomText = style({
  textAlign: 'center',
  fontSize: '20px',
  marginBottom: '18px',
})

export const retryButton = style({
  marginBottom: '32px',
})

export default {
  configurationContent,
  configurationMobilePreviewImage,
  configurationInputContainer,
  configurationOutputContainer,
  configurationSubtitle,
  configurationArrowImage,
  configurationDomainTypeLabel,
  configurationDomainLabel,
  configurationCheckedIcon,
  configurationProtocolSelect,
  loading,
  loadingChip,
  loadingBar,
  instructionsList,
}
