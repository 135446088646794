
import { computed, defineComponent, ref } from 'vue'
import { useField } from 'vee-validate'
import {
  inputStyle,
  dangerText,
  formError,
  inputWrapper,
  formErrorSpacingPlaceholder,
  helperText as helperTextStyle,
} from './styles'
import { generateRandomString } from '@/utils/textHelpers'

export default defineComponent({
  name: 'BckTextArea',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    htmlId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
      default: 'Placeholder',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    maxCharacters: {
      type: Number,
      default: 0,
    },
    // Field name used for error messages
    uiFieldName: {
      type: String,
      default: '',
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    })
    const maxCharsError = ref('')

    const className = inputStyle({
      withTextAreaStyle: true,
      hasValue: !!inputValue.value,
      fullWidth: props.maxCharacters > 0 || props.fullWidth,
    })

    const helperText = computed(() => {
      if (props.maxCharacters) {
        return `${inputValue.value.length} of ${props.maxCharacters}`
      }

      return ''
    })

    const onInput = ($event: Event) => {
      emit('change', $event)
      handleChange($event)
    }

    const onInputBlur = () => {
      // Clear max characters error, the field cannot be invalid because of this so it's just a warning.
      maxCharsError.value = ''
      handleBlur()
    }

    const onKeypress = ($event: KeyboardEvent) => {
      if (!props.maxCharacters) {
        return
      }
      const target = $event.target as HTMLInputElement
      const newVal = target.value + $event.key

      if (newVal.length > props.maxCharacters) {
        maxCharsError.value = `The
        ${
          props.uiFieldName || props.placeholder?.toLowerCase()
        } field may not exceed
         ${props.maxCharacters} characters.`
      } else {
        maxCharsError.value = ''
      }
    }

    return {
      onInput,
      onInputBlur,
      onKeypress,
      errorMessage,
      inputValue,
      meta,
      helperText,
      maxCharsError,
      css: {
        formError,
        dangerText,
        className,
        inputWrapper,
        helperTextStyle,
        formErrorSpacingPlaceholder,
      },
      id: props.htmlId || generateRandomString(),
    }
  },
})
