
import { defineComponent } from 'vue'
import { textEditorItemStyle as css } from './styles'

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    action: {
      type: Function,
      required: true,
    },

    isActive: {
      type: Function,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {
      css,
    }
  },
})
