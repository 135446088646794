import { style } from 'typestyle'

export const dashboardEnvGroup = style({
  width: '65%',
  marginLeft: '20px',
})

export const dashboardImage = style({
  width: '100px',
  display: 'flex',
  alignItems: 'center',
  $nest: {
    img: {
      width: '100px',
    },
  },
})
