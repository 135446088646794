import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "name", "value", "disabled", "maxlength"]
const _hoisted_2 = ["for", "title", "data-title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.inputWrapper(!!_ctx.inputValue, _ctx.fullWidth, '5px'))
  }, [
    _createElementVNode("textarea", {
      id: _ctx.id,
      name: _ctx.name,
      class: _normalizeClass([_ctx.css.className, _ctx.errorMessage ? `${_ctx.css.dangerText}` : '']),
      value: _ctx.inputValue,
      disabled: _ctx.disabled,
      rows: "3",
      maxlength: _ctx.maxCharacters || -1,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeypress && _ctx.onKeypress(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInputBlur && _ctx.onInputBlur(...args)))
    }, null, 42, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx.id,
      title: _ctx.placeholder,
      "data-title": _ctx.placeholder
    }, null, 8, _hoisted_2),
    (_ctx.maxCharacters > 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(['helperText', _ctx.css.helperTextStyle(true)])
        }, _toDisplayString(_ctx.helperText), 3))
      : _createCommentVNode("", true),
    (_ctx.errorMessage && !_ctx.meta.valid)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(_ctx.css.formError)
        }, _toDisplayString(_ctx.errorMessage), 3))
      : (_ctx.maxCharsError)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(_ctx.css.formError)
          }, _toDisplayString(_ctx.maxCharsError), 3))
        : (_openBlock(), _createElementBlock("span", {
            key: 3,
            class: _normalizeClass(_ctx.css.formErrorSpacingPlaceholder)
          }, null, 2))
  ], 2))
}