export const Permissions = {
  ACCESS_ADMIN_PANEL: 'ACCESS_ADMIN_PANEL',
  INVITE_USERS_TO_PLATFORM: 'INVITE_USERS_TO_PLATFORM',
  CHANGE_PLATFORM_USER_ROLES: 'CHANGE_PLATFORM_USER_ROLES',
  INVITE_USERS_TO_PROJECT: 'INVITE_USERS_TO_PROJECT',
  ACCESS_PLATFORM_DASHBOARD: 'ACCESS_PLATFORM_DASHBOARD',
  CREATE_NEW_PROJECT: 'CREATE_NEW_PROJECT',
  ACCESS_PROJECT_DASHBOARD: 'ACCESS_PROJECT_DASHBOARD',
  PREVIEW_PUBLISH_PROJECT: 'PREVIEW_PUBLISH_PROJECT',
  OPEN_PROJECT_LINKS: 'OPEN_PROJECT_LINKS',
  ACCESS_ADVANCED_PROJECT_SETTINGS: 'ACCESS_ADVANCED_PROJECT_SETTINGS',
  ARCHIVE_PROJECT: 'ARCHIVE_PROJECT',
  ACCESS_PROJECT_STYLE: 'ACCESS_PROJECT_STYLE',
  ACCESS_PROJECT_PREVIEW_LAYOUT: 'ACCESS_PROJECT_PREVIEW_LAYOUT',
  EDIT_PROJECT_STYLE: 'EDIT_PROJECT_STYLE',
  ACCESS_PROJECT_META_INFORMATION: 'ACCESS_PROJECT_META_INFORMATION',
  EDIT_PROJECT_META_INFORMATION: 'EDIT_PROJECT_META_INFORMATION',
  ACCESS_PROJECT_CONFIG: 'ACCESS_PROJECT_CONFIG',
  CONFIGURE_PROJECT_DOMAIN: 'CONFIGURE_PROJECT_DOMAIN',
  ACCESS_PROJECT_PWA_CONFIG: 'ACCESS_PROJECT_PWA_CONFIG',
  ACCESS_PROJECT_LANGUAGE: 'ACCESS_PROJECT_LANGUAGE',
  EDIT_PROJECT_PWA_CONFIG: 'EDIT_PROJECT_PWA_CONFIG',
  EDIT_PROJECT_LANGUAGE: 'EDIT_PROJECT_LANGUAGE',
  ACCESS_PROJECT_AUTHORISATION: 'ACCESS_PROJECT_AUTHORISATION',
  CHANGE_PROJECT_USER_ROLES: 'CHANGE_PROJECT_USER_ROLES',
  DELETE_PROJECT: 'DELETE_PROJECT',
  ACCESS_OFFERS: 'ACCESS_OFFERS',
  ACCESS_SPECIFIC_OFFER: 'ACCESS_SPECIFIC_OFFER',
  CREATE_OFFER: 'CREATE_OFFER',
  EDIT_FIELDS_ON_OFFER: 'EDIT_FIELDS_ON_OFFER',
  DUPLICATE_OFFER: 'DUPLICATE_OFFER',
  DELETE_OFFER: 'DELETE_OFFER',
  ACCESS_FLOW_BUILDER: 'ACCESS_FLOW_BUILDER',
  MAKE_CHANGES_ON_CANVAS: 'MAKE_CHANGES_ON_CANVAS',
  ACCESS_SPECIFIC_PAGE: 'ACCESS_SPECIFIC_PAGE',
  ADD_NEW_EXTENSION: 'ADD_NEW_EXTENSION',
  ADD_NEW_PAGE: 'ADD_NEW_PAGE',
  EDIT_FIELDS_ON_PAGE: 'EDIT_FIELDS_ON_PAGE',
  ACCESS_TOS_PP: 'ACCESS_TOS_PP',
  EDIT_TOS_PP: 'EDIT_TOS_PP',
  ACCESS_NAVIGATION: 'ACCESS_NAVIGATION',
  EDIT_NAVIGATION: 'EDIT_NAVIGATION',
  ACCESS_CAMPAIGN_MANAGER: 'ACCESS_CAMPAIGN_MANAGER',
  CREATE_NEW_FUNNEL_GROUP: 'CREATE_NEW_CAMPAIGN',
  ACCESS_SPECIFIC_FUNNEL_GROUP: 'ACCESS_SPECIFIC_CAMPAIGN',
  ACCESS_SPECIFIC_FUNNEL: 'ACCESS_SPECIFIC_FUNNEL',
  EDIT_CAMPAIGN_TITLE: 'EDIT_CAMPAIGN_TITLE',
  CREATE_NEW_FUNNEL: 'CREATE_NEW_FUNNEL',
  DUPLICATE_FUNNEL: 'DUPLICATE_FUNNEL',
  DELETE_FUNNEL: 'DELETE_FUNNEL',
  EDIT_FIELDS_ON_DRAFT_FUNNEL: 'EDIT_FIELDS_ON_DRAFT_FUNNEL',
  PUBLISH_DRAFT_FUNNEL: 'PUBLISH_DRAFT_FUNNEL',
  OPEN_FUNNEL_LINKS: 'OPEN_FUNNEL_LINKS',
  ACCESS_ANALYTICS: 'ACCESS_ANALYTICS',
  EDIT_PROJECT_INTEGRATIONS: 'EDIT_PROJECT_INTEGRATIONS',
  ACCESS_PROJECT_INTEGRATIONS: 'ACCESS_PROJECT_INTEGRATIONS',
  ACCESS_LEAD_FORMS: 'ACCESS_LEAD_FORMS',
  ACCESS_TRAFFIC_CARDS: 'ACCESS_TRAFFIC_CARDS',
  EDIT_LEAD_FORMS: 'EDIT_LEAD_FORMS',
  EDIT_TRAFFIC_CARDS: 'EDIT_TRAFFIC_CARDS',
  ACCESS_VAULT: 'ACCESS_VAULT',
  ACCESS_CAMPAIGNS: 'ACCESS_CAMPAIGNS',
}
