/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavigationCard, OfferListItem, ProductPage, TranslationItem } from '@/types/models'
import { transformTranslationsForApi, transformTranslationsFromApi } from '@/utils/languagesHelper'
import {
  filterUploads,
  transformButtonsForApi,
  transformReadMoreFromApi,
} from '@/graphqlBackOffice/common/transformers'
import { ApiCard, ApiProductPage, ITranslationResource, LearnMorePage, PageType } from '@/types/graphql/product'
import { getImagePreview } from '@/utils/uploadsHelpers'
import { find, forEach, includes, map, values } from 'lodash'
import omitDeep from 'omit-deep-lodash'
import { CarouselPageCard } from '@/types/components/UiTypes'
import { Ref, ref } from 'vue'
import { Extension } from '@/types/models/productFlowBuilder'
import { DiagramPage } from '@/views/productFlowBuilder/helpers/pageVueComponentRenderer'

/**
 * Returns (translated) fields of a navigation card.
 */
export const translateNavigationCard = (card: NavigationCard | undefined, language: string) => {
  return {
    id: card?.id || '',
    title: card?.title?.translations?.[language] || '',
    name: card?.title?.translations?.[language] || '',
    image: getImagePreview(card?.image?.translations?.[language]),
    description: card?.description?.translations?.[language] || '',
    whatsappShare: !!card?.whatsappShare || false,
    buttons: card?.buttons?.map((button) => {
      return {
        name: transformTranslationsFromApi(button?.name)?.translations?.[language],
        appearance: button.appearance,
      }
    }),
  }
}

export const transformLearnMorePageForApi = (page: ProductPage) => {
  return {
    _id: page._id,
    pageType: 'LearnMorePage',
    name: page.name,
    pwa: false,
    pageTypeDetails: {
      learnMorePage: {
        _id: page.pageId,
        title: transformTranslationsForApi(page.title?.translations),
        description: transformTranslationsForApi(page.description?.translations as TranslationItem),
        type: page.type,
      },
    },
  }
}

export const transformReadMorePageForApi = (page: ProductPage, languages?: string[]) => {
  return {
    _id: page._id,
    pageType: 'ReadMorePage',
    name: page.name,
    pwa: false,
    pageTypeDetails: {
      readMorePage: {
        _id: page.pageId,
        title: transformTranslationsForApi(page?.title?.translations, languages),
        readMoreType: 'ROWS',
        readMoreRows: values(page?.subtitle?.translations as TranslationItem[])?.map((subtitleItem, index: number) => {
          return {
            subtitle: transformTranslationsForApi(subtitleItem, languages),
            description: transformTranslationsForApi(
              (page?.description?.translations as TranslationItem[])?.[index],
              languages
            ),
          }
        }),
      },
    },
  }
}

export const transformCarouselPageForApi = (page: ProductPage, projectId: string) => {
  return {
    _id: page._id,
    pageType: 'CarouselPage',
    name: page.name,
    pwa: false,
    pageTypeDetails: {
      carouselPage: {
        _id: page.pageId,
        title: transformTranslationsForApi(page.title?.translations),
        description: transformTranslationsForApi(page.description?.translations as TranslationItem),
        navigationArray: processNavigationArray(page.cards || [], projectId),
      },
    },
  }
}

export const transformPageForApi = (page: ProductPage, pageType: PageType, projectId?: string) => {
  switch (pageType) {
    case 'CarouselPage':
      return transformCarouselPageForApi(page, projectId as string)

    case 'LearnMorePage':
      return transformLearnMorePageForApi(page)

    case 'ReadMorePage':
      return transformReadMorePageForApi(page)
  }
}

export const transformPageFromApi = (page: any): ProductPage => {
  const offerCards = page.pageTypeDetails.offers.map((offer: any) => {
    return {
      _id: offer._id,
      type: 'offer',
    }
  })

  const cards = page.pageTypeDetails.cards.map((card: ApiCard) => transformNavigationCardFromApi(card))
  const orderedCards = [...offerCards, ...cards]
  orderedCards.sort((a, b) => {
    return page.pageTypeDetails.cardsOrder?.indexOf(a._id) - page.pageTypeDetails.cardsOrder?.indexOf(b._id)
  })
  return {
    _id: page._id,
    pageId: page.pageTypeDetails._id,
    name: page.name,
    title: transformTranslationsFromApi(page.pageTypeDetails.title),
    description: transformTranslationsFromApi(page.pageTypeDetails.description),
    cards: orderedCards,
  }
}

export const transformNavigationCardFromApi = (card: ApiCard): NavigationCard & { type: string } => {
  const customButtons = card.buttons?.filter((button) => button.appearance === 'default')
  return {
    type: 'card',
    _id: card._id,
    description: transformTranslationsFromApi(card.description),
    title: transformTranslationsFromApi(card.title),
    image: transformTranslationsFromApi(card.cardImageUrls),
    readMoreTemplate: transformReadMoreFromApi(card.readMorePage),
    buttons: card.buttons.map((button) => {
      return {
        _id: button._id,
        appearance: button.appearance,
        name: {
          translations: omitDeep(values(button.name?.translations), '__typename') || [],
        },
      }
    }),
    buttonName: {
      translations: customButtons.map((button) => transformTranslationsFromApi(button.name).translations) || [],
    },
    payload: customButtons.map((button) => button.payload || ''),
    payloadType: customButtons.map((button) => button.payloadType || 'EXTERNAL'),
    readMoreButtonName: transformTranslationsFromApi(
      card.buttons?.find((button) => button.payload === card.readMorePage?._id)?.name
    ),
    whatsappShare: card.buttons?.findIndex((button) => button.appearance === 'whatsapp') > -1,
  }
}

export const transformLearnMorePageFromApi = (page: ApiProductPage) => {
  const pageDetails = page.pageTypeDetails as LearnMorePage
  return {
    _id: page._id,
    pageId: page.pageId,
    pageType: page.pageType,
    name: page.name,
    productId: page.productId,
    title: transformTranslationsFromApi(pageDetails.title),
    description: transformTranslationsFromApi(pageDetails.description),
  }
}

const getCardsAndOffers = (cards: any, offers: any, cardsOrder: any) => {
  const orderedCards = [...offers, ...cards]
  orderedCards.sort((a, b) => {
    return cardsOrder.indexOf(a._id) - cardsOrder.indexOf(b._id)
  })

  return orderedCards
}

const findByIdAndType = (pages: any, pageId: string, types = ['CarouselPage', 'ReadMorePage']) => {
  return find(pages, (page: any) => page._id === pageId && includes(types, page.pageType))?._id || null
}

const getPageNameById = (pageId: string, pages: any) => {
  let pageName = ''
  map(pages, (page: any) => {
    if (page._id === pageId) {
      pageName = page.name
    }
  })

  return pageName
}

export const transformProductPagesAndExtensions = (data: any) => {
  const pages: any = []

  data?.pages.forEach((page: any) => {
    if (page.pageType === 'CarouselPage') {
      pages.push({
        _id: page._id,
        id: `a${page._id}`,
        name: page.name,
        title: page.pageTypeDetails.title?.translations[0].value,
        description: page.pageTypeDetails.description?.translations?.[0]?.value,
        type: 'carousel',
        navigation: page._id,
        offsetX: page.canvasCoordinates ? page.canvasCoordinates.offsetX : 0,
        offsetY: page.canvasCoordinates ? page.canvasCoordinates.offsetY : 0,
        offers: getCardsAndOffers(
          page.pageTypeDetails.cards,
          page.pageTypeDetails.offers,
          page.pageTypeDetails.cardsOrder
        ).map((card: any) => {
          return {
            name: card.title?.translations[0].value || card.name?.translations[0].value || 'Title',
            image: card.cardImageUrls?.translations[0].value || card.offerImageUrls?.translations[0].value,
            description: card.description.translations[0].value,
            buttons: card.buttons.map((button: any) => {
              const targetPageId = findByIdAndType(data.pages, button.payload)
              const targetExtensionId = find(data.extensions, (extension) => button.payload === extension.name)?.name
              if (button.appearance !== 'whatsapp') {
                return {
                  id: button._id,
                  buttonName: button.name?.translations?.[0]?.value,
                  payloadType: targetPageId || targetExtensionId ? button.payloadType : 'OTHER',
                  payload: button.payload,
                  pageIdPayload: `a${targetPageId || targetExtensionId}`,
                  appearance: button.appearance,
                }
              } else {
                return {
                  id: button._id,
                  buttonName: button.name?.translations?.[0]?.value,
                  appearance: button.appearance,
                  payloadType: 'PAGE_ACTION',
                  payload: 'WHATSAPP_SHARE',
                }
              }
            }),
          }
        }),
      })
    }

    if (page.pageType === 'ReadMorePage') {
      pages.push({
        id: `a${page._id}`,
        name: page.name,
        title: page.pageTypeDetails.title?.translations[0].value,
        type: 'readMore',
        buttons: page.pageTypeDetails.buttons.map((button: any) => button.name.translations?.[0]?.value),
        readMoreRows: page.pageTypeDetails.readMoreRows.map((row: any) => ({
          subtitle: row.subtitle.translations?.[0]?.value,
          description: row.description.translations?.[0]?.value,
        })),
        navigation: page._id,
        offsetX: page.canvasCoordinates ? page.canvasCoordinates.offsetX : 0,
        offsetY: page.canvasCoordinates ? page.canvasCoordinates.offsetY : 0,
      })
    }
  })

  forEach(data?.extensions, (extension: any) => {
    pages.push(transformExtension(extension))
  })

  return {
    _id: data._id,
    pages,
    configuration: {
      bottomNavigator: data?.menu.map((menu: any) => {
        return `a${findByIdAndType(data.pages, menu.payload)}`
      }),
    },
    nav: data?.menu?.map((element: any) => {
      return {
        id: element.payload,
        icon: element.icon,
        name: getPageNameById(element.payload, data?.pages),
      }
    }),
  }
}

export const processNavigationArray = (cards: CarouselPageCard[], projectId: string, processButtons = true): any => {
  return cards?.map((item) => {
    if (item.type === 'offer') {
      return {
        _id: (item as OfferListItem)._id,
        type: 'OFFER',
      }
    } else if (item.type === 'leadForm') {
      return {
        _id: (item as any)._id,
        type: 'LEAD_FORM',
      }
    } else if (item.type === 'trafficCard') {
      return {
        _id: (item as any)._id,
        type: 'TRAFFIC_CARD',
      }
    } else if (item.type === 'card') {
      const card = item as NavigationCard
      const uploadedImages = filterUploads(card.image)
      return {
        _id: card._id,
        type: 'CARD',
        projectId: projectId,
        cardImageUrls: transformTranslationsForApi(uploadedImages),
        description: transformTranslationsForApi(card.description?.translations),
        title: transformTranslationsForApi(card.title?.translations),
        readMorePage: card.readMoreTemplate
          ? transformPageForApi(card.readMoreTemplate, 'ReadMorePage', 'CARD_READ_MORE')
          : null,
        buttons: processButtons ? transformButtonsForApi(item as NavigationCard, 'card') : item.buttons,
      }
    }
  })
}

export const cleanTranslations = (translations: ITranslationResource): any => {
  const cleanTranslations: Ref<any> = ref([])
  forEach(translations?.translations, (translation) => {
    cleanTranslations.value.push({
      lang: translation?.lang,
      value: translation?.value,
    })
  })

  return cleanTranslations.value
}

export const transformExtension = (extension: Extension): DiagramPage => ({
  _id: extension._id,
  id: `a${extension.name}`,
  name: extension.name,
  type: 'extension',
  title: '',
  description: '',
  offers: [],
  offsetX: extension.canvasCoordinates ? extension.canvasCoordinates.offsetX : 0,
  offsetY: extension.canvasCoordinates ? extension.canvasCoordinates.offsetY : 0,
  navigation: extension.name,
  project: null,
})
