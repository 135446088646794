import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

interface AccordionType {
  isOpen: boolean
}

export const projectAccordion = ({ isOpen }: AccordionType): string =>
  style({
    overflow: 'hidden',
    width: '100%',
    height: isOpen ? 'auto' : '55px',
    backgroundColor: getColor('white'),
    boxShadow: '3px 2px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    cursor: 'pointer',
    marginBottom: '20px',
    background: isOpen
      ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNi45OTcgMi41MjUgNS4wMzQgNS4yNTdhLjY5OC42OTggMCAwIDAgMS4wMTQgMCAuNzQ3Ljc0NyAwIDAgMCAwLTEuMDI4TDcuNTA1Ljk2OGEuNjk4LjY5OCAwIDAgMC0xLjAxNSAwTC45NSA2Ljc1NEguOTQ4bC0uMDAzLjAwM2EuNzQ3Ljc0NyAwIDAgMCAuMDE3IDEuMDI4Yy4yNzcuMjc5LjcyLjI3OS45OTcgMGwuMDAzLS4wMDMgNS4wMzQtNS4yNTdaIiBmaWxsPSIjODA4NjhCIiBzdHJva2U9IiM4MDg2OEIiIHN0cm9rZS13aWR0aD0iLjUiLz48L3N2Zz4=') right 32px top 24px no-repeat"
      : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNy4wMDMgNi4yMjUgMS45NjkuOTY4YS42OTguNjk4IDAgMCAwLTEuMDE0IDAgLjc0Ny43NDcgMCAwIDAgMCAxLjAyOGw1LjU0IDUuNzg2Yy4yOC4yOS43MzYuMjkgMS4wMTUgMGw1LjU0LTUuNzg2aC4wMDFsLjAwMy0uMDAzYS43NDcuNzQ3IDAgMCAwLS4wMTgtMS4wMjguNjk4LjY5OCAwIDAgMC0uOTk2IDBsLS4wMDMuMDAzLTUuMDM0IDUuMjU3WiIgZmlsbD0iIzgwODY4QiIgc3Ryb2tlPSIjODA4NjhCIiBzdHJva2Utd2lkdGg9Ii41Ii8+PC9zdmc+') right 32px top 24px no-repeat",
  })

export const accordionContent = style({
  padding: sizeMapper('no small small small'),
  fontSize: '12px',
  lineHeight: '14px',
  whiteSpace: 'pre-wrap',
})
