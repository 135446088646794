import { MutationTree } from 'vuex'
import { AuthModuleState } from './state'

interface Mutations<S = AuthModuleState> {
  SET_IS_LOGGED_IN(state: S, isLoggedIn: boolean): void
}

export const mutations: MutationTree<AuthModuleState> & Mutations = {
  SET_IS_LOGGED_IN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn
  },
}
