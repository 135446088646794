
import { defineComponent, PropType, ref, computed, toRef } from 'vue'
import { useField } from 'vee-validate'
import {
  inputStyle,
  dangerText,
  longText,
  selectStyle as css,
  formError,
  selectItemError,
  formErrorSpacingPlaceholder,
} from './styles'
import { getColor } from '@/utils'
import { generateRandomString } from '@/utils/textHelpers'

interface OptionType {
  value: number | string
  label: string
  unavailable?: boolean
}

export default defineComponent({
  name: 'BckSelect',
  props: {
    // The selected option if multiple prop is false
    value: {
      type: String,
      default: '',
    },
    // The selected options if multiple prop is true
    values: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    htmlId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Choose an item',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    slotErrorMessage: {
      type: String,
      default: '',
    },
    options: {
      type: Array as PropType<OptionType[]>,
      default: () => [],
    },
    cssClass: {
      type: String,
      default: '',
    },
    chevronIconColor: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    noError: {
      type: Boolean,
      default: false,
    },
    withSpacing: {
      type: Boolean,
      default: true,
    },
    withArrow: {
      type: Boolean,
      default: false,
    },
    alwaysShowPreviewSpace: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const search = ref('')
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(toRef(props, 'name'), undefined, {
      initialValue: props.multiple ? props.values : props.value,
    })

    const isOverlayOpen = ref(false)
    const unavailableMessage = ref(false as string | number | boolean)

    const openOverlay = () => {
      isOverlayOpen.value = true
      setTimeout(() => {
        document.addEventListener('mousedown', closeOverlay)
      })
    }
    const closeOverlay = () => {
      isOverlayOpen.value = false
      unavailableMessage.value = -1
      setTimeout(() => {
        document.removeEventListener('mousedown', closeOverlay)
      })
    }

    const chooseOption = (value: number | string) => {
      if (props.multiple) {
        let newValue = inputValue.value
        if (inputValue.value.includes(value)) {
          newValue = newValue.filter((item: string | number) => item !== value)
        } else {
          //Avoid mutating object
          newValue = [...newValue, value]
        }

        handleChange(newValue)
        emit('change', newValue)
      } else {
        closeOverlay()
        handleChange(value)
        emit('change', value)
        search.value = ''
      }
    }

    const isSelected = (value: string) => {
      return props.multiple ? inputValue.value.includes(value) : inputValue.value === value
    }

    const getLabelForValue = (value: string) => {
      return props.options.find((option) => option.value === value)?.label
    }

    const searchInputValue = computed(() => {
      return !props.multiple
        ? search.value || getLabelForValue(inputValue.value)
        : inputValue.value.length > 0
        ? `${inputValue.value.length} selected`
        : null
    })

    const showUnavailableMessage = (optionValue: number | string) => {
      unavailableMessage.value = optionValue
    }

    const filteredItems = computed(() => {
      if (!(props.searchable && search.value != '')) {
        return props.options
      }
      return props.options.filter((option) => {
        return option.label.toLowerCase().includes(search.value.trim().toLowerCase())
      })
    })

    const inputClass = inputStyle({
      hasValue: !!inputValue.value,
      withSelectStyle: true,
      searchableStyle: props.searchable,
      iconColor: props.chevronIconColor,
      fullWidth: props.fullWidth,
    })

    const updateSearchKeyword = ($event: Event) => {
      const target = $event.target as HTMLInputElement
      search.value = target?.value
      handleChange('')
      emit('change', '')
    }

    return {
      isSelected,
      searchInputValue,
      formError,
      formErrorSpacingPlaceholder,
      selectItemError,
      dangerText,
      chooseOption,
      handleBlur,
      errorMessage,
      slotErrorMessageText: props.slotErrorMessage,
      inputValue,
      meta,
      inputClass,
      selectOptions: filteredItems,
      isOverlayOpen,
      openOverlay,
      closeOverlay,
      getLabelForValue,
      showUnavailableMessage,
      unavailableMessage,
      updateSearchKeyword,
      css: { ...css, longText },
      getColor,
      id: props.htmlId || generateRandomString(),
    }
  },
  methods: {
    onOverlayMouseDown(event: MouseEvent) {
      event.stopImmediatePropagation()
    },
  },
})
