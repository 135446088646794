import { Module } from 'vuex'
import { RootState } from '@/store'

import { actions } from '@/store/modules/projectWizard/actions'
import { mutations } from '@/store/modules/projectWizard/mutations'
import { getters } from '@/store/modules/projectWizard/getters'
import { state } from '@/store/modules/projectWizard/state'
import type { ProjectWizardModuleState } from '@/store/modules/projectWizard/state'

export { ProjectWizardModuleState }

export const projectWizardModule: Module<ProjectWizardModuleState, RootState> =
  {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
  }
