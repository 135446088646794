import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

export default (active: boolean): string =>
  style({
    fontSize: '14px',
    cursor: 'pointer',
    lineHeight: '30px',
    borderBottom: `${active ? '2px' : '1px'} solid ${getColor(
      active ? 'primary-blue' : 'secondary-gray'
    )}`,
    color: active ? getColor('primary-blue') : getColor('secondary-gray'),
    margin: sizeMapper('small no'),
    paddingBottom: `${!active ? '1px' : '0px'}`,
  })
