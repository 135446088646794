import { Campaign, Funnel, Offer, ProductPage } from '@/types/models'

export interface CampaignsState {
  funnel: Funnel | undefined
  newCampaign: Campaign | undefined
  newOffer: Offer | undefined
  selectedLanguage: string
  readMoreTemplate: ProductPage | undefined
}

export const state: CampaignsState = {
  funnel: undefined,
  newCampaign: undefined,
  newOffer: undefined,
  selectedLanguage: '',
  readMoreTemplate: undefined,
}
