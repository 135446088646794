import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_BckLoading = _resolveComponent("BckLoading")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.css.page)
      }, [
        _createVNode(_component_Header, {
          "project-id": _ctx.project.id,
          "project-name": _ctx.project.name
        }, null, 8, ["project-id", "project-name"]),
        _createElementVNode("div", {
          id: "content",
          class: _normalizeClass(_ctx.css.content(_ctx.disableOverflow))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ], 2))
    : (_openBlock(), _createBlock(_component_BckLoading, {
        key: 1,
        class: _normalizeClass(_ctx.css.loading)
      }, null, 8, ["class"]))
}