import { style } from 'typestyle'
import { getColor } from '@/utils'

export const mainNavigationTooltip = ({
  top,
  left,
  right,
  fontSize,
}: {
  top: number
  left: number
  right: number
  fontSize: string
}): string =>
  style({
    position: 'absolute',
    zIndex: 99,
    top: top - 10 + 'px',
    left: left + 'px',
    right: right + 'px',
    color: getColor('primary-navy-60'),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 5px',
    background: getColor('white'),
    fontSize,
    borderRadius: '10px 0px 10px 10px',
    boxShadow: `0px 0px 20px 2px ${getColor('secondary-gray')}`,

    $nest: {
      span: {
        position: 'absolute',
        top: '-9px',
        right: 0,
        borderColor: `transparent transparent ${getColor('white')} transparent`,
        borderStyle: 'solid',
        borderWidth: '0px 5px 9px 5px',
        height: '0px',
        width: '0px',
      },
    },
  })

export default { mainNavigationTooltip }
