import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoDataMessage = _resolveComponent("NoDataMessage")!
  const _component_BckPagination = _resolveComponent("BckPagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([
      _ctx.css.tableContainer,
      _ctx.loading ? `${_ctx.css.pageContentLoading(true)}` : '',
    ])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.css.tableHead)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css.tableRow({ isHeader: true }))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (cell, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(
            _ctx.css.tableHeadCell({
              width: cell.width,
              sortable: cell.sortable,
              isSortedBy: _ctx.sortedBy === cell.name,
              sortOrder: _ctx.sortedBySortOrder,
            })
          ),
              onClick: 
            () => _ctx.sortable && cell.sortable && _ctx.onSortableHeaderPress(cell.name)
          
            }, _toDisplayString(cell.label), 11, _hoisted_1))
          }), 128))
        ], 2)
      ], 2),
      (_ctx.rows.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.css.tableBody)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
              return _renderSlot(_ctx.$slots, "default", {
                key: index,
                row: row
              })
            }), 128))
          ], 2))
        : (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_NoDataMessage, { key: 1 }))
          : _createCommentVNode("", true)
    ], 2),
    (!_ctx.loading && _ctx.count > _ctx.LIMIT_PER_PAGE)
      ? (_openBlock(), _createBlock(_component_BckPagination, {
          key: 0,
          "total-results": _ctx.count,
          "limit-per-page": _ctx.LIMIT_PER_PAGE,
          "max-pagination-items": 6
        }, null, 8, ["total-results", "limit-per-page"]))
      : _createCommentVNode("", true)
  ], 64))
}