import { Ref, ref } from 'vue'

interface UseModalComposable {
  isModalVisible: Ref<boolean>
  showModal: () => void
  closeModal: () => void
}

export const useModal = (): UseModalComposable => {
  const isModalVisible = ref(false)
  const showModal = () => {
    isModalVisible.value = true
  }

  const closeModal = () => {
    isModalVisible.value = false
  }

  return { isModalVisible, showModal, closeModal }
}
