import { style } from 'typestyle'

const page = style({
  minHeight: '100vh',
  backgroundColor: '#F2F3F5',
})

const content = (disableOverflow: boolean): string =>
  style({
    position: 'absolute',
    minWidth: '1200px',
    top: 56,
    height: 'calc(100vh - 56px)',
    left: 0,
    right: 0,
    overflow: disableOverflow ? 'hidden' : 'auto',
    backgroundColor: '#fff',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  })

const loading = style({
  display: 'block !important',
  margin: '25% auto',
})

export default {
  page,
  content,
  loading,
}
