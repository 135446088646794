/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import gql from 'graphql-tag'
import { InMemoryCache } from '@apollo/client/core'
import { PROJECT_BY_ID } from '@/graphqlBackOffice/projects/queries'
import { Project } from '@/types/models'
import { ApolloCache } from '@apollo/client/cache/core/cache'

export const updateProject = gql`
  mutation updateProject(
    $projectId: MongoID!
    $fieldName: string!
    $value: string!
  ) {
    updateProject(id: $id, fieldName: $fieldName, value: $value) @client
  }
`

export const projectLocalMutations = {
  updateProject: (
    _: any,
    vars: { [key: string]: string },
    { cache }: { cache: InMemoryCache }
  ) => {
    updateCachedProjectFields(cache, vars.id, {
      [`${vars.fieldName}`]: vars.value,
    })
  },
}

export const updateCachedProjectFields = (
  cache: ApolloCache<any>,
  projectId: string,
  updateFields: { [key: string]: string | Array<any> }
): void => {
  const data: { project: Project } | null = cache.readQuery(
    {
      query: PROJECT_BY_ID,
      variables: {
        projectId: projectId,
      },
    },
    true
  )
  const currentItem = data?.project
  if (!currentItem) {
    return
  }
  cache.writeQuery({
    query: PROJECT_BY_ID,
    variables: {
      projectId: projectId,
    },
    data: {
      project: {
        ...currentItem,
        ...updateFields,
      },
    },
  })
}
