import { CustomFilters, TableFiltersQuery } from '@/types/components'
import { ListQuery, Operator, OperatorInput } from '@/types/graphql'
import { TableRow } from '@/composables/useTableView'
import { merge, set } from 'lodash'

interface UseQueryFilters {
  computedOperator: (
    field: string,
    operator: Operator,
    value: string | Array<TableRow | string | boolean>
  ) => OperatorInput
  computedQuery: (
    filtersQuery?: TableFiltersQuery,
    searchFields?: Array<string>,
    customFilters?: CustomFilters
  ) => ListQuery
}

export const useQueryFilters = (): UseQueryFilters => {
  const computeSortValues = (sortBy: string, sortOrder: string) =>
    `${sortBy.toUpperCase()}_${sortOrder}`

  const computedOperator = (
    field: string,
    operator: Operator,
    value: string | Array<TableRow | string | boolean>
  ): OperatorInput => {
    return {
      _operators: set({}, field, {
        [operator]: value,
      }),
    }
  }

  const searchFiltersMapper = (
    filters: Array<string>,
    searchQuery: string
  ): Array<OperatorInput> => {
    const filterOperators: Array<OperatorInput> = []
    filters.forEach((filter: string) => {
      filterOperators.push(
        computedOperator(filter, 'regex', `/${searchQuery}/i`)
      )
    })

    return filterOperators
  }

  const computedQuery = (
    filtersQuery?: TableFiltersQuery,
    searchFields?: Array<string>,
    customFilters?: CustomFilters
  ): ListQuery => {
    const query: ListQuery = {}
    query.sort = undefined
    if (filtersQuery?.sortedBy && filtersQuery?.sortOrder) {
      query.sort = computeSortValues(
        filtersQuery.sortedBy,
        filtersQuery.sortOrder
      )
    }
    query.perPage = filtersQuery?.perPage
    query.page = filtersQuery?.page

    query.filter = {
      ...(query.filter || {}),
      ...merge(
        filtersQuery?.marketingPlatform &&
          filtersQuery?.marketingPlatform?.length > 0 &&
          computedOperator('platform', 'in', filtersQuery?.marketingPlatform),
        filtersQuery?.type &&
          filtersQuery?.type?.length > 0 &&
          computedOperator('type', 'in', filtersQuery?.type)
      ),
    }

    if (searchFields) {
      query.filter = {
        ...(query.filter || {}),
        ...(filtersQuery?.search && {
          OR: searchFiltersMapper(searchFields, filtersQuery.search),
        }),
      }
    }

    if (filtersQuery?.startDate && filtersQuery?.endDate) {
      query.filter = {
        ...(query.filter || {}),
        _operators: {
          ...(query.filter?._operators && query.filter?._operators),
          createdAt: {
            gte: new Date(filtersQuery?.startDate).setHours(0, 0, 0, 0),
            lte: new Date(filtersQuery?.endDate).setHours(23, 59, 59, 59),
          },
        },
      }
    }

    if (customFilters) {
      query.filter = {
        ...(query.filter || {}),
        ...(customFilters || {}),
      }
    }

    return query
  }

  return { computedQuery, computedOperator }
}
