import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "name", "value", "placeholder", "disabled", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.searchInputContainer)
  }, [
    _createElementVNode("input", {
      id: _ctx.name,
      name: _ctx.name,
      class: _normalizeClass(_ctx.css.searchInput),
      value: _ctx.inputValue,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      type: _ctx.type,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputChange && _ctx.onInputChange(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
    }, null, 42, _hoisted_1),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.loading('DARK'), 'loading'])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}