
import { defineComponent } from 'vue'
import { tabsStyle } from './styles'

export default defineComponent({
  props: {
    tabsData: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: String,
      required: true,
    },
  },
  emits: ['tab-selected'],
  setup() {
    return { tabsStyle }
  },
})
