import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import {
  projectWizardModule,
  ProjectWizardModuleState,
} from '@/store/modules/projectWizard'

import { authModule, AuthModuleState } from '@/store/modules/auth'

import { campaignsModule, CampaignsState } from '@/store/modules/campaign'

export interface RootState {
  authModule: AuthModuleState
  campaignsModule: CampaignsState
  projectWizardModule: ProjectWizardModuleState
}

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore<RootState>({
  modules: {
    authModule,
    campaignsModule,
    projectWizardModule,
  },
})

export const useStore = (): Store<RootState> => {
  return baseUseStore(key)
}
