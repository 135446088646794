import { style, cssRule, fontFace, classes } from 'typestyle'
import { getColor, getBasicStyles, TextSize, FontWeight } from '@/utils'
import { BasicProps } from '@/types/components'
import { Property } from 'csstype'

cssRule('body', {
  fontWeight: 'normal',
  minWidth: '1200px',
  overflowX: 'auto',
  padding: '0',
  margin: '0',
  textRendering: 'optimizeLegibility',
})

cssRule('ul, ol', {
  margin: 0,
  padding: 0,
})

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
interface HeadingProps extends BasicProps {
  type: HeadingType
  align?: Property.TextAlign
  noBold?: boolean
}

export const heading = ({
  margin = 'no',
  padding = 'no',
  color = 'primary-navy',
  type,
  align = 'start',
  noBold = false,
}: HeadingProps): string =>
  style({
    fontSize: getFontSize(type),
    lineHeight: '140%',
    fontWeight: noBold ? '500' : 'bold',
    textAlign: align,
    ...getBasicStyles(color, margin, padding),
  })

const getFontSize = (type: HeadingType) => {
  switch (type) {
    case 'h1':
      return '32px'
    case 'h2':
      return '28px'
    case 'h3':
      return '24px'
    case 'h4':
      return '20px'
    case 'h5':
      return '18px'
    case 'h6':
      return '12px'
  }
}

interface TextProps extends BasicProps {
  size: TextSize
  weight: FontWeight
  clickable: boolean
  align: Property.TextAlign
  display: string
}

export const regular = ({
  margin = 'no',
  padding = 'no',
  color = 'primary-blue',
  size = 'regular',
  weight = 'normal',
  clickable = false,
  align = 'start',
  display = 'block',
}: TextProps): string =>
  classes(
    style({
      fontWeight: getFontWeight(weight),
      fontSize: getTextSize(size),
      lineHeight: getLineHeight(size),
      fontStyle: 'normal',
      cursor: clickable ? 'pointer' : 'default',
      textAlign: align,
      display,
      ...getBasicStyles(color, margin, padding),
    })
  )

export const getFontWeight = (weight: FontWeight): number => {
  switch (weight) {
    case 'normal':
      return 400
    case 'bold':
      return 700
    default:
      return 400
  }
}

const getTextSize = (size: TextSize): string => {
  switch (size) {
    case 'small':
      return '12px'
    case 'regular':
      return '14px'
    case 'large':
      return '16px'
  }
}

const getLineHeight = (size: TextSize): string => {
  switch (size) {
    case 'small':
      return '14px'
    case 'regular':
      return '18px'
    case 'large':
      return '140%'
  }
}

export const bold = ({ margin = 'no', padding = 'no', color = 'primary-blue' }: BasicProps): string =>
  style({
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '140%',
    fontStyle: 'normal',
    ...getBasicStyles(color, margin, padding),
  })

interface LinkProps extends BasicProps {
  navLink: boolean
  weight: FontWeight
}

export const a = ({
  margin = 'no',
  padding = 'no',
  color = 'primary-blue',
  weight = 'bold',
  navLink,
}: LinkProps): string =>
  style({
    fontWeight: getFontWeight(weight),
    fontSize: '12px',
    textDecoration: navLink ? 'none' : 'underline',
    lineHeight: '150%',
    fontStyle: 'normal',
    ...getBasicStyles(color, margin, padding),
  })

export const label = ({ margin = 'no', padding = 'no', color = 'primary-blue' }: BasicProps): string =>
  style({
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '120%',
    ...getBasicStyles(color, margin, padding),
  })

export const caption = ({ margin = 'no', padding = 'no', color = 'primary-blue' }: BasicProps): string =>
  style({
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '120%',
    textTransform: 'uppercase',
    ...getBasicStyles(color, margin, padding),
  })

export const disabledText = style({
  color: getColor('secondary-gray'),
})

export const successText = style({
  color: getColor('primary-green'),
})

export const dangerText = style({
  color: `${getColor('primary-red')} !important`,
})

export const linkText = style({
  color: getColor('primary-green'),
})

export const padding20 = style({
  padding: '20px',
})

export const hidden = style({
  display: 'none',
})
