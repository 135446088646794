
import { defineComponent, PropType } from 'vue'
import { useField } from 'vee-validate'
import { dangerText, checkboxStyle as css, formError } from '@/components/ui/styles'
import { Sizes } from '@/utils'

export default defineComponent({
  name: 'BckCheckbox',
  props: {
    // Initial value for single checkboxes
    checked: {
      type: Boolean,
      default: false,
    },
    // Initial value for single input when in a checkbox group
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: String as PropType<Sizes>,
      required: false,
      default: 'no',
    },
  },
  emits: ['change'],

  setup(props, { emit }) {
    const {
      handleBlur,
      handleChange,
      checked: inputIsChecked,
      value: stateValue,
    } = useField(props.name, undefined, {
      value: props.value,
      initialValue: false,
      checkedValue: props.value || true,
      uncheckedValue: false,
      type: 'checkbox',
    })

    const handleInputChange = ($event: Event) => {
      handleChange($event)
      emit('change')
    }

    return {
      formError,
      inputIsChecked,
      dangerText,
      css,
      handleChange,
      handleBlur,
      handleInputChange,
      stateValue,
    }
  },
})
