import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

const card = (selected: boolean, image?: string): string =>
  style({
    background: image ? `url("${image}") no-repeat center center` : 'initial',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: sizeMapper('xxSmall'),
    position: 'relative',
    width: '89px',
    height: '52px',
    border: selected
      ? `3px solid ${getColor('primary-blue')}`
      : `1px solid ${getColor('secondary-gray')}`,
    boxSizing: 'border-box',
    borderRadius: '8px',
    fontSize: '24px',
    color: getColor('secondary-gray'),
    fontWeight: 400,
    cursor: 'pointer',
    flexShrink: 0,
  })

const deleteIcon = style({
  position: 'absolute',
  cursor: 'pointer',
  top: '-9px',
  right: '-5px',
  zIndex: 10,
  boxSizing: 'content-box',
  boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
  border: `5px solid ${getColor('white')}`,
  borderRadius: '50%',
  background: getColor('white'),
})

const name = style({
  position: 'absolute',
  bottom: '-17px',
  width: '100%',
  textAlign: 'center',
  fontSize: '10px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: getColor('primary-blue'),
  lineHeight: 'normal',
})

export default {
  card,
  deleteIcon,
  name,
}
