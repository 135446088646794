import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'

export const formError = style({
  display: 'block',
  margin: sizeMapper('xxSmall no'),
  fontSize: '12px',
  textAlign: 'left',
  color: getColor('primary-red'),
})

export const formErrorSpacingPlaceholder = style({
  display: 'block',
  height: '22px',
})

export const selectItemError = style({
  display: 'block',
  paddingLeft: '10px',
  textAlign: 'left',
  fontSize: '12px',
  lineHeight: '18px',
  color: getColor('primary-red'),
})
