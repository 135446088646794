import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelperBubble = _resolveComponent("HelperBubble")!
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_Disclaimer = _resolveComponent("Disclaimer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.offerCardStyle.content)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.cardClass)
      }, [
        (_ctx.showTemplateName)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.offerCardStyle.templateName)
            }, _toDisplayString(_ctx.OFFER_TEMPLATES[_ctx.templateKey].templateName), 3))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.selectedImageClass)
            }, [
              (_ctx.showHelpers)
                ? (_openBlock(), _createBlock(_component_HelperBubble, {
                    key: 0,
                    "bubble-text": "Image",
                    active: !!_ctx.offer?.image,
                    left: "-141px",
                    top: "7%"
                  }, null, 8, ["active"]))
                : _createCommentVNode("", true)
            ], 2)),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.cardContentClass)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.titleClass)
          }, [
            _createTextVNode(_toDisplayString(_ctx.offer?.name ? _ctx.offer.name : 'TITLE MAX 36 CHARACTERS') + " ", 1),
            (_ctx.showHelpers)
              ? (_openBlock(), _createBlock(_component_HelperBubble, {
                  key: 0,
                  "bubble-text": "Title",
                  active: !!_ctx.offer?.name,
                  left: "-141px",
                  top: _ctx.cardDesign === 'e2eCard' ? '-72px' : '-10px'
                }, null, 8, ["active", "top"]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.cardDesign !== 'e2eCard')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.descriptionWrapperClass)
              }, [
                _createElementVNode("ul", {
                  class: _normalizeClass(_ctx.descriptionListClass)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.descriptionItems, (descriptionItem, i) => {
                    return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(descriptionItem), 1))
                  }), 128))
                ], 2),
                (_ctx.showHelpers)
                  ? (_openBlock(), _createBlock(_component_HelperBubble, {
                      key: 0,
                      "bubble-text": "Description",
                      active: !!_ctx.offer.description,
                      left: "-194px",
                      top: "10%"
                    }, null, 8, ["active"]))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.buttonsWrapperClass)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardButtons, (button) => {
              return (_openBlock(), _createElementBlock("button", {
                key: button.id,
                class: _normalizeClass(_ctx.buttonClass(button))
              }, [
                (button.appearance === 'whatsapp')
                  ? (_openBlock(), _createBlock(_component_BckIcon, {
                      key: 0,
                      "icon-name": "whatsappShare",
                      height: "15",
                      width: "15",
                      "view-box": "0 0 15 15"
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.getButtonName(button)) + " ", 1),
                (_ctx.showHelpers && button.appearance !== 'whatsapp')
                  ? (_openBlock(), _createBlock(_component_HelperBubble, _normalizeProps(_mergeProps({ key: 1 }, _ctx.helperButtonSettings(button, _ctx.cardDesign === 'e2eCard'))), null, 16))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ], 2)
        ], 2)
      ], 2),
      (_ctx.cardDesign === 'e2eCard')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.descriptionWrapperClass)
          }, [
            _createElementVNode("ul", {
              class: _normalizeClass(_ctx.descriptionListClass)
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.descriptionItems, (descriptionItem, i) => {
                return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(descriptionItem), 1))
              }), 128))
            ], 2),
            (_ctx.showHelpers)
              ? (_openBlock(), _createBlock(_component_HelperBubble, {
                  key: 0,
                  "bubble-text": "Description",
                  active: !!_ctx.offer.description,
                  left: "-179px",
                  top: "25%"
                }, null, 8, ["active"]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showHelpers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Disclaimer, { offerCase: "" })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}