
import { defineComponent, PropType } from 'vue'
import { Color, Sizes, FontWeight } from '@/utils'
import { a } from '@/components/ui/styles'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'BckLink',
  props: {
    to: {
      type: [Object, String] as PropType<RouteLocationRaw | string>,
      default: () => {
        return '#'
      },
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    padding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    color: {
      type: String as PropType<Color>,
      default: 'primary-blue',
    },
    navLink: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '',
    },
    weight: {
      type: String as PropType<FontWeight>,
      default: 'bold',
    },
  },

  setup(props) {
    const className = a({
      margin: props.margin,
      padding: props.padding,
      color: props.color,
      navLink: props.navLink,
      weight: props.weight,
    })
    return { className }
  },
})
