import { Color, getColor, sizeMapper, Sizes } from '@/utils'
import { style } from 'typestyle'
import { NestedCSSProperties } from 'typestyle/lib/types'

export type FunnelCardType =
  | 'large'
  | 'medium'
  | 'small'
  | 'xLarge'
  | 'flexible'

interface FunnelProps {
  type: FunnelCardType
  margin: Sizes
  padding: Sizes
  color?: Color
  active?: boolean
  clickable?: boolean
  focused?: boolean
}

export default ({
  type,
  margin,
  padding,
  color = 'primary-navy',
  active = false,
  clickable = false,
  focused = false,
}: FunnelProps): string =>
  style({
    margin: sizeMapper(margin),
    padding: sizeMapper(padding),
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '120%',
    background: active ? getColor('primary-blue') : getColor('white'),
    color: active ? getColor('white') : getColor(color),
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    border: `2px solid ${focused ? getColor('primary-blue') : 'transparent'}`,
    cursor: clickable ? 'pointer' : 'auto',
    ...getStylesForType(type),
  })

const getStylesForType = (
  type: FunnelCardType
): NestedCSSProperties | undefined => {
  switch (type) {
    case 'xLarge':
      return {
        width: '100%',
        height: '66px',
        justifyContent: 'flex-start',
        borderRadius: '10px',
        fontSize: '12px',
      }

    case 'large':
      return {
        justifyContent: 'space-around',
        height: '62px',
        borderRadius: '10px',
        fontSize: '16px',
      }

    case 'medium':
      return {
        textAlign: 'left',
        width: '280px',
        height: '66px',
        fontSize: '15px',
        lineHeight: '18px',
      }

    case 'small':
      return {
        textAlign: 'center',
        width: '269px',
        height: '52px',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.1em',
      }

    case 'flexible':
      return {
        display: 'block',
        width: '96%',
        fontSize: '10px',
        lineHeight: '16px',
      }
  }
}
