
import { computed, defineComponent, ref, watch } from 'vue'
import { DatePicker } from 'v-calendar'
import { useRoute } from 'vue-router'
import { subDays, setMonth, subYears, setDate, format } from 'date-fns'
import { calendarStyle as css } from '@/components/ui/styles'
import 'v-calendar/dist/style.css'

export default defineComponent({
  name: 'BckCalendar',
  components: { DatePicker },
  props: {
    startDate: {
      type: Date || String || undefined,
      default: new Date(1990, 0, 1),
    },
    endDate: {
      type: Date || String || undefined,
      default: new Date(),
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
    hasButtons: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    resetDateInputByRouteChange: {
      type: String,
      default: undefined,
    },
    useUrlQuery: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Created',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    // V-Calendar external library is not typed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const datepick = ref<InstanceType<any>>()
    const route = useRoute()

    const initialRouteValue = ref(
      route.query[props.resetDateInputByRouteChange || '']
    )

    const initDate = ref({
      startDate: props.useUrlQuery
        ? (route.query.startDate as string)
        : new Date(1990, 0, 1),
      endDate: props.useUrlQuery ? (route.query.endDate as string) : new Date(),
    })

    const range = ref({
      start: props.startDate ? props.startDate : initDate.value.startDate,
      end: props.endDate ? props.endDate : initDate.value.endDate,
    })

    const selectDates = (period: string) => {
      const today = new Date()
      switch (period) {
        case 'lifetime':
          range.value.start = new Date(1990, 0, 1)
          range.value.end = today
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        case 'today':
          range.value.start = today
          range.value.end = today
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        case 'yesterday':
          range.value.start = subDays(today, 1)
          range.value.end = subDays(today, 1)
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        case 'last-7-days':
          range.value.start = subDays(today, 7)
          range.value.end = today
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        case 'last-14-days':
          range.value.start = subDays(today, 14)
          range.value.end = today
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        case 'this-month':
          range.value.start = setDate(today, 1)
          range.value.end = today
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        case 'this-year':
          range.value.start = setDate(setMonth(today, 0), 1)
          range.value.end = today
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        case 'last-year':
          range.value.start = setDate(setMonth(subYears(today, 1), 0), 1)
          range.value.end = setDate(setMonth(subYears(today, 1), 11), 31)
          datepick.value.updateValue(range.value)
          datepick.value.move(range.value.end)
          break
        default:
          return false
      }
    }

    const resetDateInput = () => {
      if (
        route.query[props.resetDateInputByRouteChange || ''] !==
        initialRouteValue.value
      ) {
        range.value.start = initDate.value.startDate
        range.value.end = initDate.value.endDate
        datepick.value.updateValue(range.value)
        emit('change', range.value)
        initialRouteValue.value =
          route.query[props.resetDateInputByRouteChange || '']
      }
    }

    const handleDateInputChange = () => {
      emit('change', range.value)
    }
    watch(route, resetDateInput)
    watch(range, handleDateInputChange)

    const dateInput = computed(() => {
      if (!range.value?.start || !range.value?.end) {
        return ''
      }

      return (
        `${props.placeholder}: ` +
        format(new Date(range.value.start), 'dd/MM') +
        ' - ' +
        format(new Date(range.value.end), 'dd/MM')
      )
    })

    return {
      range,
      datepick,
      dateInput,
      selectDates,
      format,
      resetDateInput,
      initialRouteValue,
      masks: {
        title: 'MMMM',
        weekdays: 'WWW',
      },
      css,
    }
  },
})
