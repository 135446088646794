
import { defineComponent, PropType } from 'vue'
import { Color, Sizes } from '@/utils'
import { label } from '@/components/ui/styles'

export default defineComponent({
  name: 'BckLabel',
  props: {
    forName: {
      type: String,
      default: '',
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    padding: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    color: {
      type: String as PropType<Color>,
      default: 'primary-navy',
    },
  },

  setup(props) {
    const className = label({
      margin: props.margin,
      padding: props.padding,
      color: props.color,
    })
    return { className }
  },
})
