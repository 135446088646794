import { getColor } from '@/utils'
import { style, classes } from 'typestyle'
import { loadingOverlay } from '@/components/ui/styles/BckLoading'

interface TableStyleInterface {
  tableContainer: string
  tableHead: string
  tableRow: ({ isHeader }: { isHeader?: boolean }) => string
  tableCell: (width: string) => string
  tableHeadCell: ({
    width,
    sortable,
    isSortedBy,
    sortOrder,
  }: {
    width: string
    sortable: boolean
    isSortedBy: boolean
    sortOrder: string
  }) => string
  tableBody: string
  tablePanel: ({
    isLoading,
    height,
  }: {
    isLoading: boolean
    height?: string
  }) => string
}

const tableContainer = style({
  width: '100%',
  height: 'calc(100% - 45px)',
  backgroundColor: getColor('white'),
  paddingTop: '10px',
  $nest: {
    td: {
      padding: '8px 20px 8px 26px',
      wordWrap: 'break-word',
      textAlign: 'left',
    },
  },
})

const tableHead = style({
  backgroundColor: getColor('white'),
  position: 'sticky',
  top: 0,
  zIndex: 1000,
})

export const tableRow = ({ isHeader }: { isHeader?: boolean }): string =>
  style({
    display: 'flex',
    paddingLeft: '20px',
    position: 'relative',
    borderBottom: `1px solid ${getColor('primary-blue-10')}`,
    ...(isHeader && { border: 'none !important' }),

    $nest: {
      '&:last-child': {
        border: 'none !important',
      },
      '&:hover': {
        ...(!isHeader && {
          backgroundColor: getColor('primary-blue-10'),
          cursor: 'pointer',
        }),
      },
    },
  })

export const tableCell = (width: string): string =>
  style({
    display: 'flex',
    alignItems: 'center',
    minHeight: '35px',
    width,
    padding: '5px 10px',
    wordWrap: 'break-word',
    fontSize: '12px',
    lineHeight: '14px',
  })

const tableHeadCell = ({
  width,
  sortable,
  isSortedBy,
  sortOrder,
}: {
  width: string
  sortable: boolean
  isSortedBy: boolean
  sortOrder: string
}): string =>
  classes(
    tableCell(width),
    style({
      position: 'relative',
      minHeight: '45px',
      fontSize: '14px !important',
      lineHeight: '16px !important',
      paddingRight: '26px',
      ...(sortable && { cursor: 'pointer' }),

      ...(sortable && {
        background: !isSortedBy
          ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMSA2LjA1NSAzLjYzYTEgMSAwIDAgMS0xLjExIDBMMSAxIiBzdHJva2U9IiMxRTJFMzkiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=') right 10px center no-repeat"
          : sortOrder === 'ASC'
          ? "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMSA2LjA1NSAzLjYzYTEgMSAwIDAgMS0xLjExIDBMMSAxIiBzdHJva2U9IiMyRDdEQzAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=') right 10px center no-repeat"
          : "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSA0IDMuOTQ1LTIuNjNhMSAxIDAgMCAxIDEuMTEgMEwxMCA0IiBzdHJva2U9IiMyRDdEQzAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz48L3N2Zz4=') right 10px center no-repeat",
      }),
    })
  )

const tableBody = style({
  height: '100%',
  overflow: 'scroll',
})

export const containerOverlayTable = style({
  position: 'relative',
})

export const overlayTable = style({
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  bottom: 0,
  right: 0,
  top: 0,
  background: getColor('secondary-light-gray'),
  opacity: '0.5',
})

export const tablePanel = ({
  isLoading,
  height,
}: {
  isLoading: boolean
  height?: string
}): string =>
  style({
    position: 'relative',
    backgroundColor: getColor('white'),
    width: '100%',
    height: height || '85%',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    overflow: 'hidden',
    ...(isLoading && loadingOverlay),
  })

export const TableStyle: TableStyleInterface = {
  tablePanel,
  tableHead,
  tableContainer,
  tableCell,
  tableRow,
  tableHeadCell,
  tableBody,
}

export default {
  tableContainer,
  tableHead,
  tableRow,
  tableCell,
  tableHeadCell,
  tableBody,
}
