import { style } from 'typestyle'
import { getColor } from '@/utils'

const calendarStyle = (isExpanded: boolean): string =>
  style({
    border: 'none !important',
    zIndex: 1000,
    $nest: {
      '.vc-title': {
        fontSize: '16px',
        fontWeight: isExpanded ? 500 : 700,
        color: isExpanded ? getColor() : getColor('primary-blue'),
      },
      '.vc-svg-icon': {
        color: isExpanded ? getColor() : getColor('primary-blue'),
      },
      '.vc-popover-content-wrapper': {
        zIndex: 1005,
      },
      '.vc-container': {
        backgroundColor: getColor('white'),
      },
      '.vc-weekday': {
        fontSize: '14px',
        lineHeight: '17px',
        color: getColor(),
        textTransform: 'uppercase',
      },
    },
  })

const gridBorders = style({
  borderTop: `1px solid ${getColor('secondary-light-gray')}`,
  borderRight: `1px solid ${getColor('secondary-light-gray')}`,
})

export default {
  calendarStyle,
  gridBorders,
}
