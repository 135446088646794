import { withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id", "name", "placeholder", "value", "disabled", "type", "maxlength"]
const _hoisted_2 = ["for", "title", "data-title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.inputWrapper(_ctx.disabled, !!_ctx.inputValue, _ctx.fullWidth, _ctx.alignLeft, '5px', _ctx.margin))
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      ref: "inputRef",
      name: _ctx.name,
      placeholder: (!!_ctx.noBorder || !!_ctx.noLabel) && _ctx.placeholder ? _ctx.placeholder : '',
      class: _normalizeClass([_ctx.css.className, _ctx.errorMessage && _ctx.meta.touched && !_ctx.noError ? `${_ctx.css.dangerText}` : '']),
      value: _ctx.inputValue,
      disabled: _ctx.disabled,
      autocomplete: "off",
      type: _ctx.type,
      step: "0.01",
      maxlength: _ctx.maxCharacters || -1,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInputChange && _ctx.onInputChange(...args))),
      onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onKeypress && _ctx.onKeypress(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInputBlur && _ctx.onInputBlur(...args))),
      onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onInputEnter && _ctx.onInputEnter(...args)), ["enter"]))
    }, null, 42, _hoisted_1),
    _withDirectives(_createElementVNode("label", {
      for: _ctx.id,
      title: _ctx.placeholder,
      "data-title": _ctx.placeholder
    }, null, 8, _hoisted_2), [
      [_vShow, !_ctx.noBorder && !_ctx.noLabel]
    ]),
    (_ctx.maxCharacters > 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(['helperText', _ctx.css.helperTextStyle()])
        }, _toDisplayString(_ctx.helperText), 3))
      : _createCommentVNode("", true),
    (_ctx.errorMessage && !_ctx.meta.valid && !_ctx.noError && !_ctx.showForTheFirstTime)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(_ctx.css.formError)
        }, _toDisplayString(_ctx.errorMessage), 3))
      : (_ctx.maxCharsError)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(_ctx.css.formError)
          }, _toDisplayString(_ctx.maxCharsError), 3))
        : (_ctx.withSpacing)
          ? (_openBlock(), _createElementBlock("span", {
              key: 3,
              class: _normalizeClass(_ctx.css.formErrorSpacingPlaceholder)
            }, null, 2))
          : _createCommentVNode("", true)
  ], 2))
}