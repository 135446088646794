
import { defineComponent } from 'vue'
import { disclaimerStyle } from './styles'

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: true,
      required: false,
    },
    offerCase: {
      type: Boolean,
      default: false,
      required: false,
    },
    entityLabel: {
      type: String,
      default: 'funnel',
    }
  },
  setup() {
    return {
      disclaimerStyle,
    }
  },
})
