import { getColor, sizeMapper, Sizes } from '@/utils'
import { style } from 'typestyle'

export const margin = (size: Sizes): string =>
  style({
    margin: sizeMapper(size),
  })

export const smallDeleteButton = style({
  width: '30px',
  height: '30px',
  cursor: 'pointer',
  borderRadius: '3px',
  backgroundColor: getColor('primary-red'),
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQgMi4yODZ2LS44NTdBLjQyOS40MjkgMCAwIDEgNC40MjkgMUg3YS40MjkuNDI5IDAgMCAxIC40MjkuNDI5di44NTdNMSAyLjcxNGg5LjQyOSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTAgMy41NzFIMS40NUEuNDI5LjQyOSAwIDAgMCAxIDRsLjkgOC4xNDNhLjg1Ny44NTcgMCAwIDAgLjg5Ni44NTdoNS44MzdhLjg1Ni44NTYgMCAwIDAgLjg5Ni0uODU3bC45LTguMTQzQS40MjkuNDI5IDAgMCAwIDEwIDMuNTdaTTQuNDMgMTAuODU3YS40MjkuNDI5IDAgMCAxLS44NTggMFY2LjU3YS40MjkuNDI5IDAgMSAxIC44NTggMHY0LjI4NlptMy40MjggMGEuNDI5LjQyOSAwIDAgMS0uODU3IDBWNi41N2EuNDI5LjQyOSAwIDAgMSAuODU3IDB2NC4yODZaIiBmaWxsPSIjZmZmIi8+PC9zdmc+') right 10px center no-repeat",
})

export const deleteButton = style({
  cursor: 'pointer',
  width: '50px',
  height: '45px',
  backgroundColor: getColor('primary-red'),
  borderRadius: '5px',
  margin: '10px',
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuMDkgMy4xODJWMS43MjdBLjcyNy43MjcgMCAwIDEgNi44MTcgMWg0LjM2NGEuNzI3LjcyNyAwIDAgMSAuNzI3LjcyN3YxLjQ1NU0xIDMuOTFoMTYiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTE2LjI3MyA1LjM2M0gxLjc2M0EuNzI3LjcyNyAwIDAgMCAxIDYuMDlMMi41MjcgMTkuOTFhMS40NTQgMS40NTQgMCAwIDAgMS41MiAxLjQ1NGg5LjkwNmExLjQ1NCAxLjQ1NCAwIDAgMCAxLjUyLTEuNDU0TDE3IDYuMDlhLjcyNy43MjcgMCAwIDAtLjcyNy0uNzI3Wk02LjgxOCAxNy43MjdhLjcyNy43MjcgMCAwIDEtMS40NTQgMHYtNy4yNzNhLjcyNy43MjcgMCAwIDEgMS40NTQgMHY3LjI3M1ptNS44MTggMGEuNzI4LjcyOCAwIDAgMS0xLjQ1NCAwdi03LjI3M2EuNzI3LjcyNyAwIDEgMSAxLjQ1NCAwdjcuMjczWiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==') center no-repeat",
})

export const copyButton = style({
  cursor: 'pointer',
  width: '50px',
  height: '45px',
  backgroundColor: getColor('primary-blue'),
  borderRadius: '5px',
  marginRight: '10px',
  background:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIxLjI1NSAyMi4xMUg4Ljc5N2ExLjUzNSAxLjUzNSAwIDAgMS0xLjUzMS0xLjU1M1Y4LjA3NmExLjUzIDEuNTMgMCAwIDEgMS41My0xLjUzMmgxMi40ODJjLjg1OCAwIDEuNTU0LjY5NiAxLjU1NCAxLjUzMlYyMC41OGExLjU3IDEuNTcgMCAwIDEtMS41NzcgMS41M1oiIGZpbGw9IiNmZmYiIHN0cm9rZT0iI2ZmZiIvPjxwYXRoIGQ9Ik00LjczNSAxNi41OUgyLjYwMUMxLjcxOSAxNi41OSAxIDE1Ljg3IDEgMTQuOTg5VjIuNjAxQzEgMS43MTkgMS43MiAxIDIuNiAxaDEyLjM2NmMuODgxIDAgMS42LjcyIDEuNiAxLjZ2MS45MjZhLjQ2NS40NjUgMCAwIDEtLjQ2My40NjQuNDY1LjQ2NSAwIDAgMS0uNDY0LS40NjRWMi42MDFhLjY3My42NzMgMCAwIDAtLjY3My0uNjczSDIuNjAxYS42NzMuNjczIDAgMCAwLS42NzMuNjczdjEyLjM4OGMwIC4zNzEuMzAyLjY3My42NzMuNjczaDIuMTM0YS40NjUuNDY1IDAgMCAxIDAgLjkyOFoiIGZpbGw9IiNmZmYiIHN0cm9rZT0iI2ZmZiIvPjwvc3ZnPg==') center no-repeat",
})

export const noDataMessage = style({
  textAlign: 'center',
  color: getColor('secondary-gray'),
  fontSize: '14px',
  padding: sizeMapper('lMedium'),
})

export const marginAuto = style({
  margin: 'auto',
})

export const longText = style({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})
