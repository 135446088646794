import { getColor } from '@/utils'
import { style } from 'typestyle'

const pwaConfigurationInput = style({
  marginTop: '20px',
  $nest: {
    input: {
      borderColor: getColor('secondary-gray'),
    },
  },
})

const toggleContainer = style({
  display: 'flex',
  width: '480px',
  justifyContent: 'space-between',
})

const toggle = style({
  display: 'flex',
  alignItems: 'center',
})

export default { pwaConfigurationInput, toggleContainer, toggle }
