import { ActionContext, ActionTree } from 'vuex'
import { RootState } from '@/store'
import { AuthModuleState } from '@/store/modules/auth/state'

interface Actions<C = ActionContext<AuthModuleState, RootState>> {
  setIsLoggedIn(commit: C, isLoggedIn: boolean): void
}

export const actions: ActionTree<AuthModuleState, RootState> & Actions = {
  setIsLoggedIn({ commit }, isLoggedIn) {
    commit('SET_IS_LOGGED_IN', isLoggedIn)
  },
}
