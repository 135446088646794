import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["id", "name", "value", "disabled", "readonly", "placeholder"]
const _hoisted_2 = ["for", "title", "data-title"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckText = _resolveComponent("BckText")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.css.selectWrapper(!!_ctx.searchInputValue, _ctx.withArrow, _ctx.placeholder !== ''))
  }, [
    _createElementVNode("input", {
      id: _ctx.id,
      name: _ctx.name,
      class: _normalizeClass([_ctx.css.longText, _ctx.cssClass, _ctx.inputClass]),
      value: _ctx.searchInputValue,
      disabled: _ctx.disabled,
      readonly: !_ctx.searchable,
      placeholder: _ctx.noLabel ? _ctx.placeholder : '',
      autocomplete: "off",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateSearchKeyword && _ctx.updateSearchKeyword(...args))),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openOverlay && _ctx.openOverlay(...args))),
      onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openOverlay && _ctx.openOverlay(...args)))
    }, null, 42, _hoisted_1),
    (!_ctx.noLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          title: _ctx.placeholder,
          "data-title": _ctx.placeholder
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.isOverlayOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.css.selectOverlay),
          onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onOverlayMouseDown && _ctx.onOverlayMouseDown(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              onClick: ($event: any) => (!option.unavailable ? _ctx.chooseOption(option.value) : _ctx.showUnavailableMessage(option.value))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.css.selectOption)
              }, [
                (_ctx.multiple && _ctx.isSelected(option.value))
                  ? (_openBlock(), _createBlock(_component_BckIcon, {
                      key: 0,
                      "view-box": "0 0 10 8",
                      "icon-name": "check",
                      fill: _ctx.getColor('primary-green'),
                      height: "8",
                      width: "10"
                    }, null, 8, ["fill"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass([
              _ctx.css.selectOptionText,
              option.unavailable ? `${_ctx.dangerText}` : '',
              _ctx.isSelected(option.value) ? `${_ctx.css.selectedOption}` : '',
            ])
                }, _toDisplayString(option.label), 3),
                (_ctx.$slots['item-right-content'])
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(_ctx.css.itemRightContent)
                    }, [
                      _renderSlot(_ctx.$slots, "item-right-content", { option: option })
                    ], 2))
                  : _createCommentVNode("", true)
              ], 2),
              (option.unavailable && _ctx.unavailableMessage === option.value)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.css.selectItemError)
                  }, _toDisplayString(_ctx.slotErrorMessageText), 3))
                : _createCommentVNode("", true)
            ], 8, _hoisted_3))
          }), 128))
        ], 34))
      : _createCommentVNode("", true),
    ((!!_ctx.errorMessage || !_ctx.meta.valid) && !_ctx.noError)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: _normalizeClass(_ctx.formError)
        }, _toDisplayString(_ctx.errorMessage), 3))
      : (_ctx.withSpacing)
        ? (_openBlock(), _createElementBlock("span", {
            key: 3,
            class: _normalizeClass([_ctx.formErrorSpacingPlaceholder])
          }, null, 2))
        : _createCommentVNode("", true),
    (_ctx.multiple && _ctx.alwaysShowPreviewSpace && _ctx.inputValue.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(_ctx.css.previewSelected)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputValue, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item,
              class: _normalizeClass(_ctx.css.previewOptionGroup)
            }, [
              _createVNode(_component_BckText, {
                color: "primary-blue",
                margin: "no no xSmall no",
                weight: "bold",
                class: _normalizeClass(_ctx.css.longText)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getLabelForValue(item)), 1)
                ]),
                _: 2
              }, 1032, ["class"]),
              (_ctx.$slots['preview-right-content'])
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "preview-right-content", { item: item })
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}