import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(
      _ctx.funnelContentCard({
        type: _ctx.type,
        margin: _ctx.margin,
        padding: _ctx.padding,
        active: _ctx.active,
        color: _ctx.color,
        clickable: _ctx.clickable,
        focused: _ctx.focused,
      })
    )
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}