import sizeMapper, { Sizes } from '@/utils/sizeMapper'
import { NestedCSSProperties } from 'typestyle/lib/types'
import { Color, getColor } from '@/utils/colorMapper'

export { getColor } from './colorMapper'
export { default as mapper } from './mapper'
export { default as sizeMapper } from './sizeMapper'
export { default as mathematicalOperators } from './mathematicalOperators'
export {
  fadeAndSlideInUp,
  fadeIn,
  slideDown,
  slideUp,
  rotate,
} from './animations'
export { default as calculatePaginationBoundaries } from './calculatePaginationBoundaries'

// Export types
export type { Size, Sizes } from './sizeMapper'
export type { Color } from './colorMapper'
export type TextSize = 'small' | 'regular' | 'large'
export type FontWeight = 'normal' | 'bold'

export const getBasicStyles = (
  color: Color,
  margin: Sizes,
  padding: Sizes
): NestedCSSProperties => {
  return {
    color: getColor(color),
    margin: sizeMapper(margin),
    padding: sizeMapper(padding),
  }
}
