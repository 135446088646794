import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["viewBox", "fill", "stroke", "opacity", "height", "width", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: _ctx.viewBox,
    fill: _ctx.fill,
    stroke: _ctx.stroke,
    opacity: _ctx.opacity,
    preserveAspectRatio: "xMidYMid meet",
    xmlns: "http://www.w3.org/2000/svg",
    class: _normalizeClass(_ctx.className),
    height: _ctx.height,
    width: _ctx.width,
    innerHTML: _ctx.getIcon(_ctx.iconName)
  }, null, 10, _hoisted_1))
}