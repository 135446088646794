import apolloClient from '@/helpers/apolloClient'
import { PROJECT_BY_ID_SETTINGS } from '@/graphqlBackOffice/projects'
import { omit, reduce } from 'lodash'
import { Ref, ref } from 'vue'
import { ProjectSettings } from '@/types/models'

interface UseProjectRestrictedRoutesComposable {
  projectSettingsProgress: Ref<ProjectSettings | undefined>
  allowConfigurationAccess: Ref<boolean>
  configurationComplete: Ref<boolean>
  funnelOnly: Ref<boolean>
}

export const useProjectRestrictedRoutes = (
  projectId: string
): UseProjectRestrictedRoutesComposable => {
  const projectSettingsProgress = ref(undefined)
  const allowConfigurationAccess = ref()
  const configurationComplete = ref()
  const funnelOnly = ref()

  apolloClient
    .watchQuery({
      query: PROJECT_BY_ID_SETTINGS,
      variables: { projectId: projectId },
    })
    .subscribe(({ data }) => {
      projectSettingsProgress.value = data.project?.settings
      allowConfigurationAccess.value = reduce(
        omit(data.project?.settings, ['__typename', 'configuration']),
        (res, value) => res && !!value,
        true
      )
      configurationComplete.value = reduce(
        omit(data.project?.settings, ['__typename']),
        (res, value) => res && !!value,
        true
      )
      funnelOnly.value = !!data.project?.branding?.funnelOnly
    })

  return {
    projectSettingsProgress,
    allowConfigurationAccess,
    configurationComplete,
    funnelOnly
  }
}
