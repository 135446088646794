import { style } from 'typestyle'
import { Property } from 'csstype'
import { sizeMapper, mapper, Sizes } from '@/utils'

interface GridColumnType {
  margin?: Sizes
  padding?: Sizes
  align?: Property.TextAlign
  size?: string
  width?: string
  shift?: string
}

const COLUMN_SIZES = {
  '1/12': '8.33%',
  '2/12': '16.66%',
  '3/12': '25%',
  '4/12': '33.33%',
  '5/12': '41.66%',
  '6/12': '50%',
  '7/12': '58.33%',
  '8/12': '66.66%',
  '9/12': '75%',
  '10/12': '83.33%',
  '11/12': '91.66%',
  '12/12': '100%',
}

export default ({
  padding = 'no xSmall',
  align,
  size,
  width,
  shift,
}: GridColumnType): string =>
  style({
    position: 'relative',
    textAlign: align || 'left',
    padding: sizeMapper(padding),
    ...(size && { width: mapper(size, COLUMN_SIZES) }),
    ...(width && { width }),
    ...(shift && { marginLeft: mapper(shift, COLUMN_SIZES) }),
  })
