
import { defineComponent } from 'vue'
import { textEditorBarStyle as css } from './styles'
import {
  RiBold,
  RiItalic,
  RiStrikethrough,
  RiH1,
  RiH2,
  RiParagraph,
  RiListUnordered,
  RiListOrdered,
  RiFormatClear,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
} from 'vue-remix-icons'

export default defineComponent({
  props: {
    editor: {
      type: Object,
      required: true,
    },
    fontSize: {
      type: String,
      default: '10px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['change-font-size'],

  setup(props) {
    return {
      css,
      fontOptions: [
        '2px',
        '4px',
        '6px',
        '8px',
        '10px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '22px',
        '24px',
        '26px',
        '28px',
        '30px',
        '32px',
        '34px',
        '36px',
        '38px',
        '40px',
      ],
      items: [
        {
          icon: RiBold,
          title: 'Bold',
          action: () => props.editor.chain().focus().toggleBold().run(),
          isActive: () => props.editor.isActive('bold'),
        },
        {
          icon: RiItalic,
          title: 'Italic',
          action: () => props.editor.chain().focus().toggleItalic().run(),
          isActive: () => props.editor.isActive('italic'),
        },
        {
          icon: RiStrikethrough,
          title: 'Strike',
          action: () => props.editor.chain().focus().toggleStrike().run(),
          isActive: () => props.editor.isActive('strike'),
        },
        {
          type: 'divider',
        },
        {
          type: 'font-size',
        },
        {
          type: 'divider',
        },
        {
          icon: RiH1,
          title: 'Heading 1',
          action: () =>
            props.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: () => props.editor.isActive('heading', { level: 4 }),
        },
        {
          icon: RiH2,
          title: 'Heading 2',
          action: () =>
            props.editor.chain().focus().toggleHeading({ level: 5 }).run(),
          isActive: () => props.editor.isActive('heading', { level: 5 }),
        },
        {
          icon: RiParagraph,
          title: 'Paragraph',
          action: () => props.editor.chain().focus().setParagraph().run(),
          isActive: () => props.editor.isActive('paragraph'),
        },
        {
          icon: RiListUnordered,
          title: 'Bullet List',
          action: () => props.editor.chain().focus().toggleBulletList().run(),
          isActive: () => props.editor.isActive('bulletList'),
        },
        {
          icon: RiListOrdered,
          title: 'Ordered List',
          action: () => props.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => props.editor.isActive('orderedList'),
        },
        {
          type: 'divider',
        },
        {
          icon: RiFormatClear,
          title: 'Clear Format',
          action: () =>
            props.editor.chain().focus().clearNodes().unsetAllMarks().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: RiArrowGoBackLine,
          title: 'Undo',
          action: () => props.editor.chain().focus().undo().run(),
        },
        {
          icon: RiArrowGoForwardLine,
          title: 'Redo',
          action: () => props.editor.chain().focus().redo().run(),
        },
      ],
    }
  },
})
