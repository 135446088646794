import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckH3 = _resolveComponent("BckH3")!
  const _component_BckText = _resolveComponent("BckText")!
  const _component_BckGridColumn = _resolveComponent("BckGridColumn")!
  const _component_ConfigurationBanner = _resolveComponent("ConfigurationBanner")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.pageContent(_ctx.disableContentCard ? '92%' : 'auto', _ctx.newPageStyle))
  }, [
    _createVNode(_component_BckGrid, {
      "justify-content": "space-between",
      "align-items": "end"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_BckGrid, { direction: "column" }, {
          default: _withCtx(() => [
            _createVNode(_component_BckGridColumn, { size: "6/12" }, {
              default: _withCtx(() => [
                _createVNode(_component_BckH3, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                }),
                (_ctx.subTitle)
                  ? (_openBlock(), _createBlock(_component_BckText, {
                      key: 0,
                      margin: "xSmall no no no",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.projectId && _ctx.configBanner)
              ? (_openBlock(), _createBlock(_component_ConfigurationBanner, {
                  key: 0,
                  "project-id": _ctx.projectId
                }, null, 8, ["project-id"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "headerButtons")
      ]),
      _: 3
    }),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.pageContentLoading(_ctx.isLoading))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.disableContentCard ? 'div' : 'BckContentCard'), {
        class: "main-content",
        margin: "small no",
        padding: "medium"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "content"),
          (_ctx.$slots.buttons)
            ? (_openBlock(), _createBlock(_component_BckGrid, {
                key: 0,
                "justify-content": "space-between",
                margin: "grande no no no"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "buttons")
                ]),
                _: 3
              }))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }))
    ], 2)
  ], 2))
}