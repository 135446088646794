
import { defineComponent, PropType } from 'vue'
import { OfferListItem } from '@/types/models'
import { offerListCardStyle, longText } from './styles'

export default defineComponent({
  props: {
    offer: {
      type: Object as PropType<OfferListItem>,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    showEnable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['change'],

  setup() {
    return { offerListCardStyle, css: { longText } }
  },
})
