import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'

export const CustomBulletList = BulletList.extend({
  addAttributes() {
    return {
      style: { default: 'margin: 10px 10px 10px 40px' },
    }
  },
})

export const CustomOrderedList = OrderedList.extend({
  addAttributes() {
    return {
      style: { default: 'margin: 10px 10px 10px 40px' },
    }
  },
})
