import gql from 'graphql-tag'

export const LOG_IN = gql`
  mutation LoginMutation($email: String!, $password: String) {
    login(email: $email, password: $password) {
      user {
        _id
        email
        position
        role
        status
        projectIds
        username
        localization
        projectRoles {
          projectId
          role
        }
      }
      token
      refreshToken
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshTokenMutation($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
    }
  }
`

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $name: String!
    $email: String!
    $password: String!
    $token: String!
    $country: String!
  ) {
    registerUser(
      name: $name
      email: $email
      password: $password
      token: $token
      country: $country
    ) {
      token
      refreshToken
      user {
        _id
        email
        position
        role
        status
        projectIds
        username
        localization
        projectRoles {
          projectId
          role
        }
      }
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $hash: String!, $email: String!) {
    resetPassword(password: $password, hash: $hash, email: $email) {
      success
    }
  }
`
