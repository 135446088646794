
import { defineComponent, PropType } from 'vue'
import { projectCardStyle as css } from '@/components/ui/styles'
import { Sizes } from '@/utils'

export default defineComponent({
  name: 'BckProjectCard',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: undefined,
    },
    margin: {
      type: String as PropType<Sizes>,
      default: 'no',
    },
    hasIssues: { type: Boolean, required: false, default: false },
  },

  setup() {
    return {
      css,
    }
  },
})
