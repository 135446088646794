import { style } from 'typestyle'

const disclaimerIcon = style({
  // marginLeft: '25px',
})

const disclaimerTxt = style({
  width: '220.535px',
  height: '29px',
  marginLeft: '12.6px',
})

export default { disclaimerTxt, disclaimerIcon }
