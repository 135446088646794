/* eslint-disable @typescript-eslint/no-explicit-any */
import { Ref } from 'vue'
import { getOperationName } from '@apollo/client/utilities'
import { DocumentNode } from 'graphql'
import { ApolloError } from '@apollo/client/errors'
import { useAction } from '@/graphqlBackOffice/composables'

interface Entity {
  [k: string]: string | number | boolean | null
}

interface Create {
  createEntity(newEntity: Entity): void
  loading: Ref<boolean>
  error?: Ref<ApolloError | null>
}

const create = (
  createGraphQLDoc: DocumentNode,
  fetchGraphQLDoc: DocumentNode
): Create => {
  const { mutate, loading, error } = useAction(
    createGraphQLDoc
    // { refetchQueries: [{ query: fetchGraphQLDoc }] }
  )

  const createEntity = (newEntity: Entity) => {
    mutate(
      {
        data: newEntity,
      },
      {
        update: (cache, returnValue) => {
          const mutationOperationName: string | null =
            getOperationName(createGraphQLDoc)
          const fetchOperationName: string | null =
            getOperationName(fetchGraphQLDoc)
          const data: any = cache.readQuery({
            query: fetchGraphQLDoc,
          })

          if (fetchOperationName && mutationOperationName) {
            cache.writeQuery({
              query: fetchGraphQLDoc,
              data: {
                [fetchOperationName]: [
                  ...data[fetchOperationName],
                  returnValue.data[mutationOperationName],
                ],
              },
            })
          }
        },
      }
    )
  }

  return { createEntity, loading, error }
}

export default create
