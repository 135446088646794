import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    class: _normalizeClass([_ctx.css.menuItem(_ctx.isActive())]),
    title: _ctx.title,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args)))
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { key: 0 }))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}