import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.projectCard),
    type: "button",
    disabled: _ctx.disabled
  }, [
    _createVNode(_component_BckIcon, {
      "icon-name": _ctx.iconName,
      "view-box": "0 0 70 65",
      fill: "none"
    }, null, 8, ["icon-name"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.textClassName)
    }, "Add New Project", 2)
  ], 10, _hoisted_1))
}