import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      id: _ctx.name,
      name: _ctx.name,
      class: _normalizeClass([_ctx.className, _ctx.errorMessage ? `${_ctx.dangerText}` : '']),
      value: _ctx.inputValue,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args)))
    }, null, 42, _hoisted_1),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(_ctx.formError)
    }, _toDisplayString(_ctx.errorMessage), 3), [
      [_vShow, !!_ctx.errorMessage || _ctx.meta.valid]
    ])
  ]))
}