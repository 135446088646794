import { style } from 'typestyle'
import seamlessPreviewImage from '@/assets/seamlessMobilePreview.png'
import defaultProjectBackground from '@/assets/defaultProjectBackground.png'
import { getColor } from '@/utils'
import {
  IconSize,
  OfferCardStyle,
  SeamlessPreviewSizes,
  SeamlessPreviewType,
  SeamlessPreviewButtonStyle,
} from '@/types/models'

const defaultSizes: SeamlessPreviewSizes = {
  fonts: {
    addressBar: '10px',
    content: '9px',
    title: 'large',
    tableItems: '10px',
    navBar: '9px',
  },
  headerIcons: {
    height: '28px',
    width: 'auto',
  },
  navBarIcons: {
    height: '24px',
    width: '24px',
  },
}

const container = (showHelpers: boolean, overflowCarousel: boolean, height: number, fontSize?: string): string =>
  style({
    fontSize: fontSize || defaultSizes.fonts.content,
    position: 'relative',
    height: `${height}px`,
    width: `${height * (930 / 1890)}px`,
    background: `url(${seamlessPreviewImage}) no-repeat center center`,
    backgroundSize: 'cover',
    ...(showHelpers || overflowCarousel ? { marginLeft: '175px' } : { margin: 'auto' }),
    color: getColor('black'),
    $nest: {
      '.helper-bubble': {
        display: showHelpers ? 'flex' : 'none',
      },
    },
  })

const wrapper = style({
  padding: '0 5.7% 0 16px',
  height: '100%',
  width: '100%',
})

export const headerPlaceholder = style({
  width: '100%',
  height: '8%',
})

export const addressBar = (fontSize?: string): string =>
  style({
    fontSize: fontSize || defaultSizes.fonts.addressBar,
    color: getColor('black'),
    height: '4%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1.5%',
  })

export const previewProjectBackgroundImage = (projectBackground?: string, flowBuilder?: boolean): string =>
  style({
    display: 'flex',
    flexDirection: 'column',
    height: flowBuilder ? '220px' : '524px',
    justifyContent: 'space-around',
    paddingBottom: '30px',
    background: projectBackground ? `url(${projectBackground})` : `url(${defaultProjectBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: flowBuilder ? 0 : '0px 0px 29.15px 29.15px',
  })

export const headerIcons = (iconSizes?: IconSize): string =>
  style({
    height: '27.73px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '3% 8%',
    $nest: {
      '.header-icon': {
        width: iconSizes?.width || defaultSizes.headerIcons.width,
        height: iconSizes?.height || defaultSizes.headerIcons.height,
        $nest: {
          '&.settings-icon': {
            paddingTop: '1.4%',
          },
        },
      },
    },
  })

const title = (highlightBackground: boolean, color: string, fontSize?: string): string =>
  style({
    position: 'relative',
    height: '7%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: fontSize || defaultSizes.fonts.title,
    lineHeight: '110%',
    textAlign: 'center',
    background: highlightBackground ? getColor('secondary-light-gray') : 'transparent',
    color: color && !highlightBackground ? getColor('black') : getColor('black'),
    zIndex: 2,
    padding: '2% 0',
  })

const description = style({
  position: 'relative',
  height: '8%',
  width: '100%',
  lineHeight: '110%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 5%',
  fontSize: '10px',
})

const offers = (mobileWidth: boolean): string =>
  style({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '48%',
    width: mobileWidth ? '100%' : '270%',
    overflowX: 'visible',
    transition: 'transform 150ms linear',
  })

const learnMorePage = style({
  height: '64.2%',
  marginBottom: '2%',
  overflow: 'scroll',
  fontSize: '10px',
  padding: '10px',
  $nest: {
    p: {
      margin: '0',
    },
  },
})

const offersWrapper = (
  type: SeamlessPreviewType,
  oneCard: boolean,
  offersMarginLeft: number,
  cardDesign: string
): string => {
  return style({
    display: 'flex',
    flexDirection: cardDesign === 'e2eCard' ? 'column' : 'row',
    justifyContent: oneCard ? 'center' : 'flex-start',
    width:
      type === 'carousel' || oneCard
        ? '100%'
        : cardDesign === 'e2eCard'
        ? '100%'
        : type == 'overflowCarousel'
        ? '270%'
        : '900%',
    height: 'auto',
    transition: 'transform 150ms linear',
    overflowX: oneCard ? 'unset' : 'scroll',
    scrollBehavior: 'smooth',
    $nest: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '.offer-card': {
        flexShrink: 0,
        $nest: {
          '&:first-child': {
            marginLeft: offersMarginLeft ? `${offersMarginLeft}px` : oneCard ? '0' : '7%',
          },
        },
      },
    },
  })
}

const offerCard = (offerCardStyle?: OfferCardStyle): string =>
  style({
    ...(offerCardStyle && {
      fontSize: offerCardStyle.fontSize + '!important',
      width: offerCardStyle.width + '!important',
      height: offerCardStyle.height + '!important',
      $nest: {
        div: {
          fontSize: offerCardStyle.fontSize + '!important',
        },
        button: {
          fontSize: offerCardStyle.fontSize + '!important',
          height: offerCardStyle.buttonHeight + '!important',
        },
        svg: {
          height: offerCardStyle.fontSize + '!important',
          width: offerCardStyle.fontSize + '!important',
        },
      },
    }),
  })

const tableItemsContainer = (fullHeight: boolean, fontSize?: string): string =>
  style({
    height: fullHeight ? '65.2%' : '58%',
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    overflow: 'auto',
    $nest: {
      ul: {
        fontSize: fontSize || defaultSizes.fonts.tableItems,
        lineHeight: '120%',
        listStyle: 'none',
        overflow: 'hidden',
        $nest: {
          li: {
            position: 'relative',
            paddingLeft: '2%',
          },
          'li::before': {
            position: 'absolute',
            left: '0',
            content: '"•"',
          },
        },
      },
    },
  })

const buttonsContainer = (buttonStyle: SeamlessPreviewButtonStyle, borderRadius: string): string =>
  style({
    backgroundColor: 'white',
    boxShadow: '0px -1px 1px #ccc',
    borderRadius: borderRadius || '0px 0px 29.15px 29.15px',
    height: '14.3%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    $nest: {
      button: {
        maxWidth: '90%',
        ...(buttonStyle?.fontSize && { fontSize: buttonStyle.fontSize }),
        lineHeight: '150%',
        borderRadius: '4px',
        color: getColor('black'),
        border: `0.7px solid ${getColor('secondary-light-gray')}`,
      },
    },
  })

const straightLine = (active: boolean): string =>
  style({
    width: '51px',
    height: '3px',
    backgroundColor: getColor(active ? 'primary-blue' : 'secondary-gray'),
  })

const showingDot = (active: boolean): string =>
  style({
    borderRadius: '50%',
    height: '12px',
    width: '12px',
    backgroundColor: getColor(active ? 'primary-blue' : 'secondary-gray'),
  })

const arrow = (direction: string): string =>
  style({
    height: '14%',
    width: '10%',
    borderRadius: '5px',
    position: 'absolute',
    background: `${getColor('primary-blue')}
     url(${
       direction === 'left'
         ? 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3IDEzLjVhMS41IDEuNSAwIDAgMCAwLTN2M1pNLjk0IDEwLjk0YTEuNSAxLjUgMCAwIDAgMCAyLjEybDkuNTQ1IDkuNTQ3YTEuNSAxLjUgMCAxIDAgMi4xMjItMi4xMjJMNC4xMiAxMmw4LjQ4Ni04LjQ4NWExLjUgMS41IDAgMSAwLTIuMTIyLTIuMTIyTC45NCAxMC45NFpNMTcgMTAuNUgydjNoMTV2LTNaIiBmaWxsPSIjZmZmIi8+PC9zdmc+'
         : 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIgMTAuNWExLjUgMS41IDAgMCAwIDAgM3YtM1ptMTYuMDYgMi41NmExLjUgMS41IDAgMCAwIDAtMi4xMkw4LjUxNiAxLjM5MmExLjUgMS41IDAgMSAwLTIuMTIyIDIuMTIyTDE0Ljg4IDEybC04LjQ4NiA4LjQ4NWExLjUgMS41IDAgMSAwIDIuMTIyIDIuMTIybDkuNTQ2LTkuNTQ2Wk0yIDEzLjVoMTV2LTNIMnYzWiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg=='
     })
     no-repeat center`,
    left: direction === 'left' ? '-35px' : '248%',
    top: '46%',
  })

const linearGradient = style({
  position: 'absolute',
  top: '31%',
  left: '232%',
  height: '47%',
  width: '14%',
  background: 'linear-gradient(270deg, rgba(128, 128, 128, 0.43) 0%, rgba(196, 196, 196, 0) 69.27%)',
})

const footer = style({
  height: '9.2%',
  color: getColor('secondary-gray'),
  lineHeight: '110%',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10%',
})

const navBar = (
  backgroundColor: string,
  menuItemsColor: string,
  fontSize?: string,
  iconSize?: IconSize,
  borderRadius?: string
): string =>
  style({
    left: 0,
    height: '7.7%',
    width: '100%',
    backgroundColor: backgroundColor || getColor('primary-blue'),
    boxShadow: '0 -5px 5px -5px rgba(0, 0, 0, 0.25)',
    border: '5px',
    borderRadius: borderRadius || '0px 0px 29.15px 29.15px',
    textAlign: 'center',
    color: menuItemsColor || getColor('white'),
    display: 'inline-flex',
    fontSize: fontSize || defaultSizes.fonts.navBar,
    marginBottom: '-13%',
    $nest: {
      '.nav-bar-icon': {
        height: iconSize?.height || defaultSizes.navBarIcons.height,
        width: iconSize?.width || defaultSizes.navBarIcons.width,
      },
    },
  })

const tab = style({
  display: 'inline-grid',
})

export default {
  previewProjectBackgroundImage,
  addressBar,
  arrow,
  buttonsContainer,
  container,
  description,
  footer,
  headerIcons,
  headerPlaceholder,
  linearGradient,
  offerCard,
  offers,
  offersWrapper,
  learnMorePage,
  navBar,
  showingDot,
  straightLine,
  tab,
  tableItemsContainer,
  title,
  wrapper,
}
