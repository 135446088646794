import {
  ApolloClient,
  InMemoryCache,
  from,
  fromPromise,
} from '@apollo/client/core'
import { createUploadLink } from 'apollo-upload-client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import router from '@/router'
import { store } from '@/store'
import apolloClient from '@/helpers/apolloClient'
import {
  removeUserCookies,
  updateAuthToken,
} from '@/graphqlBackOffice/auth/session'
import { setContext } from '@apollo/client/link/context'
import { getSessionValue } from '@/graphqlBackOffice/auth/session'
import { projectLocalMutations } from '@/graphqlBackOffice/projects/localCache'
import AuthService from '@/graphqlBackOffice/auth/service'

const httpLink = createUploadLink({
  uri: `${process.env.VUE_APP_API_ENDPOINT}/graphql`,
})

export const authLink = setContext((_, { headers }) => {
  const token = getSessionValue('authToken')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const logout = () => {
  store.dispatch('authModule/setIsLoggedIn', false)
  removeUserCookies()
  apolloClient.cache.reset()
  router.push({ path: '/login' })
}

export const errorHandler = onError(
  ({ networkError, operation, forward }: ErrorResponse) => {
    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 403
    ) {
      // TODO: replace with toast component
      alert('You are not authorized to perform this action.')
    }

    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      const refreshToken = getSessionValue('refreshToken')
      // Fetch a new auth token based on refresh token
      if (refreshToken) {
        const { refreshTokenMutation } = AuthService.refreshToken(refreshToken)

        return fromPromise(
          refreshTokenMutation
            .then((response) => {
              if (response) {
                updateAuthToken(response.data.refreshToken.token)
              }
            })
            .catch(() => {
              logout()
            })
        ).flatMap(() => {
          // retry the request
          return forward(operation)
        })
      } else {
        logout()
      }
    }
  }
)

export default new ApolloClient({
  link: from([errorHandler, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          projects: {
            merge(existing, incoming) {
              return incoming
            },
          },
          product: {
            merge(existing, incoming) {
              return incoming
            },
          },
          'campaign.funnels': {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  }),
  connectToDevTools: true,
  resolvers: {
    Mutation: { ...projectLocalMutations },
  },
})
