import { style } from 'typestyle'
import { getColor } from '@/utils'

const previewOffer = style({
  marginLeft: '40% !important',
  width: '310px',
})

const title = style({
  fontSize: '12px',
  fontWeight: '500',
  color: 'rgba(0, 0, 0, 0.80)',
  marginBottom: '12px',
})

const label = style({
  color: getColor('secondary-gray'),
  fontSize: '12px',
  fontWeight: '400',
  fontStyle: 'italic',
})

const vaultDataWrapper = style({
  display: 'flex',
  justifyContent: 'space-between',
  $nest: {
    svg: {
      marginBottom: '3px',
    },
  },
})

const vaultDataContentWrapper = style({
  marginTop: '24px',
})

const vaultDataToggle = style({
  width: '108px',
  height: '30px',
  borderRadius: '20px',
  border: '1px solid rgba(0, 0, 0, 0.50)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  cursor: 'pointer',
})

const vaultDataToggleText = style({
  fontSize: '12px',
  fontWeight: '400',
  color: 'rgba(0, 0, 0, 0.80)',
})

const systemTagWrapper = style({
  marginBottom: '25px',
})

const textWrapper = style({
  paddingLeft: '0.5rem',
  paddingTop: '8px',
})

export default {
  previewOffer,
  textWrapper,
  title,
  label,
  vaultDataWrapper,
  vaultDataToggle,
  vaultDataContentWrapper,
  vaultDataToggleText,
  systemTagWrapper,
}
