import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckLabel = _resolveComponent("BckLabel")!
  const _component_BckRadioButton = _resolveComponent("BckRadioButton")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createBlock(_component_BckLabel, {
          key: 0,
          for: _ctx.name
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ]),
          _: 1
        }, 8, ["for"]))
      : _createCommentVNode("", true),
    _createVNode(_component_BckGrid, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createBlock(_component_BckRadioButton, {
            key: option.value,
            disabled: _ctx.disabled,
            option: option,
            name: _ctx.name,
            icon: option?.icon,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event)))
          }, null, 8, ["disabled", "option", "name", "icon"]))
        }), 128))
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(_ctx.formError)
    }, _toDisplayString(_ctx.errorMessage), 3), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 64))
}