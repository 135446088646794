/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  provideApolloClient,
  useMutation,
  UseMutationOptions,
  UseMutationReturn,
} from '@vue/apollo-composable'
import { DocumentNode } from 'graphql'
import apolloClient from '@/helpers/apolloClient'
import { OperationVariables } from '@apollo/client'

export const useAction = <TResult = any>(
  graphQLDoc: DocumentNode,
  options?: UseMutationOptions<any, OperationVariables>
): UseMutationReturn<TResult, OperationVariables> => {
  provideApolloClient(apolloClient)
  return useMutation(graphQLDoc, options || {})
}
