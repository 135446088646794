import { getColor } from '@/utils'
import { style } from 'typestyle'

const container = (height: string): string =>
  style({
    margin: '10px 0px',
    border: `1px solid ${getColor('white')}`,
    minHeight: height,
    padding: '10px 20px 0px',
    borderRadius: '5px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  })

const toggleSwitch = style({
  alignSelf: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
})

const button = style({
  display: 'flex',
  justifyContent: 'flex-end',
})

export default { container, toggleSwitch, button }
