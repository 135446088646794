import { style } from 'typestyle'
import { getColor } from '@/utils'

const offerGroup = style({
  display: 'flex',
  flexDirection: 'column',
  $nest: {
    '.label': {
      color: getColor('secondary-gray'),
      fontSize: '10px',
    },
    '.value': {
      fontSize: '15px',
      color: getColor('primary-blue'),
    },
  },
})

const image = style({
  verticalAlign: 'bottom',
  width: '89px',
  height: '52px',
  objectFit: 'contain',
})

const checkbox = style({
  marginTop: '10px',
})

const editButton = style({
  cursor: 'pointer',
  height: '30px',
  width: '30px',
  backgroundColor: getColor('primary-blue'),
  borderRadius: '3px',
  background:
    'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExLjcwMiAxMS4zMjVhLjkwMi45MDIgMCAwIDEtLjg4Ni44N0gyLjY5MmEuOTAyLjkwMiAwIDAgMS0uODg2LS45MDRWMy4xNjdjMC0uNDg2LjM5NC0uODcuODg2LS44N0g3LjU2di0uOTMzSDIuNjkyYy0xLjAwMiAwLTEuODIuNzk4LTEuODIgMS44MDR2OC4xMjNjMCAxLjAwMy44MTQgMS44MzggMS44MiAxLjgzOGg4LjEyM2MxLjAwNiAwIDEuODIxLS44MzYgMS44MjEtMS44MzhWNi40NGgtLjkzNFYxMS4zMjVaIiBmaWxsPSIjZmZmIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iLjI1NyIvPjxwYXRoIGQ9Im01LjQzNCA3Ljk3Ny40OC0xLjkxNy4xMjQuMDMtLjYwNCAxLjg4N1ptMCAwYS40Ny40NyAwIDAgMCAuMTIzLjQ0NG0tLjEyMy0uNDQ0LjEyMy40NDRtMCAwYS40NjguNDY4IDAgMCAwIC4zMy4xMzdtLS4zMy0uMTM3LjMzLjEzN20wIDBhLjQ2LjQ2IDAgMCAwIC4xMTUtLjAxNG0tLjExNC4wMTQuMTE0LS4wMTRtMCAwIDEuOTItLjQ4YS40NjUuNDY1IDAgMCAwIC4yMTctLjEyMmw0LjU1Ni00LjU1Yy4yOC0uMjguNDM1LS42NTMuNDM1LTEuMDUgMC0uMzk2LS4xNTQtLjc2OC0uNDM1LTEuMDQ5TDYuMDAyIDguNTQ0Wm01LjIyLTUuMDAyTDcuNTcgNy4xODlsLTEuMDM4LjI2LjI1OS0xLjAzNyAzLjY1Mi0zLjY0Ny43NzkuNzc3Wm0uODEtLjgxLS4xNDguMTQ4LS43NzktLjc3Ny4xNS0uMTQ5YS41NjguNTY4IDAgMCAxIC43NzggMCAuNTQ0LjU0NCAwIDAgMSAuMTYxLjM4OS41NDQuNTQ0IDAgMCAxLS4xNjEuMzg4WiIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9Ii4yNTciLz48L3N2Zz4=) center no-repeat',
})

export default {
  offerGroup,
  checkbox,
  image,
  editButton,
}
