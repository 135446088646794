import gql from 'graphql-tag'


export const USER = gql`
  query GetUser($filter: FilterFindOneUserInput) {
    user(filter: $filter) {
      _id
      email
      position
      role
      status
      projectIds
      username
      localization
      projectRoles {
        projectId
        role
      }
    }
  }
`

export const USERS = gql`
  query GetUsers(
    $sort: SortFindManyUserInput
    $perPage: Int
    $page: Int
    $filter: FilterFindManyUserInput
  ) {
    users(sort: $sort, perPage: $perPage, page: $page, filter: $filter) {
      items {
        _id
        email
        username
        position
        country
        projectIds
        projectRoles {
          projectId
          role
        }
        role
        status
        localization
      }
      count
    }
  }
`

export const INVITATIONS = gql`
  query Query(
    $page: Int
    $filter: FilterFindManyUserInvitationInput
    $sort: SortFindManyUserInvitationInput
    $perPage: Int
  ) {
    invitations(page: $page, filter: $filter, sort: $sort, perPage: $perPage) {
      items {
        _id
        userEmail
        role
        status
        createdAt
        updatedAt
        projectIds
        projectRoles {
          projectId
          role
        }
      }
      count
    }
  }
`
