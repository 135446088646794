import { style } from 'typestyle'
import { getColor, sizeMapper } from '@/utils'
import oldCard from '@/assets/oldcard.png'
import newCard from '@/assets/newcard.png'
import e2eCard from '@/assets/e2eCard.png'
import oldCardDisabled from '@/assets/oldCardDisabled.png'
import newCardDisabled from '@/assets/newCardDisabled.png'
import e2eCardDisabled from '@/assets/e2eCardDisabled.png'
import landscapeCardDisalbled from '@/assets/landscapeDisabled.png'
import portraitCardDisabled from '@/assets/portraitDisabled.png'

export const form = style({
  maxWidth: '308px',
  margin: '0 auto !important',
})

export const tabName = (active: boolean): string =>
  style({
    fontSize: '14px',
    cursor: 'pointer',
    lineHeight: '30px',
    borderBottom: `1px solid ${getColor(active ? 'primary-blue' : 'secondary-gray')}`,
    color: active ? getColor('primary-blue') : getColor('secondary-gray'),
    margin: sizeMapper('small no'),
  })

export const tabs = style({
  maxWidth: '308px',
  margin: '0 auto !important',
})

export const mobilePreviewImage = style({
  height: '594px',
  width: '273px',
})

export const loaderStyle = style({
  position: 'absolute',
  top: 'calc(50% - 40px)',
  right: 'calc(50% - 120px)',
})

export const cardImage = (cardStyle: string, isDisabled: boolean): string =>
  style({
    width: '150px',
    height: '270px',
    background: `url(${
      cardStyle === 'new'
        ? isDisabled
          ? newCardDisabled
          : newCard
        : cardStyle === 'old'
        ? isDisabled
          ? oldCardDisabled
          : oldCard
        : cardStyle === 'e2e'
        ? isDisabled
          ? e2eCardDisabled
          : e2eCard
        : cardStyle === 'landscape'
        ? isDisabled
          ? landscapeCardDisalbled
          : landscapeCardDisalbled
        : isDisabled
        ? portraitCardDisabled
        : portraitCardDisabled
    }) no-repeat center center`,
    backgroundSize: '150px 270px',
  })

export const formatsWrapper = style({
  width: '100%',
  padding: '0',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-around',
})

export const cardsWrapper = style({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
})

export const brandsWrapper = style({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '80%',
  marginBottom: '24px',
})

export const uploadHelperText = style({
  marginTop: sizeMapper('small'),
  $nest: {
    '> div': {
      fontSize: '12px',
      lineHeight: '14px',
      marginBottom: sizeMapper('xSmall'),
      color: getColor('secondary-gray'),
    },
    '.image-spec': {
      color: getColor(),
    },
  },
})

export const radioButtonsWrapper = (disabled?: boolean): string =>
  style({
    display: 'flex',
    gap: '8px',
    $nest: {
      input: {
        width: '16px',
        height: '16px',
      },
      label: {
        color: disabled ? getColor('secondary-gray') : getColor('black'),
        paddingTop: '5px',
        fontSize: '12px',
        fontWeight: '500',
      },
    },
  })

export const fakeImage = style({
  width: '150px',
})
