
import { defineComponent, PropType } from 'vue'
import { Color, FontWeight } from '@/utils'
import { customTextBubbleStyle } from '@/components/ui/styles'

export default defineComponent({
  name: 'CustomBubbleText',
  props: {
    color: {
      type: String as PropType<Color>,
      default: '#fff',
    },
    backgroundColor: {
      type: String,
      default: '#5930FF',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    weight: {
      type: String as PropType<FontWeight>,
      default: 'normal',
    },
    fontSize: {
      type: String,
      default: '14px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return { customTextBubbleStyle }
  },
})
