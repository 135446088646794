
import { defineComponent, PropType } from 'vue'
import { OptionType } from '@/types/components'
import { formError } from '@/components/ui/styles'

export default defineComponent({
  name: 'BckRadioGroup',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array as PropType<OptionType[]>,
      required: true,
    },
    initialValue: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],

  setup() {
    return { formError }
  },
})
