import { getColor, sizeMapper } from '@/utils'
import { style } from 'typestyle'
import { BasicProps } from '@/types/components'

export default (
  { margin = 'xxSmall', padding = 'no', color = 'white' }: BasicProps,
  sticky: boolean,
  borderLess: boolean,
  onlyPadding: boolean,
  overflowHidden: boolean
): string =>
  style({
    position: sticky ? 'sticky' : 'relative',
    top: sticky ? '1rem' : 0,
    backgroundColor: borderLess ? 'transparent' : getColor(color),
    margin: borderLess ? '0 0 0 0' : sizeMapper(margin),
    padding: borderLess
      ? onlyPadding
        ? sizeMapper(padding)
        : '0 0 0 0'
      : sizeMapper(padding),
    width: '100%',
    boxShadow: borderLess
      ? '0px 0px 0px 0px rgba(0, 0, 0, 0.0)'
      : '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '20px',
    overflow: overflowHidden ? 'hidden' : '', // no need fo 'auto' or 'scroll' here
    $nest: {
      '.bottom-bar': {
        backgroundColor: getColor(color),
        borderTop: `1px solid ${getColor('secondary-gray')}`,
        bottom: '0',
        left: '0',
        height: '32px',
        overflowX: 'scroll',
        position: 'absolute',
        width: '100%',
      },
    },
  })
