import { style } from 'typestyle'
import { getColor } from '@/utils'

export default (text: boolean): string =>
  style({
    width: text ? '169px' : '45px',
    height: text ? '40px' : '26px',
    border: `1px solid ${getColor('secondary-gray')}`,
    borderRadius: '20px',
    padding: '2px',
    fontSize: '12px',
    lineHeight: '18px',
    position: 'relative',
    $nest: {
      '.slider': {
        cursor: 'pointer',
        width: '100%',
        height: '100%',
        position: 'relative',
        color: getColor('secondary-light-gray'),
        transition: 'all 0.3s ease',
        borderRadius: '20px',
        $nest: {
          '&::before, &::after': {
            borderRadius: '20px',
            width: text ? ' 81px' : '20px',
            height: text ? '33px' : '20px',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center',
            transition: 'all 0.3s ease',
          },
          '&::before': {
            backgroundColor: getColor('secondary-light-gray'),
            zIndex: 10,
            content: text ? 'attr(data-unchecked)' : '" "',
            color: getColor('white'),
          },
          '&::after': {
            right: 0,
            content: text ? 'attr(data-checked)' : '" "',
          },
        },
      },

      input: {
        display: 'none',
        $nest: {
          '&:checked + .slider': {
            $nest: {
              '&:after': {
                left: 0,
                content: text ? 'attr(data-unchecked)' : '" "',
                color: getColor('secondary-gray'),
              },

              '&:before': {
                left: '50%',
                backgroundColor: getColor('primary-blue'),
                color: getColor('white'),
                content: text ? 'attr(data-checked)' : '" "',
              },
            },
          },
        },
      },
    },
  })
