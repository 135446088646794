
import { defineComponent, watch, ref } from 'vue'
import { useField } from 'vee-validate'
import { useRouter, useRoute } from 'vue-router'
import { searchInputStyle as css, loading } from '@/components/ui/styles'

export default defineComponent({
  name: 'BckInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    useUrlQuery: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: undefined,
    },
    clearSearchInput: {
      type: Function,
      default: undefined,
    },
    resetSearchInputByRouteChange: {
      type: String,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  setup(props) {
    const {
      value: inputValue,
      handleBlur,
      handleChange,
      resetField,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    })

    const router = useRouter()
    const route = useRoute()
    const initialRouteValue = ref(
      route.query[props.resetSearchInputByRouteChange || '']
    )

    const onInputChange = (event: Event) => {
      handleChange(event)

      if (props.onChange) props.onChange(inputValue.value)
    }

    const resetSearchInput = () => {
      if (
        route.query[props.resetSearchInputByRouteChange || ''] !==
        initialRouteValue.value
      ) {
        delete route.query.search
        resetField({ value: '' })
        initialRouteValue.value =
          route.query[props.resetSearchInputByRouteChange || '']
        router.push({
          query: { ...route.query },
        })
      }
    }

    if (props.resetSearchInputByRouteChange) {
      watch(route, resetSearchInput)
    }

    return {
      loading,
      css,
      onInputChange,
      handleBlur,
      inputValue,
    }
  },
})
