
import { defineComponent, watch, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { range } from 'lodash'
import { paginationStyle as css } from './styles'
import { calculatePaginationBoundaries } from '@/utils'

export default defineComponent({
  props: {
    totalResults: {
      type: Number,
      required: true,
    },
    limitPerPage: {
      type: Number,
      required: true,
    },
    maxPaginationItems: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute()
    const { push } = useRouter()
    const currentPage = ref()
    const firstPage = ref()
    const lastPage = ref()
    const isFirstPage = ref()
    const isLastPage = ref()

    const getState = () => {
      currentPage.value = Number(route.query.page) || 1

      const { firstPage: firstPageUpdate, lastPage: lastPageUpdate } =
        calculatePaginationBoundaries(
          props.totalResults,
          props.limitPerPage,
          props.maxPaginationItems,
          currentPage.value
        )

      firstPage.value = firstPageUpdate
      lastPage.value = lastPageUpdate

      isFirstPage.value = currentPage.value === 1
      isLastPage.value = currentPage.value === lastPage.value
    }

    const updatePageNumber = (pageNumber: number) => {
      push({
        query: {
          ...route.query,
          page: pageNumber,
        },
      })
    }
    getState()

    watch(route, () => getState())

    return {
      currentPage,
      firstPage,
      lastPage,
      isFirstPage,
      isLastPage,
      range,
      updatePageNumber,
      css,
    }
  },
})
