
import { defineComponent, PropType } from 'vue'
import { CardProps } from '@/types/components'

export default defineComponent({
  props: {
    cardsData: {
      type: Array as PropType<CardProps[]>,
      default: () => [],
    },
  },
  emits: ['card-selected'],
})
