import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BckIcon = _resolveComponent("BckIcon")!
  const _component_BckH4 = _resolveComponent("BckH4")!
  const _component_BckGrid = _resolveComponent("BckGrid")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "modal",
    class: _normalizeClass(_ctx.css.modalTransition('modal'))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.css.overlay)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.css.modal(_ctx.portrait, _ctx.fullWidth))
        }, [
          _createVNode(_component_BckIcon, {
            "icon-name": "close",
            class: "close",
            viewBox: "0 0 14 14",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }),
          _createVNode(_component_BckH4, { margin: "no" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header")
            ]),
            _: 3
          }),
          (_ctx.$slots.body)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "body")
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_BckGrid, {
            margin: "large no no",
            "justify-content": "space-between"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer")
            ]),
            _: 3
          })
        ], 2)
      ], 2)
    ]),
    _: 3
  }, 8, ["class"]))
}