import { BasicProps } from '@/types/components'
import { getBasicStyles, getColor, sizeMapper } from '@/utils'
import { classes, style } from 'typestyle'
import { longText } from '@/components/ui/styles/common'

const squareCard = ({ margin = 'no' }: BasicProps): string => {
  return style({
    backgroundClip: 'padding-box',
    backgroundColor: getColor('white'),
    margin: sizeMapper(margin),
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', //'0px 0px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px', //'5px',
    border: '2px solid transparent',
    width: '200px',
    height: '200px',
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        backgroundClip: 'border-box',
        border: `2px solid ${getColor('primary-blue')}`,
      },
      '&:active': {
        border: `2px solid ${getColor('primary-blue')}`,
      },
      '&:disabled': {
        color: getColor('secondary-gray'),
        pointerEvents: 'none',
        border: `2px solid ${getColor('secondary-gray')}`,
      },
    },
  })
}

export const addProjectCard = ({ margin = 'no' }: BasicProps): string =>
  classes(
    squareCard({ margin }),
    style({
      color: getColor('primary-blue-10'),
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
      $nest: {
        svg: {
          width: '100%',
          height: '100%',
          maxWidth: '69.17px',
          maxHeight: '64.12px',
          margin: 'auto',
        },
        '&:active': {
          backgroundColor: getColor('primary-blue'),
        },
      },
    })
  )

const projectCard = ({ margin = 'no' }: BasicProps): string =>
  classes(
    squareCard({ margin }),
    style({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      padding: '0',
      alignItems: 'center',
    })
  )

export const addProjectCardText = ({ margin = 'no', padding = 'no', color = 'primary-blue' }: BasicProps): string =>
  classes(
    style({
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '16px',
      fontStyle: 'normal',
      textAlign: 'center',
      width: '100%',
      ...getBasicStyles(color, margin, padding),
    })
  )

const projectCardHeader = style({
  padding: '16px 22px',
  backgroundColor: getColor('white'),
  maxHeight: '50px',
  textAlign: 'left',
  width: '100%',
  borderRadius: '20px 20px 0 0', //'5px 5px 0 0',
})

const projectCardBody = (icon = ''): string =>
  style({
    width: '120px',
    height: '120px',
    background: `url(${icon}) no-repeat center center`,
    backgroundSize: 'contain',
  })

const projectCardHeaderTitle = classes(
  style({
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '128.91%',
    color: getColor('swashly-transparent-80-black'),
    maxWidth: '184px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: '2px',
  }),
  longText
)

const projectCardHeaderSubTitle = style({
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '10px',
  lineHeight: '128.91%',
  color: getColor(),
  maxWidth: '184px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const projectCardIssues = style({
  position: 'absolute',
  bottom: '8px',
  left: '8px',
  width: '20px',
  height: '20px',
  backgroundColor: getColor('white'),
  padding: '3px 8px',
  color: getColor('primary-red'),
  fontSize: '12px',
  lineHeight: '128.91%',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
  borderRadius: '5px',
})

export default {
  squareCard,
  addProjectCard,
  projectCard,
  addProjectCardText,
  projectCardHeader,
  projectCardBody,
  projectCardHeaderTitle,
  projectCardHeaderSubTitle,
  projectCardIssues,
}
